/**
 * UserHeading Component
 *
 */

// Libraries
import { UserOutlined } from "@ant-design/icons";
import React from "react";

// View
const UserHeading = () => {
  return (
    <div className="userHeading">
      <div className="heading">
        <div className="flex justify-between">
          <div className="headTitle">
            <div className="flex gap-2 items-center">
              <div className="bg-blue-100 px-1 rounded-sm border-blue-200 text-blue-800 border-2 border-solid">
                <UserOutlined />
              </div>
              <div className="font-bold text-lg">User</div>
            </div>
            <div className="font-semibold">
              You can preview user details here
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserHeading;
