import { PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, message, Row } from "antd";
import { Loader } from "components";
import { useUser } from "models";
import React from "react";
import { useUpdateMutation } from "utils";
import { AccountSettingsHeading } from "./AccountSettingsHeading";

type props = {};

const AccountSettings: React.FC<props> = (props) => {
  const user = useUser((state) => state.user);

  const { mutate, isLoading, isError, isSuccess } = useUpdateMutation("users");

  React.useEffect(() => {
    if (isError) message.error("There are error while update settings");
    if (isSuccess) message.success("Update account settings success");
  }, [isError, isSuccess]);

  const handleFormSubmit = (data: {
    name: string;
    phone: string;
    email: string;
  }) => {
    mutate({
      ID: user?.ID,
      name: data.name,
      phone: data.phone,
      email: data.email,
    });
  };

  if (!user) return null;
  return (
    <div className="AccountSettings h-full">
      <div className="bg-white p-1 rounded-sm m-1 h-full">
        {isLoading && <Loader />}
        <AccountSettingsHeading />
        <Divider />
        <div className="context">
          <Form
            name="user settings"
            layout="vertical"
            onFinish={handleFormSubmit}
            initialValues={{
              name: user.name,
              phone: user.phone,
              email: user.email,
            }}
          >
            <Form.Item label="Full name" name="name">
              <Input prefix={<UserOutlined />} />
            </Form.Item>
            <Form.Item label="Phone number" name="phone">
              <Input prefix={<PhoneOutlined />} />
            </Form.Item>

            <Form.Item label="Email address" name="email">
              <Input prefix={<UserOutlined />} />
            </Form.Item>
            <Row gutter={12}>
              <Col span={12}>
                <Button className="w-full">reset</Button>
              </Col>
              <Col span={12}>
                <Button htmlType="submit" className="w-full" type="primary">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
