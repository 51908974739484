import { Tag } from "antd";
import React from "react";

type props = {
  title: string;
  value?: string;
};

const CardItem: React.FC<props> = (props) => {
  if (!props.value || props.value === "") return null;
  return (
    <div className="CardItem mt-4">
      <div className="font-bold text-xs">{props.title}</div>
      <Tag color="red" className="mt-2 cursor-pointer">
        {props.value}
      </Tag>
    </div>
  );
};

export default CardItem;
