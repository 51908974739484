import React from "react";
import CasePreviewAttachments from "./CasePreviewAttachments";
import CasePreviewClinicalConditions from "./CasePreviewClinicalConditions";
import CasePreviewPrescription from "./CasePreviewPrescription";
import EditAttachments from "./../EditAttachments/index";
import { Button } from "antd";

type props = {};

const CasePreviewDetails: React.FC<props> = (props) => {
  const [isEditing, setIsEditing] = React.useState(false);

  if (isEditing) return <EditAttachments />;

  return (
    <div className="CasePreviewDetails">
      <div className="flex pb-2 items-center justify-end">
        <Button
          onClick={() => {
            setIsEditing(true);
          }}
        >
          Edit Attachments
        </Button>
      </div>
      <CasePreviewClinicalConditions />
      <CasePreviewAttachments />
      <CasePreviewPrescription />
    </div>
  );
};

export default CasePreviewDetails;
