import { Button, Checkbox, Form, message, Typography } from "antd";
import Heading from "components/Cases/components/Heading";
import ImageUpload from "components/Uploads/ImageUpload";
import React from "react";
import { FileImageOutlined } from "@ant-design/icons";
import { MultiFilesUpload } from "components/Uploads";
import { imageAttachments, stlAttachments, xRayAttachments } from "./Data";
import { useCase } from "models";
import { useCreateCase } from "pages/cases/create_case/hooks";
import shallow from "zustand/shallow";
import CaseStrippingProtocol from "../../../../pages/cases/create_case/utils/CaseStrippingProtocol";
import StlUpload from "components/Uploads/StlUpload";

type props = {};

const Attachments: React.FC<props> = (props) => {
  const [attachments, setAttachments] = React.useState<IAttachment[]>([]);
  //traditional_impressions
  const clinicalCondition = useCase((state) => state.clinicalCondition);
  const setClinicalCondition = useCase((state) => state.setClinicalCondition);
  const setStrippingProtocol = useCase((state) => state.setStrippingProtocol);

  function handleChangeImage(value: IAttachment) {
    setAttachments((oldValues) => [...oldValues, value]);
  }

  function handleRemoveImage(uri: string) {
    setAttachments((oldValues) =>
      oldValues.filter((value) => value.uri !== uri)
    );
  }

  const setCaseAttachments = useCase((state) => state.setAttachments);
  const [nextPage, previousPage] = useCreateCase(
    (state) => [state.nextPage, state.previousPage],
    shallow
  );

  const caseType = useCase((e) => e.caseType);
  function handleNextButton() {
    if (attachments.length === 0 && caseType == "NewCase")
      return message.error("Please complete all required fields");
    setCaseAttachments(attachments);
    nextPage();
  }

  return (
    <div className="Attachments">
      <Heading
        title="Attachments"
        description="Please upload attachments bellow in order"
      />
      <div className="mt-4 px-2">
        <div className="flex items-center gap-2">
          <FileImageOutlined />
          <div>
            <Typography.Text strong className="f text-xl">
              Patient photo
            </Typography.Text>
          </div>
        </div>
        <div>Please add patient photo like references belows</div>
        <div className="flex">
          <div className="flex bg-slate-50 items-center rounded-lg border-2 justify-center flex-wrap max-w-xl border-slate-100 mt-2">
            {imageAttachments.map((attac, index) => {
              if (attac.preventClick) {
                return (
                  <div className=" w-48 flex items-center justify-center">
                    <div>Select images</div>
                  </div>
                );
              }
              return (
                <ImageUpload
                  isImage
                  key={index}
                  onChangeImage={(uri) => {
                    handleChangeImage({ uri, type: attac.type, slot: "image" });
                  }}
                  onRemoveImage={handleRemoveImage}
                  initialImage={attac.image}
                  label={attac.title}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className="mt-8 px-2">
        <div className="flex items-center gap-2">
          <FileImageOutlined />
          <div>
            <Typography.Text strong className="f text-xl">
              X-rays
            </Typography.Text>
          </div>
        </div>
        <div>Please add x-ray image bellow</div>
        <div className="flex">
          <div className="flex bg-slate-50 items-center rounded-lg border-2 justify-center flex-wrap max-w-xl border-slate-100 mt-2">
            {xRayAttachments.map((attac, index) => (
              <ImageUpload
                isImage
                key={index}
                initialImage={attac.image}
                label={attac.title}
                onChangeImage={(uri) => {
                  handleChangeImage({ uri, type: attac.type, slot: "x-ray" });
                }}
                onRemoveImage={handleRemoveImage}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="mt-8 px-2">
        <div className="flex items-center gap-2">
          <FileImageOutlined />
          <div>
            <Typography.Text strong className="f text-xl">
              Scans
            </Typography.Text>
          </div>
        </div>
        <div>Please add scan stl files bellow</div>
        <div className="flex flex-col">
          <div className="flex bg-slate-50 items-center rounded-lg border-2 justify-center flex-wrap max-w-xl border-slate-100 mt-2">
            {stlAttachments.map((attac, index) => (
              <StlUpload
                key={index}
                onChangeImage={(uri) => {
                  handleChangeImage({ uri, type: attac.type, slot: "stl" });
                }}
                onRemoveImage={handleRemoveImage}
                initialImage={attac.image}
                label={attac.title}
              />
            ))}
          </div>
          <div className="box mt-8 font-bold">
            <Form.Item
              tooltip="If you don't have STL Scanner and send the impression traditional "
              label="Sending Impressions "
              help="Set this box if you sending the impression traditional"
            >
              <Checkbox
                checked={clinicalCondition?.traditional_impressions}
                onChange={(e) => {
                  if (clinicalCondition) {
                    setClinicalCondition({
                      ...clinicalCondition,
                      traditional_impressions: e.target.checked,
                    });
                  }
                }}
              >
                Is Traditional Impression
              </Checkbox>
            </Form.Item>
          </div>
        </div>
      </div>
      <div className="mt-8 px-2">
        <div className="flex items-center gap-2">
          <FileImageOutlined />
          <div>
            <Typography.Text strong className="f text-xl">
              More ?
            </Typography.Text>
          </div>
        </div>
        <div>Do you want add more images & files ? Please add it bellow</div>
        <div className="flex">
          <div className="flex bg-slate-50 items-center rounded-lg border-2 justify-center flex-wrap max-w-xl border-slate-100 mt-2">
            <MultiFilesUpload
              onChangeImage={(uri) => {
                handleChangeImage({ uri, type: "any", slot: "other" });
              }}
            />
          </div>
        </div>
      </div>
      {/*Stripping Protocol*/}
      {caseType !== "NewCase" && (
        <CaseStrippingProtocol
          onFinish={(url, data) => {
            setStrippingProtocol({
              data,
              file: url,
            });
          }}
          patientName={"XXX"}
          doctorName={"XXX"}
        />
      )}
      {/*Stripping // Protocol*/}
      <div id="actions" className="flex gap-2 mt-6 items-center">
        <div className="flex-grow">
          <Button className="w-full" onClick={previousPage}>
            Back
          </Button>
        </div>
        <div className="flex-grow">
          <Button className="w-full" type="primary" onClick={handleNextButton}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Attachments;
