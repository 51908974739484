import { FileImageOutlined } from "@ant-design/icons";
import { UrlList } from "components/Uploads/ImagesUpload/ImagesUpload";
import React from "react";
import { ENDPOINT, http } from "utils";
import { IconLabel, PreviewCard } from "../TreatmentPlanComponents";
import { Image, message } from "antd";
import SectionPreviewBase from "./SectionPreviewBase";
import { Loader } from "components";
import { saveAs } from "file-saver";
type Props = {
  files: UrlList[];
};

const TreatmentPlanImages = ({ files }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const downloadImage = async () => {
    setIsLoading(true);
    try {
      const res = await http.post<{ url: string }>("api/zip:Generate", {
        files: files.map((trg) => trg.url),
      });
      saveAs(ENDPOINT + "public/tmp/" + res.data.url, res.data.url);
      message.success("Files Ready to Download");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error("There are error please try again later");
    }
  };
  return (
    <div className="TreatmentPlanImages mb-4">
      <div className="imagesList">
        {/* <IconLabel
          title="Images"
          icon={<FileImageOutlined />}
          description={"Preview images for the treatment plan"}
        /> */}
        {isLoading && <Loader />}
        <SectionPreviewBase
          title="Images"
          description="Treatment Plan Images"
          img="/attachments/ig.png"
          handleDownload={downloadImage}
        >
          <div className="flex gap-2 mt-2">
            {files.map((trg) => (
              <PreviewCard>
                <Image
                  src={ENDPOINT + trg.url}
                  width="12rem"
                  height={"10rem"}
                />
              </PreviewCard>
            ))}
          </div>
        </SectionPreviewBase>
      </div>
    </div>
  );
};

export default TreatmentPlanImages;
