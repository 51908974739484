/* eslint-disable react-hooks/rules-of-hooks */
import { CommentOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Form, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { useCreateMutation, useReadQuery } from "utils";
import { useUser } from "models";
import useCasePreview from "./../hooks/useCasePreview";
import { useUpdateEffect } from "hooks";
import { Loader } from "components";
import CommentsPreviewCommentBox from "./CommentsPreviewCommentBox";
import notification from "utils/Notification";

type props = {};

const CommentsPreview: React.FC<props> = () => {
  const selected = useCasePreview((state) => state.case);
  if (!selected) return null;
  const searchValue = `where=case_id=${selected.ID}&order=id desc`;
  const config = `?${searchValue}&preload=User`;
  const { isLoading, data, isError, refetch } = useReadQuery<{
    data: IComments[];
  }>("comments", config);
  const user = useUser((state) => state.user);
  if (!user) return null;
  const sending = useCreateMutation("comments");
  const handleFormSubmit = async (values: any) => {
    await sending.mutateAsync({
      ...values,
      userID: user.ID,
      caseID: selected.ID,
    });
    await notification.send({
      message: `User ${user.name} Sent message on Case ID ${selected.ID}`,
      caseID: selected.ID,
      alertType: "info",
      notificationType: "default",
      targetType: "manager",
    });
  };
  const [form] = Form.useForm();

  useUpdateEffect(() => {
    if (isError) {
      message.error(`there are error while getting the comments.`);
    }
  }, [isError]);
  useUpdateEffect(() => {
    if (sending.isSuccess) {
      refetch();
      message.success("Sending comment success");
      form.resetFields();
    }
    if (sending.isError) {
      message.error("Sending comment error");
    }
  }, [sending.isSuccess, sending.isError]);
  if (isLoading) {
    <div className="w-full h-full">
      <Loader />
    </div>;
  }
  return (
    <div className="CommentsPreview">
      <div className="addCommentsBox bg-slate-50 border-solid border-slate-100">
        <div className="commentHeading mb-2 bg-slate-100 border-solid border-0 border-b-slate-200 p-2 border-b-2">
          <div className="flex gap-2 items-center ">
            <div className="bg-blue-100 px-1 rounded-sm border-blue-200 text-blue-800 border-2 border-solid">
              <CommentOutlined />
            </div>
            <div className="text-lg font-bold">Messages</div>
          </div>
          <div className="t text-slate-500">
            Message its like communication between Doctor and lab for the case.
          </div>
        </div>
        <div className="sendComment p-1">
          <div className="SendCommentsHeading">
            <div className="heading-text font-semibold">Send message</div>
          </div>
          <Form
            name="comment"
            autoComplete="off"
            layout="vertical"
            onFinish={handleFormSubmit}
            form={form}
          >
            <Form.Item
              name="comment"
              rules={[{ required: true, message: "Please fill out field" }]}
            >
              <TextArea placeholder="message content" rows={4} />
            </Form.Item>
            <div className="flex justify-end">
              <Button
                htmlType="submit"
                icon={<SendOutlined />}
                loading={sending.isLoading}
                disabled={sending.isLoading}
                type="primary"
              >
                Send Message
              </Button>
            </div>
          </Form>
        </div>
      </div>
      {data?.data.length != 0 && (
        <div className="commentsLists bg-slate-50 border-solid border-slate-100 my-2">
          <div className="commentHeading mb-2 bg-slate-100 border-solid border-0 border-b-slate-200 p-2 border-b-2">
            <div className="flex gap-2 items-center ">
              <div className="bg-blue-100 px-1 rounded-sm border-blue-200 text-blue-800 border-2 border-solid">
                <CommentOutlined />
              </div>
              <div className="text-lg font-bold">Messages list</div>
            </div>
          </div>
          {data && (
            <>
              {data.data.map((trg) => (
                <CommentsPreviewCommentBox comment={trg} key={trg.ID} />
              ))}
            </>
          )}
        </div>
      )}

      <div className="mt-6"></div>
    </div>
  );
};

export default CommentsPreview;
