import { FormBuilderInputsProps } from "components/FormBuilder/types";

export const clinicalConditionsValues: { id: number; title: string }[] = [
  { id: 0, title: "Crowding" },
  { id: 1, title: "Class II div2" },
  { id: 2, title: "Deep bite" },
  { id: 3, title: "Narrow arch" },
  { id: 4, title: "Uneven smile" },
  { id: 5, title: "Spacing" },
  { id: 6, title: "Class III" },
  { id: 7, title: "Anteriae crossbite" },
  { id: 8, title: "Flared Teeth" },
  { id: 9, title: "Misshapen teeth" },
  { id: 10, title: "Class II div 1" },
  { id: 11, title: "Open bite" },
  { id: 12, title: "Posteriar crossbite" },
  { id: 13, title: "Overjet" },
];

export const clinicalConditionsInputs: FormBuilderInputsProps[] = [
  {
    type: "select",
    name: "values",
    label: "Clinical Condition",
    span: 24,
    items: clinicalConditionsValues.map((c) => ({
      label: c.title,
      value: c.id.toString(),
    })),
    mode: "multiple",
    help: "Can select multi type of Clinical Condition",
  },

  {
    type: "input",
    label: "Unique Internal ID",
    name: "uniquelInternalID",
    span: 24,
    help: "Set Internal ID to case to help you to remember case",
  },
  {
    type: "textarea",
    label: "Note",
    name: "note",
    span: 24,
  },
];
