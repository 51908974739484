import { Loader } from "components";
import React from "react";
import { useReadQuery } from "./../../../utils/Api.utils";
import { useUpdateEffect } from "hooks";
import { message } from "antd";
import AddressesTable from "pages/addresses/AddressesTable";

const AddressDetails = ({ userID }: { userID: number }) => {
  const { data, isError, isLoading } = useReadQuery<{ data: IAddress[] }>(
    "addresses",
    "",
    {
      params: {
        where: `user_id = ${userID}`,
      },
    }
  );

  useUpdateEffect(() => {
    if (isError) message.error("There are error, please try again later");
  }, [isError]);

  if (!data) return null;
  if (!data.data) return null;
  return (
    <div>
      {isLoading && <Loader />}
      <h3>Address details</h3>
      <AddressesTable data={data.data} />
    </div>
  );
};

export default AddressDetails;
