/* eslint-disable react-hooks/exhaustive-deps */
import { HeartFilled, PlusSquareFilled } from "@ant-design/icons";
import { Card, Checkbox, Image, message, Modal } from "antd";
import assets from "assets";
import React from "react";
import { UrlList } from "components/Uploads/ImagesUpload/ImagesUpload";
import { ENDPOINT } from "../../../../utils/constants.utils";
import useCart from "models/useCart";

export const ItemCard = ({ item }: { item: IItem }) => {
  const { height } = window.screen;
  const HEIGHT = React.useMemo(() => {
    const HEIGHT_FIXED = height * 0.3;
    return HEIGHT_FIXED;
  }, []);
  const [images] = React.useState<UrlList[]>(JSON.parse(item.image));
  const [itemOptions] = React.useState<IItemOptions[]>(
    JSON.parse(item.options)
  );

  const [isOpen, setIsOpen] = React.useState(false);

  const cartItems = useCart((state) => state.cartItems);
  const setCartItems = useCart((state) => state.setCartItems);

  // Add to cart option
  const addToCart = () => {
    // add to cart method
    setCartItems([...cartItems, { itemID: item.ID ?? 0, qty: 1, item }]);
    setIsOpen(false);
    message.success("Item added to cart");
  };

  return (
    <div className="ItemCard">
      <div className="w-full bg-slate-300 cursor-pointer">
        <Card
          cover={
            <Image
              preview={false}
              src={
                images.length === 0
                  ? assets.logo.logo
                  : ENDPOINT + images[0].url
              }
              height={HEIGHT}
            />
          }
          actions={[
            <div id="AddButton" className="flex justify-center">
              <div
                className="flex items-center gap-2"
                onClick={() => setIsOpen(true)}
              >
                <PlusSquareFilled />
                <div className="label">Add to cart</div>
              </div>
            </div>,
            <HeartFilled />,
          ]}
        >
          <div className="titleHeader font-medium text-lg">{item.title}</div>
          <div className="itemDescription">{item.description}</div>
          <div className="flex justify-end">
            <div className="price font-bold text-green-500 text-lg">
              {item.fear + " "}SAR
            </div>
          </div>
        </Card>
      </div>
      <Modal
        open={isOpen}
        title="Add item to card"
        okText="Add to Cart"
        onOk={addToCart}
        onCancel={() => setIsOpen(false)}
      >
        <Card
          cover={
            <Image
              preview={false}
              src={
                images.length === 0
                  ? assets.logo.logo
                  : ENDPOINT + images[0].url
              }
              height={HEIGHT}
            />
          }
        >
          <div className="titleHeader font-medium text-lg">{item.title}</div>
          <div className="itemDescription">{item.description}</div>
          <div className="flex justify-end">
            <div className="price font-bold text-green-500 text-lg">
              {item.fear + " "}SAR
            </div>
          </div>
          <div className="optionsPreview">
            <div className="title font-medium">Options</div>
            {itemOptions.map((itemOption) => (
              <div className="itemOptionCard">
                <Checkbox>
                  {itemOption.title}, {itemOption.fear}
                </Checkbox>
              </div>
            ))}
          </div>
        </Card>
      </Modal>
    </div>
  );
};
