import { MessageOutlined } from "@ant-design/icons";

const MessagesHeader = () => {
  return (
    <div className="userHeading p-2">
      <div className="heading">
        <div className="flex justify-between">
          <div className="headTitle">
            <div className="flex gap-2 items-center">
              <div className="bg-blue-100 px-1 rounded-sm border-blue-200 text-blue-800 border-2 border-solid">
                <MessageOutlined />
              </div>
              <div className="font-bold text-lg">Messages</div>
            </div>
            <div className="font-semibold">
              You can preview messages and notification details here
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagesHeader;
