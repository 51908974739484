import React from "react";
import Authentication from "./pages/authentication";
import useUser from "./models/useUser";
import { useEffectOnce } from "./hooks/useEffectOnce";
import { useIsLoading } from "./hooks/useIsLoading";
import Splash from "./components/Core/Splash/Splash";
import Router from "./Routes";

function App() {
  const auth = useUser((state) => state.auth);
  const isAuth = useUser((state) => state.isAuth);
  const loading = useIsLoading();

  useEffectOnce(() => {
    (async () => {
      loading.startIsLoading();
      await auth();
      loading.stopIsLoading();
    })();
  });

  // if authentication
  if (isAuth) return <Router />;

  // if is loading
  if (loading.isLoading) return <Splash />;

  // if !loading && !Auth
  return <Authentication />;
}

export default App;
