import { BookOutlined } from "@ant-design/icons";
import { Descriptions, Tag } from "antd";
import React from "react";
import { clinicalConditionsValues } from "./../../../../components/Cases/Forms/ClinicalConditions/Data";
import useCasePreview from "./../hooks/useCasePreview";
import CardItem from "./CardItem";

type props = {};

const CasePreviewClinicalConditions: React.FC<props> = (props) => {
  const selected = useCasePreview((state) => state.case);
  if (!selected) return null;
  return (
    <div className="CasePreviewClinicalConditions bg-slate-50 border-solid rounded-sm border-slate-100">
      <div className="flex gap-2 items-center border-0 border-solid pb-2 border-b-2 border-b-slate-200 p-2 bg-slate-100">
        <div className="bg-blue-100 px-1 rounded-sm border-blue-200 text-blue-800 border-2 border-solid">
          <BookOutlined />
        </div>
        <div className="font-bold text-lg">Clinical Conditions</div>
      </div>
      <div className="w-full border-2 border-slate-500  bg-slate-50 mt-2 rounded-sm p-2">
        {selected.clinicalCondition.values && (
          <>
            {clinicalConditionsValues
              .filter((value) =>
                selected.clinicalCondition.values.includes(value.id.toString())
              )
              .map((trg, index) => (
                <Tag className="cursor-pointer text-lg" key={index} color="red">
                  {trg.title}
                </Tag>
              ))}
          </>
        )}
        <div className="flex gap-10">
          <CardItem
            title="Internal ID"
            value={selected.clinicalCondition.uniquelInternalID}
          />
          <CardItem title="Note" value={selected.clinicalCondition.note} />
        </div>
      </div>
    </div>
  );
};

export default CasePreviewClinicalConditions;
