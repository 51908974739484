export const imageAttachments = [
  {
    image: "/attachments/a01.svg",
    title: "full face smiling",
    type: "fullFaceSmiling",
  },
  {
    image: "/attachments/a02.svg",
    title: "full face not smiling",
    type: "fullFaceNotSmiling",
  },
  {
    image: "/attachments/a03.svg",
    title: "profile not smiling",
    type: "profileNotSmiling",
  },
  {
    image: "/attachments/a04.svg",
    title: "upper occlusal",
    type: "upperOcclusal",
  },
  {
    image: "/attachments/a05.svg",
    title: "lower occlusal",
    type: "lowerOcclusal",
    preventClick: true,
  },
  {
    image: "/attachments/a05.svg",
    title: "lower occlusal",
    type: "lowerOcclusal",
  },
  {
    image: "/attachments/a06.svg",
    title: "right lateral",
    type: "rightLateral",
  },
  { image: "/attachments/a07.svg", title: "front view", type: "frontView" },
  { image: "/attachments/a08.svg", title: "left lateral", type: "leftLateral" },
];

export const stlAttachments = [
  { image: "/attachments/stl01.svg", title: "STL Upper", type: "stlUpper" },
  { image: "/attachments/stl02.svg", title: "STL Lower", type: "stlLower" },
  { image: "/attachments/biteStl.png", title: "Bite Scan", type: "biteScan" },
];

export const xRayAttachments = [
  { image: "/attachments/xray.svg", title: "Panoramic", type: "panoramic" },
  {
    image: "/attachments/xray02.svg",
    title: "Cephalometry",
    type: "cephalometer",
  },
];
