import React from "react";
import MyOrdersHeader from "./components/MyOrdersHeader";
import Table, { ColumnsType } from "antd/lib/table";
import { useReadQuery } from "utils";
import { useUser } from "models";
import { Loader } from "components";

interface ICart {
  ID: number;
  CreatedAt: string;
  cartItems: string;
}

const columns: ColumnsType<ICart> = [
  {
    title: "ID",
    key: "ID",
    dataIndex: "ID",
  },
  {
    title: "CreatedAt",
    key: "CreatedAt",
    dataIndex: "CreatedAt",
    render(_, { CreatedAt }, index) {
      return <div className="time">{new Date(CreatedAt).toUTCString()}</div>;
    },
  },
];

const MyOrders = () => {
  const user = useUser((state) => state.user);
  const { data, isLoading } = useReadQuery<{ data: ICart[] }>("cart", "", {
    params: {
      where: "user_id = " + user?.ID,
    },
  });

  const cols = React.useMemo<ColumnsType<ICart>>(() => {
    return [
      ...columns,
      {
        title: "CartItems",
        key: "CartItems",
        dataIndex: "CartItems",
        render(value, { cartItems }, index) {
          return (
            <div className="div" key={index}>
              {JSON.parse(cartItems).map(
                (trg: { itemID: number; qty: number }, index: number) => {
                  return (
                    <div className="x" key={index}>
                      <div className="title font-bold">
                        Item ID: {trg.itemID}
                      </div>
                      <div className="title">x{trg.qty}</div>
                    </div>
                  );
                }
              )}
            </div>
          );
        },
      },
    ];
  }, []);

  return (
    <div className="myOrders bg-white p-1 m-1 rounded-sm h-full">
      <MyOrdersHeader />
      {isLoading && <Loader />}
      <div className="body">
        {data?.data && <Table dataSource={[...data.data]} columns={cols} />}
      </div>
    </div>
  );
};

export default MyOrders;
