import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Button, Upload } from "antd";
import React from "react";
import { ENDPOINT } from "./../../../utils/constants.utils";
type props = {
  onChangeImage(uri: string): void;
};

const MultiFilesUpload: React.FC<props> = (props) => {
  const data: UploadProps<{ id: string }> = {
    action: ENDPOINT + "file",
    onChange({ file }) {
      if (file.status !== "uploading") {
        if (file.response) {
          props.onChangeImage(file.response.id);
        }
      }
    },
  };

  return (
    <div className="MultiFilesUpload">
      <Upload {...data}>
        <Button icon={<UploadOutlined />}>Upload</Button>
      </Upload>
    </div>
  );
};

export default MultiFilesUpload;
