import { Spin } from "antd";
import React from "react";

type props = {};

const Loader: React.FC<props> = (props) => {
  return (
    <div className="loading absolute top-0 left-0 z-10 w-full h-full bg-slate-50/75 flex items-center justify-center flex-col font-semibold">
      <Spin size="large" />
      <div className="text-center text-lg mt-2">Loading</div>
    </div>
  );
};

export default Loader;
