/**
 * Utils Preview
 */
// export const ENDPOINT = "https://www.clearaligner-kh.tk/api/";

/* A comment. */
/* A constant variable that is used to store the URL of the API. */
//export const ENDPOINT = "http://localhost:8080/";

export const ENDPOINT = "https://ca-clearart.com/api/";
// export const ENDPOINT = "/api/";

export const storagesToken = {
  userToken: "userToken",
};
