import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import DashboardLayout from "./components/Layouts/DashboardLayout";
import {
  AccountSettings,
  Address,
  CaseList,
  CasePreview,
  Dashboard,
  NeedActiveUsersList,
  User,
  UsersList,
} from "./pages";
import CreateCase from "./pages/cases/create_case";

import Messages from "./pages/dashboard/Messages";
import NewUser from "./pages/users/NewUser";
import UserSearch from "./pages/users/UserSearch";
import NewAddress from "./pages/addresses/NewAddress";
import ChangePassword from "./pages/account/ChangePassword";
import { ListItem, MyOrders, NewItem, Orders } from "pages/items";
import CastItems from "pages/items/CastItems";
import StrippingProtocolPage from "./pages/cases/case_preview/Pages/StrippingProtocolPage";
//import ResetPassword from './pages/reset_password';

type props = {};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <DashboardLayout>
        <Dashboard />
      </DashboardLayout>
    ),
  },
  /*{
    path: "/reset_password",
    element: <ResetPassword />
  },*/
  {
    path: "/dashboard/messages",
    element: (
      <DashboardLayout>
        <Messages />
      </DashboardLayout>
    ),
  },
  {
    path: "/cases/create_case",
    element: (
      <DashboardLayout>
        <CreateCase />
      </DashboardLayout>
    ),
  },
  {
    path: "/cases/case_list/:type",
    element: (
      <DashboardLayout>
        <CaseList />
      </DashboardLayout>
    ),
  },
  {
    path: "/cases/case_preview/:id",
    element: (
      <DashboardLayout>
        <CasePreview />
      </DashboardLayout>
    ),
  },
  {
    path: "/address",
    element: (
      <DashboardLayout>
        <Address />
      </DashboardLayout>
    ),
  },
  {
    path: "/new_address",
    element: (
      <DashboardLayout>
        <NewAddress />
      </DashboardLayout>
    ),
  },
  {
    path: "/account_settings",
    element: (
      <DashboardLayout>
        <AccountSettings />
      </DashboardLayout>
    ),
  },
  {
    path: "/change_password",
    element: (
      <DashboardLayout>
        <ChangePassword />
      </DashboardLayout>
    ),
  },
  {
    path: "/users_list",
    element: (
      <DashboardLayout>
        <UsersList />
      </DashboardLayout>
    ),
  },
  {
    path: "/need_active_users_list",
    element: (
      <DashboardLayout>
        <NeedActiveUsersList />
      </DashboardLayout>
    ),
  },
  {
    path: "/new_user",
    element: (
      <DashboardLayout>
        <NewUser />
      </DashboardLayout>
    ),
  },
  {
    path: "/user_search",
    element: (
      <DashboardLayout>
       <UserSearch />
      </DashboardLayout>
    )
  },
  {
    path: "/user/:id",
    element: (
      <DashboardLayout>
        <User />
      </DashboardLayout>
    ),
  },
  {
    path: "/item/new",
    element: (
      <DashboardLayout>
        <NewItem />
      </DashboardLayout>
    ),
  },
  {
    path: "/item/:id",
    element: (
      <DashboardLayout>
        <NewItem />
      </DashboardLayout>
    ),
  },
  {
    path: "/item/list",
    element: (
      <DashboardLayout>
        <ListItem />
      </DashboardLayout>
    ),
  },
  {
    path: "/item/buy",
    element: (
      <DashboardLayout>
        <CastItems />
      </DashboardLayout>
    ),
  },
  {
    path: "/item/orders",
    element: (
      <DashboardLayout>
        <MyOrders />
      </DashboardLayout>
    ),
  },
  {
    path: "/item/all",
    element: (
      <DashboardLayout>
        <Orders />
      </DashboardLayout>
    ),
  },
  {
    path: "/stripping_protocol/:id",
    element: <StrippingProtocolPage />,
  },
]);
const Router: React.FC<props> = (props) => {
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
};

export default Router;
