import { DownloadOutlined, FileOutlined } from "@ant-design/icons";
import { Alert, Image } from "antd";
import { StlCard } from "components";
import React from "react";
import { ENDPOINT } from "utils";
import useCasePreview from "./../hooks/useCasePreview";
import { saveAs } from "file-saver";
import TreatmentPlanDoc from "./TreatmentPlanPreviewComponents/TreatmentPlanDoc";
import TreatmentPlanPreviewSP from "./TreatmentPlanEditComponent/TreatmentPlanPreviewSP";

type props = {};

/**
 * CasePreviewAttachments
 * Preview attachments
 *
 * @param props
 * @constructor
 */
const CasePreviewAttachments: React.FC<props> = (props) => {
  const selected = useCasePreview((state) => state.case);
  if (!selected) return null;
  return (
    <div className="CasePreviewAttachments w-full border-2 border-slate-100 border-solid bg-slate-50  rounded-sm mt-2">
      <div className="flex gap-2 items-center border-0 border-solid pb-2 border-b-2 border-b-slate-200 p-2 bg-slate-100">
        <div className="bg-blue-100 px-1 rounded-sm border-blue-200 text-blue-800 border-2 border-solid ">
          <FileOutlined />
        </div>
        <div className="text-lg font-bold">Attachments</div>
      </div>
      <div className="mt-2 p-2">
        <div className="flex flex-wrap items-center mt-2 gap-6">
          {selected.attachment
            ?.filter((val) => val.slot === "image")
            .map((attach, index) => (
              <div key={index}>
                <div className="border bg-blue-50 border-blue-200 border-solid rounded-sm p-1">
                  <Image
                    src={ENDPOINT + attach.uri}
                    className="h-40 w-48 rounded-sm"
                  ></Image>
                  <div className="text-center font-semibold capitalize border-t-2 p-0.5">
                    {attach.type}
                  </div>
                  <div
                    className="flex cursor-pointer text-lg gap-2 items-center justify-center hover:scale-110 transition-all"
                    onClick={() => {
                      // window.open(ENDPOINT + attach.uri, "_blank");
                      saveAs(
                        ENDPOINT + attach.uri,
                        attach.uri.slice(20, attach.uri.length)
                      );
                    }}
                  >
                    <DownloadOutlined />
                    <div className="font-semibold">Download</div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="mt-8 p-2 pt-0 pb-0">
        {selected.clinicalCondition?.traditional_impressions && (
          <Alert
            message="Its Traditional Impressions"
            showIcon
            type="info"
          ></Alert>
        )}
        <div className="flex"></div>
        <div className="flex flex-wrap items-center gap-6 mt-4">
          {selected.attachment
            ?.filter((val) => val.slot === "stl")
            .map((attach, index) => (
              <div key={index} className="keydiv">
                <StlCard url={attach.uri} type={attach.type} />
              </div>
            ))}
        </div>
      </div>
      <div className="mt-8 mb-2 p-2 pt-0 pb-0">
        <div className="flex flex-wrap items-center gap-6 mt-4">
          {selected.attachment
            ?.filter((val) => val.slot === "x-ray")
            .map((attach, index) => (
              <div key={index}>
                <div className="border border-blue-200 border-solid bg-blue-50 rounded-sm p-1">
                  <Image
                    src={ENDPOINT + attach.uri}
                    className="h-40 w-48 rounded-sm"
                  ></Image>
                  <div className="text-center font-semibold capitalize border-t-2 p-0.5">
                    {attach.type}
                  </div>
                  <div
                    className="flex cursor-pointer text-lg gap-2 items-center justify-center hover:scale-110 transition-all"
                    onClick={() => {
                      saveAs(
                        ENDPOINT + attach.uri,
                        attach.uri.slice(20, attach.uri.length)
                      );
                    }}
                  >
                    <DownloadOutlined />
                    <div className="font-semibold">Download</div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="mt-8 mb-2 p-2 pt-0 pb-0">
        {selected?.strippingProtocol?.file &&
          selected.strippingProtocol.file !== "" && (
            <TreatmentPlanPreviewSP
              fileUrl={`public/files/${selected.strippingProtocol.file}.docx`}
            />
          )}
      </div>
    </div>
  );
};

export default CasePreviewAttachments;
