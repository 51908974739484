import { Divider, Form, message, Result } from "antd";
import { FormBuilder, Loader } from "components";
import React, { useCallback, useState } from "react";
import { UserSearchHeading } from "./UserSearchHeading";
import { FormBuilderInputsProps } from "components/FormBuilder/types";
import { MailOutlined } from "@ant-design/icons";
import {  useReadQueryOnEvent} from "utils";
import { useUpdateEffect } from "hooks";
import { UserListTable } from "./UserListTable";

type props = {};

const FORM_ITEMS: FormBuilderInputsProps[] = [
  {
    name: "email",
    label: "Name or Email",
    type: "input",
    span: 6,
    prefix: <MailOutlined />,
    placeholder: "info@example.com",
  }
];

const UserSearch: React.FC<props> = (props) => {

  const { data, isLoading, isError, mutate } = useReadQueryOnEvent("search_users")
  const [form] = Form.useForm();

  const _handleKeyPress = useCallback((e: React.KeyboardEvent) => {
    if(e.charCode === 13) {
      e.preventDefault();
      let formValues = form.getFieldsValue();
      let email = formValues.email;
      if(email !== "") {
        mutate({ q: email });
      }
    }
  },[]);

  useUpdateEffect(() => {
    if (isError) {
      message.error(
        "There are error while fetching data please try again later."
      );
    }
  }, [isError]);

  /*if (!data) {
    return (
      <div className="bg-white p-1 m-1 rounded-sm h-full">
        <div className="flex flex-1 justify-center items-center">
          <Result
            status={"500"}
            title="No data"
            subTitle="Not expected Error while fetching data !"
          />
        </div>
      </div>
    );
  }*/

  return (
    <div className="h-full">
      <div className="bg-white p-1 h-full mx-1 my-2 rounded-sm">
        <UserSearchHeading />
        <Divider />
        <Form
          name="user"
          layout="vertical"
          autoComplete="off"
          onKeyPress={_handleKeyPress}
          form={form} >
          <FormBuilder form={form} inputs={FORM_ITEMS} />
        </Form>
        <Divider />
         <div className="context">
          { data && (<UserListTable data={data.data as IUser[]} /> ) }
        </div>
      </div>
    </div>
  );
};

export default UserSearch;
