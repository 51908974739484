/* -------------------------------------------------------------------------- */
/*                                    Settings                                    */
/* -------------------------------------------------------------------------- */

import { SettingOutlined } from "@ant-design/icons";
import { Button, Form, message, Modal, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import DetailsBox from "components/Boxes/DetailsBox";
import { useUser } from "models";
import useCasePreview from "./../../hooks/useCasePreview";
import { useDeleteMutation, useUpdateMutation } from "utils";
import { useUpdateEffect } from "hooks";
import { useState } from "react";

/* ---------------------------- Component Global ---------------------------- */
interface IProps {}

/* ---------------------------- Default function ---------------------------- */
const Settings = (props: IProps) => {
  const { mutateAsync, isError, isSuccess, isLoading } =
    useUpdateMutation("cases");
  const [form] = Form.useForm();
  const selected = useCasePreview((s) => s.case);
  const user = useUser((s) => s.user);

  const [isOpenMoveToArchive, setIsOpenMoveToArchive] = useState(false);
  const [isOpenRemove, setIsOpenRemove] = useState(false);

  const moveToArchiveMutation = useUpdateMutation("cases");
  const removeMutation = useDeleteMutation("cases", selected?.ID ?? 0);
  const handleMoveToArchive = async () => {
    // move to archive
    setIsOpenMoveToArchive(false);
    await moveToArchiveMutation.mutateAsync({
      ID: selected?.ID,
      StatusID: 100,
    });
    await message.success("Move to archive success");
    window.location.reload();
  };

  const handleRemove = async () => {
    // move to archive
    setIsOpenRemove(false);
    await removeMutation.mutateAsync();
    await message.success("Remove case success");
    window.location.reload();
  };

  useUpdateEffect(() => {
    if (isError) {
      message.error("There are error please try again later");
    }
    if (isSuccess) {
      message.success("Saving settings success");
    }
  }, [isSuccess, isError]);
  if (!selected || !user) return null;
  const handleSaveForm = async (values: any) => {
    // mutate
    await mutateAsync({
      ID: selected.ID,
      ...values,
    });
  };

  /* ----------------------------- Function return ---------------------------- */
  return (
    <div>
      <Modal
        okText="Yes sure"
        onOk={() => {
          handleMoveToArchive();
        }}
        title="Move case to archive"
        open={isOpenMoveToArchive}
        onCancel={() => setIsOpenMoveToArchive(false)}
      >
        <div className="text-lg">Do you want move this case to archive ?</div>
      </Modal>
      <Modal
        okText="Yes sure"
        onOk={() => {
          handleRemove();
        }}
        title="Remove Case"
        open={isOpenRemove}
        onCancel={() => setIsOpenRemove(false)}
      >
        <div className="text-lg">
          You well lose the data for ever are you sure ?
        </div>
      </Modal>
      <DetailsBox title="Settings" icon={<SettingOutlined />}>
        <div>
          <Form
            layout="vertical"
            form={form}
            onFinish={handleSaveForm}
            initialValues={{ statusID: selected.statusID, note: selected.note }}
          >
            <Form.Item label="Status" name="statusID">
              <Select
                disabled={user.type === "user"}
                defaultValue={selected.statusID}
                options={[
                  { label: "New case", value: 0 },
                  { label: "Receiving", value: 1 },
                  { label: "Waiting Approve", value: 2 },
                  { label: "Submitted Case", value: 3 },
                  { label: "Sending To Lab", value: 4 },
                  { label: "In Process", value: 5 },
                  { label: "Completed", value: 6 },
                ]}
              ></Select>
            </Form.Item>
            <Form.Item label="Notes " name="note">
              <TextArea rows={6} placeholder="Edit a note" />
            </Form.Item>
            <Button htmlType="submit" type="primary">
              Save
            </Button>
          </Form>
        </div>
      </DetailsBox>
      <div className="dangerZone mt-4 bg-red-400/40">
        <DetailsBox title="Danger Zone" icon={<></>}>
          <div className="flex gap-4">
            <Button
              onClick={() => setIsOpenRemove(true)}
              danger
              type="primary"
              loading={isLoading}
              disabled={isLoading}
            >
              Delete Case
            </Button>
            <Button onClick={() => setIsOpenMoveToArchive(true)}>
              Move to archive
            </Button>
          </div>
        </DetailsBox>
      </div>
    </div>
  );
};

/* ----------------------------- Default export ----------------------------- */
export default Settings;
