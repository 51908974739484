import { PicLeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

export function AddressHeading() {
  const navigate = useNavigate();
  return (
    <div className="heading">
      <div className="flex justify-between">
        <div className="headTitle">
          <div className="flex gap-2 items-center">
            <div className="bg-blue-100 px-1 rounded-sm border-blue-200 text-blue-800 border-2 border-solid">
              <PicLeftOutlined />
            </div>
            <div className="font-bold text-lg">Addresses</div>
          </div>
          <div className="font-semibold">Fill out the addresses</div>
        </div>
        <Button
          type="link"
          icon={<PlusOutlined />}
          onClick={() => {
            navigate("/new_address");
          }}
        >
          New address
        </Button>
      </div>
    </div>
  );
}
