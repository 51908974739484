import { Form, message, Spin } from "antd";
import Heading from "components/Cases/components/Heading";
import FormBuilder from "components/FormBuilder";
import React from "react";
import { formBuilderData } from "./Data";
import { useCase, useUser } from "models";
import shallow from "zustand/shallow";
import { useCreateMutation } from "./../../../../utils/Api.utils";
import { useEffectOnce, useUpdateEffect } from "hooks";
import { useCreateCase } from "pages/cases/create_case/hooks";
import notification from "utils/Notification";
import email, { MANAGER_EMAIL } from "utils/Email.utils";

type props = {};

const defaultValue = {
  archToTreat: "0",
  toothMovement: [],
};

const Prescription: React.FC<props> = (props) => {
  const [form] = Form.useForm();
  const setPrescription = useCase((s) => s.setPrescription);
  const caseType = useCase((s) => s.caseType);
  const [patient, attachments, clinicalCondition] = useCase(
    (state) => [
      state.patient,
      state.attachments,
      state.clinicalCondition,
      state.prescription,
    ],
    shallow
  );
  function handleFormSubmit(values: IPrescription) {
    setPrescription(values);
    save(values);
  }

  const {
    isLoading,
    isError,
    isSuccess,
    mutate,
    data: ServerData,
  } = useCreateMutation<any, any>("cases");
  const user = useUser((state) => state.user);
  const strippingProtocol = useCase((e) => e.strippingProtocol);
  function save(values: IPrescription) {
    const data = {
      statusID: 0,
      userID: user?.ID,
      patient,
      attachment: attachments,
      clinicalCondition,
      prescription: values,
      caseType,
      strippingProtocol,
    };
    mutate(data);
  }

  useUpdateEffect(() => {
    if (isError) {
      message.error(`There are error while save the case`);
    }
  }, [isError]);

  const nextPage = useCreateCase((state) => state.nextPage);

  useUpdateEffect(() => {
    if (isSuccess) {
      nextPage();
    }
  }, [isSuccess]);

  useUpdateEffect(() => {
    if (ServerData) {
      notification.send({
        userID: user?.ID,
        message: `${user?.name} Created case #0000${ServerData.data.ID}`,
        alertType: "info",
        notificationType: "default",
        targetType: "manager",
        caseID: ServerData.data.ID,
      });
      email.send({
        to: MANAGER_EMAIL,
        subject: "New Case Received",
        data: {
          title: "New Case Received",
          user: "Manager",
          body: `New Case Received from user ${user?.name} with case ID ${ServerData.data.ID}`,
          url: `${ServerData.data.ID}`,
        },
      });
    }
  }, [ServerData]);

  useEffectOnce(() => {
    form.scrollToField("archToTreat");
  });

  return (
    <div className="Prescription relative">
      <Heading
        title="Prescription"
        description="You can fill Prescription to improve case instructions"
      />
      <Form
        initialValues={defaultValue}
        name="Prescription"
        layout="vertical"
        onFinish={handleFormSubmit}
        autoComplete="off"
        form={form}
      >
        <FormBuilder form={form} inputs={formBuilderData} />
      </Form>
      {isLoading && (
        <div className="loading absolute top-0 left-0 z-10 w-full h-full bg-slate-50/75 flex items-center justify-center flex-col font-semibold">
          <Spin size="large" />
          <div className="text-center text-lg mt-2">Loading</div>
        </div>
      )}
    </div>
  );
};

export default Prescription;
