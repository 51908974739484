/**
 * useUser
 */

 import create from 'zustand'
import { http } from '../utils/Api.utils'


interface IUseUser {
    user?:IUser,
    isAuth?:boolean
    auth:() => Promise<void>
    logout:() => void
    setUser:(token:string,user:IUser) => void
}

const useUser = create<IUseUser>((set) => ({
    async auth() {
        const token = localStorage.getItem("userToken")
        if(token) {
            try {
                const result = await http.get<IUser>("user/auth",{
                    headers:{
                        Authorization: "Bearer " + token
                    }
                })
                set(() => ({
                    user:result.data,
                    isAuth:true
                }))
            } catch (error) {
            }
        }
    },
    logout() {
        localStorage.removeItem("userToken")
        window.location.reload();
    },
    setUser(token, user) {
        localStorage.setItem("userToken",token)
        set(() => ({
            user,
            isAuth:true
        }))
    },
}))


export default useUser;