/**
 * Use Case
 */
import create from "zustand";
import { IUseCase } from "./types";

export default create<IUseCase>((set) => ({
  setPatient(patient) {
    set(() => ({ patient }));
  },
  setClinicalCondition(clinicalCondition) {
    set(() => ({ clinicalCondition }));
  },
  setAttachments(attachments) {
    set(() => ({ attachments }));
  },
  setPrescription(prescription) {
    set(() => ({ prescription }));
  },
  setCaseType(caseType) {
    set(() => ({ caseType }));
  },
  setStrippingProtocol(strippingProtocol) {
    set(() => ({ strippingProtocol }));
  },
}));
