/* eslint-disable react-hooks/rules-of-hooks */
import { EditFilled } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, InputNumber, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Loader } from "components";
import { UrlList } from "components/Uploads/ImagesUpload/ImagesUpload";
import React, { useState } from "react";
import { useUpdateMutation } from "utils";
import useCasePreview from "../hooks/useCasePreview";
import Header from "./TreatmentPlanEditComponent/Header";
import TreatmentPlanFilesEditor from "./TreatmentPlanEditComponent/TreamentPlanFilesEditor";
import TreatmentPlanStripping from "./TreatmentPlanEditComponent/TreatmentPlanStripping";

const TreatmentPlanEdit = (props: { onFinish: () => void }) => {
  // Selected Case
  const selected = useCasePreview((state) => state.case);
  if (!selected) return null; // return null if case not found
  // get files
  const files = React.useMemo<UrlList[]>(() => {
    return JSON.parse(selected.treatmentPlan.files);
  }, [selected]);
  // images List
  const [imageFiles, setImageFiles] = useState(
    files.filter((trg) => trg.type === "images")
  );
  // document files
  const [docmentFiles, setDocumentFiles] = useState(
    files.filter((trg) => trg.type === "documents")
  );
  // files video
  const [videoFiles, setVideoFiles] = useState(
    files.filter((trg) => trg.type === "videos")
  );
  // d files
  const [dFiles, setDFiles] = useState(
    files.filter((trg) => trg.type === "3dFile")
  );
  // file files
  const [fileFiles, setFileFiles] = useState(
    files.filter((trg) => trg.type === "files")
  );
  
  const [link, setLink ] = useState(selected?.treatmentPlan.link);
  // extra
  const [extra, setExtra] = useState(JSON.parse(selected.treatmentPlan.extra));
  // stripping protocol file
  const [strippingProtocolFile, setStrippingProtocolFile] = React.useState(
    selected.treatmentPlan.strippingProtocolFile ?? ""
  );
  // stripping protocol data
  const [strippingProtocolData, setStrippingProtocolData] = React.useState(
    selected.treatmentPlan.strippingProtocolData ?? ""
  );

  // text
  const [text, setText] = useState(selected.treatmentPlan.message);

  // cost
  const [cost, setCost] = useState(selected.treatmentPlan.cost);

  // loading
  const [loading, setLoading] = useState(false);

  // loading, is error , is success
  const { isLoading, isError, isSuccess, mutateAsync, mutate } =
    useUpdateMutation("treatment_plan");

  // handle submit form
  const handleSubmitForm = async () => {
    const data = {
      ID: selected.treatmentPlan.ID,
      files: JSON.stringify([
        ...imageFiles,
        ...docmentFiles,
        ...videoFiles,
        ...dFiles,
        ...fileFiles,
      ]),
      message: text,
      extra: JSON.stringify(extra),
      strippingProtocolFile,
      strippingProtocolData,
      cost,
      link
    };
    await mutateAsync(data);
    message.success("Treatment Plan Updating Success");
    props.onFinish();
  };

  return (
    <div className="TreatmentPlanEditView">
      {isLoading && <Loader />}
      <Header />
      <div className=" absolute right-0 top-0">
        <div className="flex justify-end p-4 gap-4">
          <Button
            icon={<EditFilled />}
            loading={isLoading}
            disabled={isLoading}
            type="primary"
            onClick={handleSubmitForm}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              props.onFinish();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
      <div className="p-2">
        <TreatmentPlanFilesEditor
          loading={loading}
          setLoading={setLoading}
          files={imageFiles}
          type="images"
          setFiles={setImageFiles}
        />

        <TreatmentPlanFilesEditor
          loading={loading}
          setLoading={setLoading}
          files={videoFiles}
          type="videos"
          setFiles={setVideoFiles}
        />
        <TreatmentPlanFilesEditor
          loading={loading}
          setLoading={setLoading}
          files={docmentFiles}
          type="documents"
          setFiles={setDocumentFiles}
        />
        <TreatmentPlanFilesEditor
          loading={loading}
          setLoading={setLoading}
          files={dFiles}
          type="3dFile"
          setFiles={setDFiles}
        />
        <TreatmentPlanFilesEditor
          loading={loading}
          setLoading={setLoading}
          files={fileFiles}
          type="files"
          setFiles={setFileFiles}
        />
        <TreatmentPlanStripping
          onFinish={(url, fields) => {
            setStrippingProtocolFile(url);
            setStrippingProtocolData(fields);
          }}
          initalFields={
            selected.treatmentPlan.strippingProtocolData ?? undefined
          }
          initalUrl={selected.treatmentPlan.strippingProtocolFile ?? undefined}
        />
        <div className="Instructions mt-4">
          <label>Instruction</label>
          {/* Extra instructions */}
          <Form.Item label="Number of Aligner" className="mt-4">
            <div className="flex gap-2 items-center">
              <div className="labelView font-medium">Upper</div>
              <InputNumber
                placeholder="upper"
                value={extra.upper}
                onChange={(e) => setExtra({ ...extra, upper: e || 0 })}
              />
              <div className="labelView font-medium">Lower</div>
              <InputNumber
                placeholder="lower"
                value={extra.lower}
                onChange={(e) => setExtra({ ...extra, lower: e || 0 })}
              />
            </div>
          </Form.Item>
          <Form.Item label="Attachment Timing">
            <Input
              value={extra.attachmentTiming}
              onChange={(e) =>
                setExtra({ ...extra, attachmentTiming: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="Stripping Protocol">
            <Checkbox
              value={extra.uploaded}
              onChange={(e) => setExtra({ ...extra, uploaded: e.target.value })}
            >
              Uploaded
            </Checkbox>
            <Checkbox
              value={extra.noStriping}
              onChange={(e) =>
                setExtra({ ...extra, noStriping: e.target.value })
              }
            >
              No Striping
            </Checkbox>
          </Form.Item>
          <Form.Item label="Anterior teeth will start to move at aligner number">
            <Input
              placeholder="Number"
              value={extra.startToMove}
              onChange={(e) =>
                setExtra({ ...extra, startToMove: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item label="3D Video Link">
            <Input
              placeholder="3D Video Link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          </Form.Item>
          <Input
            placeholder="Cost in SAR"
            value={cost}
            onChange={(e) => setCost(e.target.value)}
          />
          <TextArea
            value={text}
            onChange={(e) => setText(e.target.value)}
            rows={4}
            className="mt-2"
            placeholder="Message"
          />
        </div>
        <div className="buttonSave mt-4">
          <Button
            type="primary"
            className="w-full"
            loading={loading}
            disabled={loading}
            onClick={handleSubmitForm}
          >
            Save Treatment Plan
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TreatmentPlanEdit;
