import Heading from "components/Cases/components/Heading";
import React from "react";
import { Form } from "antd";
import FormBuilder from "components/FormBuilder";
import { clinicalConditionsInputs } from "./Data";
import { useCreateCase } from "pages/cases/create_case/hooks";
import shallow from "zustand/shallow";
import { useCase } from "models";

type props = {};

const ClinicalConditions: React.FC<props> = (props) => {
  const [form] = Form.useForm();
  const [nextPage, previousPage] = useCreateCase(
    (state) => [state.nextPage, state.previousPage],
    shallow
  );

  const [clinicalCondition, setClinicalCondition] = useCase(
    (state) => [state.clinicalCondition, state.setClinicalCondition],
    shallow
  );
  const handleSubmitForm = (values: IClinicalCondition) => {
    setClinicalCondition({ ...values, traditional_impressions: false });
    nextPage();
  };
  return (
    <div className="ClinicalConditions">
      <Heading
        title="Clinical conditions"
        description="Please fill the clinical conditions"
      />
      <Form
        name="clinicalConditions"
        layout="vertical"
        onFinish={handleSubmitForm}
        autoComplete={"off"}
        initialValues={clinicalCondition}
        form={form}
      >
        <FormBuilder
          form={form}
          inputs={[
            ...clinicalConditionsInputs,
            {
              type: "button",
              onClick: previousPage,
              name: "back",
              label: "Back",
              span: 12,
              className: "w-full mt-2",
            },
            {
              name: "submit",
              span: 12,
              type: "button",
              label: "Next",
              htmlType: "submit",
              className: "w-full mt-2",
              buttonType: "primary",
            },
          ]}
        />
      </Form>
    </div>
  );
};

export default ClinicalConditions;
