import Collapse from "@kunukn/react-collapse";
import { Descriptions, Image } from "antd";
import { useCase } from "models";
import React from "react";
import useCasePreview from "../../hooks/useCasePreview";
import "./TXInstruction.css";

type ExtraType = {
  upper: number;
  lower: number;
  attachmentTiming: string;
  uploaded: boolean;
  noStriping: false;
  startToMove: string;
};
const TreatmentPlanInstructions = () => {
  const selected = useCasePreview((state) => state.case);

  const extra = React.useMemo<ExtraType | undefined>(() => {
    if (!selected) return undefined;

    const data: ExtraType = JSON.parse(selected.treatmentPlan.extra);
    if (data) return data;
    return undefined;
  }, [selected]);
  const [isOpen, setIsOpen] = React.useState(true);
  if (!selected) return null;
  return (
    <div className="treatmentPlanInstructions mt-6">
      <div className="py-1 px-2 bg-red-600/[.8] rounded-sm  border-solid border-0 border-b-2 border-b-green-500 flex justify-between items-center">
        <div className="flex gap-2 items-center ">
          <div
            onClick={() => {
              setIsOpen((val) => !val);
            }}
            className="minus h-4 w-4 flex items-center cursor-pointer justify-center bg-red-50 font-bold rounded-sm"
          >
            {isOpen ? "-" : "+"}
          </div>
          <Image
            src="/attachments/instructions.png"
            width="1.5rem"
            height="1.5rem"
            preview={false}
          />
          <div className="text-2xl text-white ">Instruction</div>
        </div>
      </div>
      <Collapse isOpen={isOpen} className="collapse-css-transition">
        <div className="py-4 px-4 w-full bg_image">
          {extra && (
            <div className="listOfDetails">
              <Descriptions
                title="Instructions details"
                bordered
                layout="vertical"
              >
                <Descriptions.Item
                  label="Number of aligner"
                  labelStyle={{ fontWeight: "bold" }}
                >
                  Upper {extra.upper ?? ""} Lower {extra.lower ?? ""}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Attachment Timing"
                  labelStyle={{ fontWeight: "bold" }}
                >
                  {extra.attachmentTiming ?? ""}
                </Descriptions.Item>
                <Descriptions.Item
                  labelStyle={{ fontWeight: "bold" }}
                  label="Stripping Protocol"
                >
                  {extra.uploaded ? "Uploaded" : "No Striping"}
                </Descriptions.Item>
                <Descriptions.Item
                  labelStyle={{ fontWeight: "bold" }}
                  label="Anterior teeth will start to move at aligner number"
                >
                  {extra.startToMove}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Note"
                  labelStyle={{ fontWeight: "bold" }}
                >
                  {selected.treatmentPlan.message}
                </Descriptions.Item>
              </Descriptions>
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default TreatmentPlanInstructions;
