import React from "react";
import { AddressHeading } from "./AddressHeading";
import { Divider, Form, message } from "antd";
import { FormBuilderInputsProps } from "components/FormBuilder/types";
import { GlobalOutlined } from "@ant-design/icons";
import { FormBuilder, Loader } from "components";
import { useCreateMutation } from "./../../utils/Api.utils";
import { useUser } from "models";
import { useUpdateEffect } from "hooks";

type props = {};

const DATA: FormBuilderInputsProps[] = [
  {
    type: "input",
    name: "address",
    label: "Address",
    rules: [{ message: "Please fill the address field", required: true }],
    prefix: <GlobalOutlined />,
    span: 24,
    placeholder: "fill address",
  },
  {
    type: "input",
    name: "city",
    label: "City",
    rules: [{ message: "Please fill the city field", required: true }],
    span: 12,
    placeholder: "fill the city",
  },
  {
    type: "input",
    name: "zip",
    label: "Postal code",
    span: 12,
  },
  {
    type: "textarea",
    label: "Note",
    span: 24,
    name: "note",
  },
  {
    type: "button",
    htmlType: "submit",
    className: "w-full",
    span: 8,
    label: "Save address",
    name: "submit",
    buttonType: "primary",
  },
];

const NewAddress: React.FC<props> = (props) => {
  const [form] = Form.useForm();
  const user = useUser((state) => state.user);

  const { isLoading, isSuccess, isError, mutate } =
    useCreateMutation("addresses");
  function handleSubmitForm(values: any) {
    mutate({ ...values, userID: user?.ID });
  }

  useUpdateEffect(() => {
    if (isError) message.error("Error while create form");
    if (isSuccess) {
      form.resetFields();
      message.success("Creating address success");
    }
  }, [isSuccess, isError]);

  return (
    <div className="NewAddress h-full">
      <div className="bg-white p-1 m-1 rounded-sm h-full">
        <AddressHeading />
        <Divider />
        <div className="content">
          <Form
            name="address"
            autoComplete="off"
            layout="vertical"
            form={form}
            onFinish={handleSubmitForm}
          >
            <FormBuilder inputs={DATA} form={form} />
          </Form>
        </div>
        {isLoading && <Loader />}
      </div>
    </div>
  );
};

export default NewAddress;
