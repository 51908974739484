import { Col, Row } from "antd";
import React, { Fragment } from "react";
import FormBuilderItems from "./FormBuilderItems";
import { FormBuilderProps } from "./types";

const FormBuilder: React.FC<FormBuilderProps> = ({ inputs, form, ...rest }) => {
  return (
    <Fragment>
      <div {...rest}>
        <Row gutter={6}>
          {inputs.map((input) => (
            <Col className="gutter-row" key={input.name} span={input.span}>
              <FormBuilderItems {...input} form={form} />
            </Col>
          ))}
        </Row>
      </div>
    </Fragment>
  );
};

export default FormBuilder;
