import React from "react";
import { Typography } from "antd";

type props = {
  title: string;
  description?: string;
};

const Heading: React.FC<props> = (props) => {
  return (
    <div className="pb-2">
      <div className="Heading tracking-wider pb-2 border-0 border-b-2 border-b-slate-100 border-solid">
        <div className="text-2xl font-bold">{props.title}</div>
        {props.description && (
          <Typography.Text mark className=" font-semibold">
            {props.description}
          </Typography.Text>
        )}
      </div>
    </div>
  );
};

export default Heading;
