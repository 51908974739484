import { MessageOutlined } from "@ant-design/icons";
import { Result } from "antd";
import React from "react";

const TimeLinePreviewTreatmentPlanApprove = () => {
  return (
    <div className="bg-slate-50 border-solid border-slate-100 mb-2">
      <Header />
      <div className="p-2 flex items-center justify-center">
        <div className="Box">
          <Result
            title="Treatment plan has success approved"
            subTitle="You can review the treatment plan in treatment plan tab."
            status="success"
          />
        </div>
      </div>
    </div>
  );
};

export function Header() {
  return (
    <div className="treatmentPlanHeading p-2 border-solid border-0 border-b-2 border-b-slate-200 bg-slate-100">
      <div className="flex gap-2 items-center ">
        <div className="bg-blue-100 px-1 rounded-sm border-blue-200 text-blue-800 border-2 border-solid">
          <MessageOutlined />
        </div>
        <div className="text-lg font-bold">Treatment Plan Has Approved</div>
      </div>
      <div className="text-medium">Doctor has approve treatment plan</div>
    </div>
  );
}

export default TimeLinePreviewTreatmentPlanApprove;
