/* eslint-disable react/style-prop-object */
import { Alert, Col, Divider, Empty, Row } from "antd";
import "./dashboard.css";
import { useState } from "react";
import useSidePanel from "components/Layouts/useSidepanel";
import { useEffectOnce } from "hooks";
import notification from "utils/Notification";
import { AlertOutlined, BookOutlined } from "@ant-design/icons";
import { useUser } from "models";
import { useNavigate } from "react-router-dom";

function CountCard(props: {
  title: string;
  value: any;
  className?: string;
  onClick?: () => void;
}) {
  return (
    <div
      onClick={props.onClick}
      className={
        "border-2 flex-1 bg-slate-100 cursor-pointer hover:bg-slate-200 rounded-sm p-2 mx-2  " +
        props.className
      }
    >
      <div className="text-lg font-semibold text-center">{props.title}</div>
      <Divider />
      <div className="text-xl font-bold text-center">{props.value}</div>
    </div>
  );
}

const Dashboard = () => {
  const badges = useSidePanel((state) => state.badges);
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState<INotification[]>([]);

  const user = useUser((state) => state.user);
  if (!user) return null;

  // use layout

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffectOnce(() => {
    (async () => {
      const userType = user.type;
      const data = await notification.fetch("", {
        where: userType === "user" ? "user_id = " + user.ID : "",
        limit: 30,
        order: "ID desc",
      });
      if (data) {
        setNotifications(data);
      }
    })();
  });
  return (
    <div className="bg-white mx-2 my-2 rounded-md">
      <div className="flex gap-2 items-center p-1 bg-blue-50 border-solid border-0 border-b-2 border-b-slate-100">
        <div className="bg-blue-100 px-1 rounded-sm border-blue-200 text-blue-800 border-2 border-solid">
          <BookOutlined />
        </div>
        <div className="font-bold text-xl sf">Cases</div>
      </div>
      <div className="md:flex items-center lg:flex mt-2">
        <CountCard
          onClick={() => {
            navigate("/cases/case_list/new_cases", { replace: true });
          }}
          title="New cases"
          value={badges["newCases"]}
          className="bg-indigo-200 hover:bg-indigo-300"
        />
        <CountCard
          onClick={() => {
            navigate("/cases/case_list/need_approve", { replace: true });
          }}
          title="Waiting Treatment plan"
          value={badges["needApproved"]}
          className="bg-red-200 hover:bg-red-300"
        />
        <CountCard
          onClick={() => {
            navigate("/cases/case_list/in_process", { replace: true });
          }}
          title="In Process"
          value={badges["inProcessCases"]}
          className="bg-blue-200 hover:bg-blue-300"
        />
        <CountCard
          title="Completed Cases"
          onClick={() => {
            navigate("/cases/case_list/completed", { replace: true });
          }}
          value={badges["completedCases"]}
          className="bg-green-200 hover:bg-green-300"
        />
      </div>
      <Divider />
      <div className="mt-4 px-2">
        <Row gutter={4}>
          <Col span={12} xs={24} md={12} sm={24} className="bg-slate-50 ">
            <div className="flex gap-2 items-center bg-blue-50 border-solid border-0 border-b-2 border-b-slate-100 p-1">
              <div className="bg-blue-100 px-1 rounded-sm border-blue-200 text-blue-800 border-2 border-solid">
                <AlertOutlined />
              </div>
              <div className="font-bold text-xl">Alerts</div>
            </div>
            {notifications.filter((trg) => trg.notificationType === "default")
              .length === 0 && (
              <Empty
                className="font-bold"
                description="Not received alert yet"
              />
            )}
            {notifications
              .filter((trg) => trg.notificationType === "default")
              .slice(0, 10)
              .map((trg, index) => (
                <Alert
                  key={index}
                  className="m-2 font-semibold cursor-pointer"
                  message={trg.message}
                  type={trg.alertType}
                  showIcon
                  description={`Case ID: 000${trg.caseID}`}
                  onClick={() => {
                    navigate("cases/case_preview/" + trg.caseID);
                  }}
                />
              ))}
          </Col>
          <Col
            span={12}
            md={12}
            xs={24}
            sm={24}
            className="bg-slate-50 rounded-md h-64"
          >
            <div className="p-2xx">
              <div className="flex gap-2 items-center border-solid border-0 border-b-2 border-b-slate-100 bg-blue-50 p-1">
                <div className="bg-blue-100 px-1 rounded-sm border-blue-200 text-blue-800 border-2 border-solid">
                  <AlertOutlined />
                </div>
                <div className="font-bold text-xl">Need attention</div>
              </div>
              <div className="flex flex-1 items-center justify-center">
                {notifications.filter(
                  (trg) => trg.notificationType === "needAttention"
                ).length === 0 && (
                  <Empty
                    className="font-bold"
                    description="No cases need attention for now"
                  />
                )}
              </div>
              <div className="view">
                {notifications
                  .filter((trg) => trg.notificationType === "needAttention")
                  .slice(0, 10)
                  .map((trg, index) => (
                    <Alert
                      key={index}
                      className="m-2 font-semibold cursor-pointer"
                      message={trg.message}
                      type={trg.alertType}
                      showIcon
                      description={`Case ID: 000${trg.caseID}`}
                      onClick={() => {
                        navigate("cases/case_preview/" + trg.caseID);
                      }}
                    />
                  ))}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;
