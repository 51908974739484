import { Col, message, Row } from "antd";
import CastItemsHeader from "./components/CastItemsHeader";
import { useReadQuery } from "utils";
import { useUpdateEffect } from "hooks";
import { Loader } from "components";
import { ItemCard } from "./components/ItemCard";

const CastItems = () => {
  const { data, isLoading, isError } = useReadQuery<{ data: IItem[] }>("items");

  useUpdateEffect(() => {
    if (isError) {
      message.error("There are error please try again.");
    }
  }, [isError]);
  return (
    <div className="castItems bg-white h-full p-1 m-1 rounded-sm">
      <CastItemsHeader />
      <div className="body">
        {isLoading && <Loader />}
        <Row gutter={12}>
          {data?.data.map((item) => (
            <Col span={6} xs={24} sm={24} md={12} lg={12} xl={6} key={item.ID}>
              <ItemCard item={item} />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default CastItems;
