import React from "react";
import { Layout, Spin } from "antd";
import { Content } from "antd/lib/layout/layout";
import Logo from "../Logo/Logo";

type props = {};

const Splash: React.FC<props> = (props) => {
  return (
    <div className="fullPage">
      <Layout className="fullPage">
        <Content
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
          className="fullPage"
        >
          <Logo size="md" />
          <Spin size="large" style={{ marginTop: 20 }} />
        </Content>
      </Layout>
    </div>
  );
};

export default Splash;
