import { PlusOutlined, UserSwitchOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

export function UserListHeading() {
  const navigate = useNavigate();
  return (
    <div className="flex justify-between">
      <div className="headTitle">
        <div className="flex gap-2 items-center">
          <div className="bg-blue-100 px-1 rounded-sm border-blue-200 text-blue-800 border-2 border-solid">
            <UserSwitchOutlined />
          </div>
          <div className="font-bold text-lg">Users</div>
        </div>
        <div className="font-semibold">Manage users in the software</div>
      </div>
      <Button
        type="link"
        icon={<PlusOutlined />}
        onClick={() => {
          navigate("/new_user");
        }}
      >
        New user
      </Button>
    </div>
  );
}
