import { KeyOutlined } from "@ant-design/icons";
import { Divider, Form, message } from "antd";
import { FormBuilder, Loader } from "components";
import { useUser } from "models";
import React from "react";
import { useCreateMutation } from "utils";
import { ChangePasswordHeading } from "./ChangePasswordHeading";

type props = {};

interface PasswordChangeValues {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const ChangePassword: React.FC<props> = (props) => {
  const [form] = Form.useForm();
  const user = useUser((state) => state.user);

  const { isLoading, isError, mutate, isSuccess } = useCreateMutation(
    "user/change_password"
  );

  React.useEffect(() => {
    //
    if (isError)
      return message.error("There are error, Please check your old password");
    if (isSuccess) return message.success("Change password success");
  }, [isSuccess, isError]);

  function handleSubmitForm(values: PasswordChangeValues) {
    if (values.newPassword !== values.confirmPassword) {
      message.error("Check passwords confirmed");
      return;
    }

    mutate({
      ID: user?.ID,
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    });
  }

  return (
    <div className="ChangePassword h-full">
      <div className="h-full">
        {isLoading && <Loader />}
        <div className="bg-white h-full p-1 m-1 rounded-sm">
          <ChangePasswordHeading />
          <Divider />
          <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmitForm}
            name="changePassword"
          >
            <FormBuilder
              inputs={[
                {
                  type: "input",
                  inputType: "password",
                  name: "oldPassword",
                  prefix: <KeyOutlined />,
                  label: "Current password",
                  span: 24,
                  placeholder: "please confirm your old password",
                  rules: [
                    {
                      required: true,
                      message: "Please fill the current password",
                    },
                  ],
                },
                {
                  type: "input",
                  inputType: "password",
                  name: "newPassword",
                  prefix: <KeyOutlined />,
                  label: "New Password",
                  span: 12,
                  placeholder: "please confirm your new password",
                  rules: [
                    { required: true, message: "Please fill the new password" },
                  ],
                },
                {
                  type: "input",
                  inputType: "password",
                  name: "confirmPassword",
                  prefix: <KeyOutlined />,
                  span: 12,
                  label: "Confirm Password",
                  placeholder: "Please confirm password",
                  rules: [
                    { required: true, message: "Please confirm password" },
                  ],
                },
                {
                  type: "button",
                  name: "submit",
                  htmlType: "submit",
                  buttonType: "primary",
                  label: "Save Password",
                },
              ]}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
