import React from "react";
import { useReadQuery } from "./../../../utils/Api.utils";
import { useUpdateEffect } from "hooks";
import { message } from "antd";
import { CasesStatus, CaseTable, Loader } from "components";

const CaseDetails = ({
  userID,
  userType,
}: {
  userID: number;
  userType: IUTypes;
}) => {
  const { data, isError, isLoading } = useReadQuery<{
    data: ICase[];
  }>("cases", "", {
    params: {
      where: `user_id = ${userID}`,
      preload: "User,Patient,ClinicalCondition,Attachment,Prescription",
    },
  });

  useUpdateEffect(() => {
    if (isError) message.error("There are error, please try again.");
  }, [isError]);
  if (!data) return null;
  if (!data.data) return null;
  return (
    <div>
      {isLoading && <Loader />}
      <h3>Cases list</h3>
      <div className="CaseList bg-white p-2 mx-1 my-1 mb-4 rounded-sm">
        <CaseTable
          data={data.data.map((trg) => ({
            ID: trg.ID ? trg.ID : 0,
            name: trg.patient.name,
            age: trg.patient.age,
            date: trg.CreatedAt ? trg.CreatedAt : "",
            status: CasesStatus[trg.statusID]
              ? CasesStatus[trg.statusID][userType].status
              : "",
            avatar: trg.patient.avatar,
            case_type: trg.caseType,
            waiting: CasesStatus[trg.statusID]
              ? CasesStatus[trg.statusID][userType].waiting
              : "",
          }))}
        />
      </div>
    </div>
  );
};

export default CaseDetails;
