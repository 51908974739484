import { Divider, Form, message } from "antd";
import { FormBuilder, Loader } from "components";
import React from "react";
import { UserListHeading } from "./UserListHeading";
import { FormBuilderInputsProps } from "components/FormBuilder/types";
import { MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { useCreateMutation } from "utils";
import { useUpdateEffect } from "hooks";

type props = {};

const DATA: FormBuilderInputsProps[] = [
  {
    name: "name",
    label: "User name",
    type: "input",
    placeholder: "ex: John",
    span: 24,
    rules: [{ required: true, message: "Please fill the name" }],
    prefix: <UserOutlined />,
  },
  {
    name: "phone",
    label: "Phone number",
    type: "input",
    placeholder: "966 - xxx",
    span: 12,
    rules: [{ required: true, message: "Please fill the phone number" }],
    prefix: <PhoneOutlined />,
  },
  {
    name: "email",
    label: "Email",
    type: "input",
    span: 12,
    rules: [{ required: true, message: "Please fill the email address" }],
    prefix: <MailOutlined />,
    placeholder: "info@example.com",
  },
  {
    name: "password",
    label: "Password",
    type: "input",
    span: 12,
    rules: [{ required: true, message: "Please fill the password" }],
    placeholder: "*****",
    inputType: "password",
  },
  {
    name: "confirmPassword",
    label: "Confirm password",
    type: "input",
    span: 12,
    rules: [{ required: true, message: "Please fill the confirm password" }],
    placeholder: "*****",
    inputType: "password",
  },
  {
    name: "type",
    label: "User role",
    type: "select",
    span: 24,
    rules: [{ required: true, message: "Please fill the required field" }],
    items: [
      { label: "User", value: "user" },
      { label: "Manager", value: "manager" },
      { label: "Assistant", value: "assistant" },
      {
        label: "Factory",
        value: "factory",
      },
    ],
  },
  {
    name: "submit",
    type: "button",
    label: "Save User",
    htmlType: "submit",
    className: "w-full",
    span: 6,
    buttonType: "primary",
  },
];

const NewUser: React.FC<props> = (props) => {
  const [form] = Form.useForm();

  const { mutate, isLoading, isSuccess } = useCreateMutation("users");

  function handleSubmitForm(values: any) {
    mutate(values);
  }

  useUpdateEffect(() => {
    if (isSuccess) {
      message.success("Add user success");
    }
  }, [isSuccess]);
  return (
    <div className="NewUser h-full">
      <div className="bg-white p-1 h-full mx-1 my-2 rounded-sm">
        <UserListHeading />
        <Divider />
        <Form
          name="user"
          layout="vertical"
          onFinish={handleSubmitForm}
          autoComplete="off"
          form={form}
        >
          <FormBuilder form={form} inputs={DATA} />
        </Form>
        {isLoading && <Loader />}
      </div>
    </div>
  );
};

export default NewUser;
