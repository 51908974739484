import { DeleteFilled, ShoppingCartOutlined } from "@ant-design/icons";
import { Badge, Empty, Image, message, Modal } from "antd";
import assets from "assets";
import useCart from "models/useCart";
import React from "react";
import { UrlList } from "components/Uploads/ImagesUpload/ImagesUpload";
import { ENDPOINT, useCreateMutation } from "utils";
import { useUser } from "models";
import { useUpdateEffect } from "hooks";
import { Loader } from "components";

const CastItemsCart = () => {
  const cartItems = useCart((state) => state.cartItems);
  const [isOpen, setIsOpen] = React.useState(false);
  const user = useUser((state) => state.user);
  const setCartItems = useCart((state) => state.setCartItems);

  const { isError, isLoading, isSuccess, mutate } = useCreateMutation("cart");

  useUpdateEffect(() => {
    if (isError) message.error("There are error saving cart");
    if (isSuccess) {
      message.success("Success save cart details");
      setIsOpen(false);
      setCartItems([]);
    }
  }, [isError, isSuccess]);
  const checkout = async () => {
    if (cartItems.length === 0)
      return message.error("Please add item to cart first");
    // check out
    const data = {
      cartItems: JSON.stringify(
        cartItems.map((trg) => ({ itemID: trg.itemID, qty: trg.qty }))
      ),
      userID: user?.ID,
    };

    try {
      mutate(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="castItemsCart px-2 mt-2">
      {isLoading && <Loader />}
      <div
        className="castItemBl flex items-center gap-1 cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        <div className="icon text-3xl">
          <ShoppingCartOutlined />
        </div>
        <div className="label">
          <Badge count={cartItems.length}></Badge>
        </div>
      </div>
      <Modal
        open={isOpen}
        title="Cart"
        onOk={checkout}
        onCancel={() => setIsOpen(false)}
        okText="Checkout items"
      >
        <div className="itemsList">
          {cartItems.length === 0 && <Empty description="Cart Empty" />}
          {cartItems.map((cartItem, index) => (
            <ItemCard key={index} cartItem={cartItem} />
          ))}
          <div className="selectItems"></div>
        </div>
      </Modal>
    </div>
  );
};

const ItemCard = ({ cartItem }: { cartItem: ICartItems }) => {
  const images = React.useMemo<UrlList[]>(() => {
    return JSON.parse(cartItem.item.image);
  }, [cartItem.item.image]);
  const cartItems = useCart((state) => state.cartItems);
  const setCartItems = useCart((state) => state.setCartItems);
  const removeItem = () => {
    setCartItems(cartItems.filter((trg) => trg.itemID !== cartItem.itemID));
  };
  return (
    <div className="itemCard py-2 border-solid border-0 border-b-2 border-b-slate-100">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div className="image">
            <Image
              src={
                images.length === 0
                  ? assets.logo.logo
                  : ENDPOINT + images[0].url
              }
              preview={false}
              width={"4rem"}
              height="4rem"
            />
          </div>
          <div className="details">
            <div className="text-lg font-medium">{cartItem.item.title}</div>
            <div className="qty mt-1">x{cartItem.qty}</div>
          </div>
        </div>
        <div className="close text-red-500 text-lg cursor-pointer">
          <DeleteFilled onClick={removeItem} />
        </div>
      </div>
    </div>
  );
};

export default CastItemsCart;
