/* eslint-disable react-hooks/rules-of-hooks */
/* -------------------------------------------------------------------------- */
/*                                    EditAttachments                                    */
/* -------------------------------------------------------------------------- */

import { Divider, message } from "antd";
import assets from "assets";
import React from "react";
import ImageUpload from "components/Uploads/ImageUpload";
import { ENDPOINT, useUpdateMutation } from "utils";
import useCasePreview from "../hooks/useCasePreview";
import ImageUploadEdit from "./../../../../components/Uploads/ImageUploadEdit/ImageUploadEdit";

/* ---------------------------- Component Global ---------------------------- */
interface IProps {}

/* ---------------------------- Default function ---------------------------- */
const EditAttachments = (props: IProps) => {
  const selected = useCasePreview((s) => s.case);
  if (!selected) return null;
  const [imagesAttachments, setImagesAttachments] = React.useState(
    selected?.attachment.filter((s) => s.slot === "image")
  );
  const [xRay] = React.useState(
    selected.attachment.filter((s) => s.slot === "x-ray")
  );
  const [stl] = React.useState(
    selected.attachment.filter((s) => s.slot === "stl")
  );

  const updateMutation = useUpdateMutation<any, any>("attachments");

  const update = async (ID: any, uri: string) => {
    await updateMutation.mutateAsync({ ID, uri });
    message.success("Updating attachment success");
  };

  /* ----------------------------- Function return ---------------------------- */
  return (
    <>
      <div>
        <div className="text-lg font-semibold">Edit Attachments</div>
        <Divider />
        {/* Image upload */}
        <div className="img">
          <div className="text-md font-bold">Images</div>
        </div>
        <div className="flex flex-wrap">
          {imagesAttachments.map((img) => (
            <ImageUploadEdit
              isImage
              showDelete
              onChangeImage={(uri) => update(img.ID, uri)}
              label={img.type}
              initialImage={"/attachments/a01.svg"}
              theImage={img.uri}
            />
          ))}
        </div>
        <div className="img">
          <div className="text-md font-bold">X-Ray</div>
        </div>
        <div className="flex flex-wrap">
          {xRay.map((img) => (
            <ImageUploadEdit
              isImage
              showDelete
              onChangeImage={(uri) => update(img.ID, uri)}
              label={img.type}
              initialImage={"/attachments/a01.svg"}
              theImage={img.uri}
            />
          ))}
        </div>
        <div className="img">
          <div className="text-md font-bold">Stl</div>
        </div>
        <div className="flex flex-wrap">
          {stl.map((img) => (
            <ImageUploadEdit
              isImage
              showDelete
              onChangeImage={(uri) => update(img.ID, uri)}
              label={img.type}
              initialImage={"/attachments/a01.svg"}
              theImage={img.uri}
            />
          ))}
        </div>
      </div>
    </>
  );
};

/* ----------------------------- Default export ----------------------------- */
export default EditAttachments;
