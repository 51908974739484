import { BoxPlotOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import React from "react";
import CastItemsCart from "./CastItemsCart";

const CastItemsHeader = () => {
  return (
    <div className="newItemHeader">
      <div className="flex justify-between">
        <div className="headTitle">
          <div className="flex gap-2 items-center">
            <div className="bg-blue-100 px-1 rounded-sm border-blue-200 text-blue-800 border-2 border-solid">
              <BoxPlotOutlined />
            </div>
            <div className="font-bold text-lg">Items</div>
          </div>
          <div className="font-semibold">You can order items from bellow</div>
        </div>
        <CastItemsCart />
      </div>
      <Divider />
    </div>
  );
};

export default CastItemsHeader;
