import { Image } from "antd";
import assets from "assets";
import React from "react";

type sizes = "xs" | "sm" | "md" | "lg" | undefined;

type props = {
  size: sizes;
};

const SizeHandler = (size: sizes) => {
  switch (size) {
    case "xs":
      return 40;

    case "sm":
      return 90;
    case "md":
      return 120;
    case "lg":
      return 150;
    default:
      return 120;
  }
};

const Logo: React.FC<props> = (props) => {
  return (
    <div>
      <Image
        width={"auto"}
        height={SizeHandler(props.size)}
        src={assets.logo.logo}
        preview={false}
      />
    </div>
  );
};

export default Logo;
