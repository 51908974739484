import {
  BookOutlined,
  FileImageOutlined,
  FileSyncOutlined,
  MessageOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  message,
  Result,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import { ImagesUpload } from "components";
import { UrlList } from "components/Uploads/ImagesUpload/ImagesUpload";
import { useUpdateEffect } from "hooks";
import { useUser } from "models";
import React from "react";
import { ENDPOINT, useCreateMutation } from "utils";
import email from "utils/Email.utils";
import notification from "utils/Notification";
import useCasePreview from "../hooks/useCasePreview";
import { GetStatus } from "../utils/StatusHelper";
import TreatmentPlanStripping from "./TreatmentPlanEditComponent/TreatmentPlanStripping";

type props = {};

const TreatmentPlanSubmit: React.FC<props> = (props) => {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = React.useState(false);

  // Files lists
  const [imagesList, setImagesList] = React.useState<UrlList[]>([]);
  const [videosList, setVideosList] = React.useState<UrlList[]>([]);
  const [documentsList, setDocumentsList] = React.useState<UrlList[]>([]);
  const [fileList, setFileList] = React.useState<UrlList[]>([]);

  const [extra, setExtra] = React.useState({
    upper: 0,
    lower: 0,
    attachmentTiming: "",
    uploaded: false,
    noStriping: false,
    startToMove: "",
  });

  const statusSending = useCreateMutation("status");
  const sending = useCreateMutation("treatment_plan");
  // Use Update effect
  useUpdateEffect(() => {
    if (sending.isError) {
      message.error("There are error while submit status.");
    }
    if (sending.isSuccess) {
      message.success("Submit status success");
    }
  }, [sending.isSuccess, sending.isError]);

  // user check
  const user = useUser((state) => state.user);

  const [spFile, setSpFile] = React.useState("");
  const [spData, setSpData] = React.useState("");
  // case preview
  const casePreview = useCasePreview((state) => state.case);
  if (!user || !casePreview) return null;
  // handle submit form
  const handleSubmitForm = async (values: any) => {
    await sending.mutateAsync({
      ...values,
      userID: user.ID,
      caseID: casePreview.ID,
      statusID: casePreview.statusID,
      files: JSON.stringify([
        ...imagesList,
        ...videosList,
        ...documentsList,
        ...fileList,
      ]),
      extra: JSON.stringify(extra),
      strippingProtocolFile: spFile,
      strippingProtocolData: spData,
    });
    await axios.put(ENDPOINT + "api/cases", {
      ID: casePreview.ID,
      statusID: 2,
    });
    await statusSending.mutateAsync({
      userID: user.ID,
      caseID: casePreview.ID,
      statusID: 2,
      files: [],
    });
    notification.send({
      userID: casePreview.userID,
      caseID: casePreview.ID,
      message: `Case #0000${casePreview.ID} has treatment plan check it out`,
      alertType: "success",
      notificationType: "needAttention",
      targetType: "user",
    });

    email.send({
      to: casePreview.user.email,
      subject: `Case ${casePreview.ID} received treatment plan`,
      data: {
        user: casePreview.user.name,
        title: `Case ${casePreview.ID} received treatment plan`,
        body: `The case ${casePreview.ID} for patient ${casePreview.patient.name} have treatment plan please check it`,
        url: `${casePreview.ID}`,
      },
    });
  };

  // status
  const status = GetStatus(casePreview.statusID);
  if (!status) return null;
  if (sending.isSuccess) {
    return (
      <Result
        status="success"
        title="Success success"
        subTitle="Sending treatment plan to doctor success, you must change the status if the case to confirm sending treatment plan"
      />
    );
  }

  return (
    <div className="TreatmentPlanSubmit">
      <div className="bg-slate-50 rounded-sm my-2 border-solid border-slate-100">
        <Header />
        <div className="p-2">
          <Form
            name="treatment_plan"
            form={form}
            layout="vertical"
            onFinish={handleSubmitForm}
            initialValues={{
              message: "",
            }}
          >
            <IconLabel
              icon={<FileImageOutlined />}
              title="Images"
              description="Upload Images For TX Plan"
            />
            <ImagesUpload
              fileList={imagesList}
              setFileList={setImagesList}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              type="images"
            />
            <IconLabel
              title="Files"
              icon={<FileSyncOutlined />}
              description="Have a other type of files ? Upload it here"
            />
            <ImagesUpload
              fileList={fileList}
              setFileList={setFileList}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              type="files"
            />
            <TreatmentPlanStripping
              onFinish={(url, data) => {
                setSpData(data);
                setSpFile(url);
              }}
            />
            <IconLabel
              title="Treatment Plan Instructions"
              icon={<MessageOutlined />}
              description="Add important instructions if needed"
            />
            {/* Extra instructions */}
            <Form.Item label="Expected Number of Aligner" className="mt-4">
              <div className="flex gap-2 items-center">
                <div className="labelView font-medium">Upper</div>
                <InputNumber
                  placeholder="upper"
                  value={extra.upper}
                  onChange={(e) => setExtra({ ...extra, upper: e || 0 })}
                />
                <div className="labelView font-medium">Lower</div>
                <InputNumber
                  placeholder="lower"
                  value={extra.lower}
                  onChange={(e) => setExtra({ ...extra, lower: e || 0 })}
                />
              </div>
            </Form.Item>
            {/* <Form.Item label="Actual Number of Aligner" className="mt-4">
              <div className="flex gap-2 items-center">
                <div className="labelView font-medium">Upper</div>
                <InputNumber
                  placeholder="upper"
                  value={extra.upper}
                  onChange={(e) => setExtra({ ...extra, upper: e || 0 })}
                />
                <div className="labelView font-medium">Lower</div>
                <InputNumber
                  placeholder="lower"
                  value={extra.lower}
                  onChange={(e) => setExtra({ ...extra, lower: e || 0 })}
                />
              </div>
            </Form.Item> */}
            <Form.Item label="Attachment Timing">
              <Input
                value={extra.attachmentTiming}
                onChange={(e) =>
                  setExtra({ ...extra, attachmentTiming: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item label="Stripping Protocol">
              <Checkbox
                checked={extra.uploaded}
                onChange={(e) =>
                  setExtra({ ...extra, uploaded: e.target.checked })
                }
              >
                Uploaded
              </Checkbox>
              <Checkbox
                checked={extra.noStriping}
                onChange={(e) =>
                  setExtra({ ...extra, noStriping: e.target.checked })
                }
              >
                No Striping
              </Checkbox>
            </Form.Item>
            <Form.Item label="Anterior teeth will start to move at aligner number">
              <Input
                placeholder="Number"
                value={extra.startToMove}
                onChange={(e) =>
                  setExtra({ ...extra, startToMove: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item name="cost" label="Cost">
              <Input placeholder="Number" />
            </Form.Item>
            <Form.Item name="link" label="Link">
              <Input placeholder="Link" />
            </Form.Item>
            {/* End extra instructions */}
            <Form.Item name="message">
              <TextArea rows={4} className="mt-2" placeholder="Message" />
            </Form.Item>

            <div className="submitButton mt-4">
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={isLoading}
                disabled={isLoading}
              >
                Submit Treatment Plan
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

function Header() {
  return (
    <div className="treatmentPlanHeading p-2 border-solid border-0 border-b-2 border-b-slate-200 bg-slate-100 ">
      <div className="flex gap-2 items-center ">
        <div className="bg-blue-100 px-1 rounded-sm border-blue-200 text-blue-800 border-2 border-solid">
          <MessageOutlined />
        </div>
        <div className="text-lg font-bold">Submit Treatment Plan</div>
      </div>
      <div className="text-medium">
        You can sending treatment plan information with the form bellow
      </div>
    </div>
  );
}

function IconLabel({
  icon,
  title,
  description,
}: {
  icon?: any;
  title: string;
  description?: string;
}) {
  return (
    <div className="IconLabel mt-2">
      <div className="TitleCard flex items-center gap-1 text-lg">
        <div className="TitleCardIcon">{icon}</div>
        <div className="title font-semibold">{title}</div>
      </div>
      {description && <div className="font-medium">{description}</div>}
    </div>
  );
}

export default TreatmentPlanSubmit;
