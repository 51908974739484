import { CloseSquareOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import React from "react";

type OptionProps = {
  title: string;
  fear: string;
};

type Props = {
  options: IItemOptions[];
  setOptions: React.Dispatch<React.SetStateAction<IItemOptions[]>>;
};

const OptionsSelect = ({ options, setOptions }: Props) => {
  const [values, setValues] = React.useState<OptionProps>({
    title: "",
    fear: "",
  });

  return (
    <div className="OptionsSelect">
      <div className="label font-medium text-md mb-1">Item Options</div>
      <div className="formValues flex items-center gap-2">
        <Input
          value={values.title}
          onChange={(e) => setValues({ ...values, title: e.target.value })}
          placeholder="Title"
        />
        <Input
          value={values.fear}
          onChange={(e) => setValues({ ...values, fear: e.target.value })}
          placeholder="Price"
        />
        <Button
          onClick={() => {
            setOptions((oldValues) => [...oldValues, values]);
            setValues({ title: "", fear: "" });
          }}
          icon={<PlusOutlined />}
        >
          Add To List
        </Button>
      </div>
      <div className="optionsList bg-slate-50 mt-2">
        <div className="heading bg-slate-100 border-solid border-0 border-b-slate-200 border-b-2 py-1 px-2 font-medium">
          Options list
        </div>
        <div className="p-2">
          {options.map((option, index) => (
            <div className="optionCard flex items-center gap-2" key={index}>
              <div className="values">
                {option.title}, {option.fear}
              </div>
              <div className="DeleteButton text-red-400 cursor-pointer">
                <CloseSquareOutlined
                  onClick={() => {
                    // remove from options
                    setOptions((oldValues) =>
                      oldValues.filter((trg, inx) => inx !== index)
                    );
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OptionsSelect;
