import { Alert, Result } from "antd";
import { useEffectOnce } from "hooks";
import { useUser } from "models";
import React from "react";
import { useNavigate } from "react-router-dom";
import notification from "utils/Notification";
import MessagesHeader from "./components/MessagesHeader";

type props = {};

const Messages: React.FC<props> = (props) => {
  const [notifications, setNotifications] = React.useState<INotification[]>([]);

  const navigate = useNavigate();

  const user = useUser((state) => state.user);
  if (!user) return null;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffectOnce(() => {
    (async () => {
      const userType = user.type;
      const data = await notification.fetch("", {
        where: userType === "user" ? "user_id = " + user.ID : "",
        limit: 20,
        order: "ID desc",
      });
      if (data) {
        setNotifications(data);
      }
    })();
  });

  return (
    <div className="Messages bg-white mx-2 my-2 rounded-md">
      <MessagesHeader />
      <div className="container p-2">
        {notifications.map((trg, index) => (
          <Alert
            key={index}
            className="m-2 font-semibold cursor-pointer"
            onClick={() => {
              navigate(`/cases/case_preview/${trg.caseID}`);
            }}
            message={trg.message}
            type={trg.alertType}
            showIcon
            description={`Case ID 000${trg.caseID}`}
          />
        ))}
      </div>
      {notifications.length === 0 && (
        <Result
          status={"500"}
          title="Empty page"
          subTitle="There are no messages here"
        />
      )}
    </div>
  );
};

export default Messages;
