import { Avatar, message, Typography, Upload, UploadProps } from "antd";
import React from "react";
import { ENDPOINT } from "utils";

import { UserOutlined } from "@ant-design/icons";

type props = {
  place: string;
  onUpload: (uri: string) => void;
  title?: string;
};

const AvatarUpload: React.FC<props> = ({ place, onUpload, title }) => {
  const [isSelect, setIsSelect] = React.useState(false);
  const props: UploadProps = {
    name: "image",
    action: ENDPOINT + "image",
    data: {
      place,
    },
    listType: "picture-card",
    onChange(info) {
      setIsSelect(true);

      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        onUpload(info.file.response.id);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  return (
    <div className="AvatarUpload">
      <Upload {...props}>
        {!isSelect && (
          <div>
            <Avatar
              style={{ backgroundColor: "#88b24f" }}
              icon={<UserOutlined />}
              size={64}
              shape="square"
            />
            <Typography.Text style={{ fontSize: 12 }} strong type="secondary">
              {title ? title : "Upload image"}
            </Typography.Text>
          </div>
        )}
      </Upload>
    </div>
  );
};

export default AvatarUpload;
