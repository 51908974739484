/* eslint-disable import/no-anonymous-default-export */
/**
 * User Component
 *
 */

// Libraries
import React from "react";
import { useParams } from "react-router-dom";
import UserHeading from "./components/UserHeading";
import UserTabs from "./components/UserTabs";
import { useShowQuery } from "utils";
import { message, Spin } from "antd";
import { useUpdateEffect } from "hooks";

// View
export default () => {
  const params = useParams<{ id: string }>();
  if (!params.id) return null;

  const { isError, isLoading, data } = useShowQuery<IUser>("users", params.id);

  useUpdateEffect(() => {
    if (isError) {
      message.error("There are error while get the data");
    }
  }, [isError]);
  if (isLoading)
    return (
      <div className="h-full">
        <div className="bg-white flex h-full items-center justify-center">
          <Spin />
        </div>
      </div>
    );
  return (
    <div className="user h-full">
      <div className="bg-white p-1 h-full mx-1 my-2 rounded-sm">
        <UserHeading />
        <UserTabs user={data} />
      </div>
    </div>
  );
};
