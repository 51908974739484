import React from "react";
import Table, { ColumnsType } from "antd/lib/table";
import { useReadQuery } from "utils";
import { Loader } from "components";
import OrdersHeader from "./components/OrdersHeader";

interface ICart {
  ID: number;
  CreatedAt: string;
  cartItems: string;
  user: IUser;
}

const columns: ColumnsType<ICart> = [
  {
    title: "ID",
    key: "ID",
    dataIndex: "ID",
  },
  {
    title: "CreatedAt",
    key: "CreatedAt",
    dataIndex: "CreatedAt",
    render(_, { CreatedAt }, index) {
      return <div className="time">{new Date(CreatedAt).toUTCString()}</div>;
    },
  },
];

const Orders = () => {
  const { data, isLoading } = useReadQuery<{ data: ICart[] }>("cart", "", {
    params: {
      preload: "User",
    },
  });

  const cols = React.useMemo<ColumnsType<ICart>>(() => {
    return [
      ...columns,
      {
        title: "user",
        key: "user",
        dataIndex: "user",
        render(value, { user }, index) {
          return (
            <div className="user">
              <div className="name font-medium font-sans">{user.name}</div>
              <div className="id font-medium">ID: {user.ID}</div>
            </div>
          );
        },
      },
      {
        title: "Cart Items",
        key: "cartItems",
        dataIndex: "cartItems",
        render(value, { cartItems }, index) {
          return (
            <div className="div" key={index}>
              {JSON.parse(cartItems).map(
                (trg: { itemID: number; qty: number }, index: number) => {
                  return (
                    <div className="x" key={index}>
                      <div className="title font-bold">
                        Item ID: {trg.itemID}
                      </div>
                      <div className="title">x{trg.qty}</div>
                    </div>
                  );
                }
              )}
            </div>
          );
        },
      },
    ];
  }, []);

  return (
    <div className="myOrders bg-white p-1 m-1 rounded-sm h-full">
      <OrdersHeader />
      {isLoading && <Loader />}
      <div className="body">
        {data?.data && <Table dataSource={[...data.data]} columns={cols} />}
      </div>
    </div>
  );
};

export default Orders;
