import { Tabs } from "antd";
import { useUser } from "models";
import useCaseTab from "models/useCaseTab";
import React from "react";
import ActionsPreview from "./ActionsPreview";
import CasePreviewDetails from "./CasePreviewDetails";
import CommentsPreview from "./CommentsPreview";
import Settings from "./Settings/Settings";
import TimeLinePreview from "./TimeLinePreview";
import TreatmentPlan from "./TreatmentPlan";

type props = {};

const CasePreviewTab: React.FC<props> = (props) => {
  const user = useUser((state) => state.user);
  const tabKey = useCaseTab((state) => state.tabKey);
  const setTabKey = useCaseTab((state) => state.setTabKey);

  return (
    <div className="CasePreviewTab mt-2">
      <Tabs
        defaultActiveKey="0"
        type="card"
        activeKey={tabKey}
        onChange={(key) => {
          setTabKey(key);
        }}
      >
        <Tabs.TabPane tab="Summary" key={"0"}>
          <TimeLinePreview />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Details" key={"1"}>
          <CasePreviewDetails />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Messages" key={"2"}>
          <CommentsPreview />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Treatment Plan" key={"4"}>
          <TreatmentPlan />
        </Tabs.TabPane>
        {user && user.type !== "user" && (
          <Tabs.TabPane tab="Actions" key={"5"}>
            <ActionsPreview />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane tab="Settings" key="6">
          <Settings />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default CasePreviewTab;
