import { Button, Result } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

type props = {};

const SuccessPage: React.FC<props> = (props) => {
  const navigate = useNavigate();
  return (
    <div className="SuccessPage flex w-full h-full items-center justify-center">
      <Result
        status="success"
        title="Thank you for create case"
        subTitle="We got your request, We well get in touch soon after preview the case, Meanwhile you can check the dashboard and your cases list, And you can check messages, Alerts, for any update to your case."
        className="w-4/12"
        extra={[
          <Button
            type="primary"
            onClick={() => {
              navigate("/");
            }}
            key="console"
          >
            Dashboard
          </Button>,
          <Button
            key="cases_list"
            onClick={() => {
              navigate("/cases/case_list/new_cases");
            }}
          >
            Cases list
          </Button>,
        ]}
      />
    </div>
  );
};

export default SuccessPage;
