/* eslint-disable react-hooks/rules-of-hooks */
/* -------------------------------------------------------------------------- */
/*                                    WaitingAction                                    */
/* -------------------------------------------------------------------------- */

import {
  ClockCircleFilled,
  ClockCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, message } from "antd";
import DetailsBox from "components/Boxes/DetailsBox";
import { useUpdateEffect } from "hooks";
import { useUser } from "models";
import { useCreateMutation, useUpdateMutation } from "utils";
import notification from "utils/Notification";
import useCasePreview from "../../hooks/useCasePreview";
import { NextStatus } from "../../utils/StatusHelper";

/* ---------------------------- Component Global ---------------------------- */
interface IProps {}

/* ---------------------------- Default function ---------------------------- */
const WaitingAction = (props: IProps) => {
  // selected
  const selected = useCasePreview((state) => state.case);
  const user = useUser((state) => state.user);
  if (!selected || !user) return null;

  const sending = useCreateMutation("status");
  const update = useUpdateMutation("cases");

  const nextStatus = NextStatus(selected.statusID);

  const handleSubmitForm = (values: any) => {
    sending.mutate({
      ...values,
      userID: user.ID,
      caseID: selected.ID,
      statusID: selected.statusID,
    });
    update.mutate({
      ID: selected.ID,
      statusID: 1,
    });

    notification.send({
      message: `Case #${selected.ID} Has submit as receiving`,
      userID: user.ID,
      caseID: selected.ID,
      alertType: "success",
      notificationType: "default",
      targetType: "user",
    });
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useUpdateEffect(() => {
    if (sending.isError) {
      message.error("There are error while submit status.");
    }
    if (sending.isSuccess) {
      message.success("Submit status success");
      setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }, 2000);
    }
  }, [sending.isSuccess, sending.isError]);

  /* ----------------------------- Function return ---------------------------- */
  if (!nextStatus) return null;
  return (
    <>
      <DetailsBox title="Actions needed" icon={<EditOutlined />}>
        <div className="h-48 flex items-center justify-center  flex-col">
          <div className="flex items-center justify-center">
            {nextStatus && (
              <div className="flex gap-2 items-center text-lg text-red-500">
                <ClockCircleOutlined className="mt-0.5" />
                <div className="text-xl font-medium">
                  {nextStatus[user.type]["waiting"]}
                </div>
              </div>
            )}
          </div>
          {user.type === "manager" && selected.statusID === 0 && (
            <div className="mt-3">
              <Button type="link" onClick={() => handleSubmitForm({})}>
                Set Case as received
              </Button>
            </div>
          )}
        </div>
      </DetailsBox>
    </>
  );
};

/* ----------------------------- Default export ----------------------------- */
export default WaitingAction;
