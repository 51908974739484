// Prescriptions Data
import { Image } from "antd";
import { FormBuilderInputsProps } from "components/FormBuilder/types";
import assets from "assets";

type DefaultDataType = { label: any; value: string };

const CheckBoxImagePreview = (props: { label: string; image: any }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <Image src={props.image} className="w-20 h-8" preview={false} />
      <div className="text-center">{props.label}</div>
    </div>
  );
};

export const PrescriptionArchToTreatValues: DefaultDataType[] = [
  {
    label: <CheckBoxImagePreview label="BOTH" image={assets.arch[0]} />,
    value: "0",
  },
  {
    label: <CheckBoxImagePreview label="UPPER" image={assets.arch[1]} />,
    value: "1",
  },
  {
    label: <CheckBoxImagePreview label="LOWER" image={assets.arch[2]} />,
    value: "2",
  },
];

export const PrescriptionTreatmentTypeValues: DefaultDataType[] = [
  { label: "Full Arch", value: "0" },
  { label: "Full Anterior", value: "1" },
];

export const PrescriptionOverjetAndMidlineValues: DefaultDataType[] = [
  { label: "Maintain", value: "0" },
  { label: "Improve", value: "1" },
];

export const PrescriptionOverbiteAndPosteriorValues: DefaultDataType[] = [
  { label: "Do not correct", value: "0" },
  { label: "Correct", value: "1" },
];

export const PrescriptionSpacingValues: DefaultDataType[] = [
  { label: "Close All", value: "0" },
  { label: "Leave All", value: "1" },
];

export const formBuilderData: FormBuilderInputsProps[] = [
  {
    type: "radio",
    name: "archToTreat",
    label: "Arch To Treat",
    span: 24,
    items: PrescriptionArchToTreatValues,
  },
  {
    type: "select",
    name: "treatmentType",
    label: "Treatment Type",
    items: PrescriptionTreatmentTypeValues,
    span: 12,
  },
  {
    type: "select",
    name: "overjet",
    label: "Overjet",
    items: PrescriptionOverjetAndMidlineValues,
    span: 12,
  },
  {
    type: "select",
    name: "midline",
    label: "Midline",
    items: PrescriptionOverjetAndMidlineValues,
    span: 12,
  },
  {
    type: "select",
    name: "overbite",
    label: "Overbite",
    items: PrescriptionOverbiteAndPosteriorValues,
    span: 12,
  },
  {
    type: "select",
    name: "posterior",
    label: "Posterior Crossbite",
    items: PrescriptionOverbiteAndPosteriorValues,
    span: 12,
  },
  {
    type: "select",
    name: "spacing",
    label: "Spacing",
    items: PrescriptionSpacingValues,
    span: 12,
  },
  {
    type: "tooth",
    name: "toothMovement",
    label: "Tooth movement",
    span: 24,
  },
  {
    type: "tooth",
    name: "attachmentRestrictions",
    label: "Attachments restrictions",
    span: 24,
  },
  {
    type: "tooth",
    name: "extractions",
    label: "Extractions",
    span: 24,
  },
  {
    type: "tooth",
    name: "missingTeeth",
    label: "Missing teeth",
    span: 24,
  },
  {
    type: "textarea",
    name: "instructions",
    label: "Instructions",
    span: 24,
  },
  {
    type: "button",
    htmlType: "submit",
    name: "submit",
    label: "Create case",
    span: 24,
    className: "w-full",
    buttonType: "primary",
  },
];
