///

/// Get the status id text

export function GetWhereStatusIDText(params: CasesStatusList, type: IUTypes) {
  switch (params) {
    case "new_cases":
      return "status_id = 0";
    case "waiting_tx_plan":
      return "status_id = 1";
    case "need_approve":
      return "status_id = 2";
    case "submitted_cases":
      return "status_id = 3";
    case "sending_to_lab":
      return "status_id = 4";
    case "in_process":
      return type === "manager" ? "status_id = 5" : "status_id IN (3,4,5)";
    case "completed":
      return "status_id = 6";
    default:
      return "";
  }
}
