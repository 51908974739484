// Status helper
import { CasesStatus } from "components";

export function GetStatus(statusID: number) {
  if (CasesStatus[statusID]) {
    return CasesStatus[statusID];
  }

  return undefined;
}

export function NextStatus(statusID: number) {
  if (CasesStatus[statusID + 1]) {
    return CasesStatus[statusID];
  }

  return undefined;
}
