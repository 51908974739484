/* eslint-disable jsx-a11y/alt-text */
import {
  DownloadOutlined,
  FileOutlined,
  FileWordFilled,
  FileWordOutlined,
  FileWordTwoTone,
} from "@ant-design/icons";
import { Button, Input, message, Modal } from "antd";
import { Loader } from "components";
import React from "react";
import { http } from "utils";
import useCasePreview from "../../hooks/useCasePreview";
import { IconLabel } from "../TreatmentPlanComponents";
import "./strpping.css";
import { EditOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";
import { ENDPOINT } from "utils";

type Props = {
  onFinish: (url: string, data: string) => void;
  initalFields?: string;
  initalUrl?: string;
};

const TreatmentPlanStripping = ({
  onFinish,
  initalFields,
  initalUrl,
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const selected = useCasePreview((state) => state.case);
  const [fields, setFields] = React.useState<{ [key: string]: string }>(
    initalFields
      ? JSON.parse(initalFields)
      : {
          u1_1: "",
          ul1_2: "",
          ul2_3: "",
          ul3_4: "",
          ul4_5: "",
          ul5_6: "",
          ul6_7: "",
          ul7: "",
          ur1_2: "",
          ur2_3: "",
          ur3_4: "",
          ur4_5: "",
          ur5_6: "",
          ur6_7: "",
          ur7: "",
          l1_1: "",
          ll1_2: "",
          ll2_3: "",
          ll3_4: "",
          ll4_5: "",
          ll5_6: "",
          ll6_7: "",
          ll7: "",
          lr1_2: "",
          lr2_3: "",
          lr3_4: "",
          lr4_5: "",
          lr5_6: "",
          lr6_7: "",
          lr7: "",
        }
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [localUri, setLocalUri] = React.useState(initalUrl ?? "");
  const handleSave = async () => {
    try {
      setIsLoading(true);
      const res = await http.post<{ url: string }>("api/doc:Generate", {
        ...fields,
        patient_name: selected?.patient.name,
        doctor_name: selected?.user.name,
      });
      setIsLoading(false);
      message.success(
        "You have created and save Doc File for stripping protocol"
      );
      setLocalUri(res.data.url);
      onFinish(res.data.url, JSON.stringify(fields));
      setIsOpen(false);
    } catch (error) {
      console.log(error);
      message.error("You have error while save the doc file");
    }
  };
  return (
    <div className="TreatmentPlanStripping mb-6 mt-4">
      <Modal
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        width={827}
        onOk={handleSave}
        confirmLoading={isLoading}
        centered
        okText="Save"
      >
        {isLoading && <Loader />}
        <div className="viewOfStr" style={{ height: 1170, width: 827 }}>
          <PreviewHtml fields={fields} setFields={setFields} />
        </div>
      </Modal>
      <IconLabel
        icon={<FileWordOutlined />}
        title="Stripping protocol"
        description="Set Stripping Protocol & Save, The software well generate Doc file Auto"
      />
      <div className="flex flex-col justify-center ">
        <div
          className="boxSet h-36 w-36 border-dashed border-2 border-gray-200 bg-gray-50 flex items-center justify-center cursor-pointer border-separate flex-col"
          onClick={() => setIsOpen(true)}
        >
          {localUri === "" && (
            <>
              <div className="iconView text-lg">
                <EditOutlined />
              </div>
              <div className="text-xs text-center font-semibold">
                Set Stripping protocol
              </div>
            </>
          )}
          {localUri !== "" && (
            <>
              <div className="iconView text-lg">
                <FileWordFilled />
              </div>
              <div className="text-xs text-center font-semibold">
                Stripping File Uploaded
              </div>
            </>
          )}
        </div>
        {localUri !== "" && (
          <div
            className="Download flex items-center w-36 justify-center gap-2 cursor-pointer"
            onClick={() => {
              saveAs(
                ENDPOINT + "public/files/" + localUri + ".docx",
                localUri + ".docx"
              );
            }}
          >
            <DownloadOutlined className="text-blue-500 pt-1" />
            <div className="pt-1 font-bold">Download Word</div>
          </div>
        )}
      </div>
    </div>
  );
};

const PreviewHtml = (props: {
  fields: { [key: string]: string };
  setFields: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string;
    }>
  >;
}) => {
  const selected = useCasePreview((state) => state.case);
  if (!selected) return null;
  return (
    <div className="viewOfStr">
      <div className="pos" id="_0:0" style={{ top: 0 }}>
        <img
          src="/attachments/page_001.jpg"
          height={1170}
          width={827}
          useMap="#Map"
        />
      </div>
      <div className="pos" id="_165:131" style={{ top: 131, left: 165 }}>
        <span
          id="_20.2"
          style={{
            fontWeight: "bold",
            fontFamily: "Arial",
            fontSize: "20.2px",
            color: "#000000",
          }}
        >
          {selected.patient.name.slice(0, 20)}
        </span>
      </div>
      <div className="pos" id="_541:130" style={{ top: 130, left: 544 }}>
        <span
          id="_20.2"
          style={{
            fontWeight: "bold",
            fontFamily: "Arial",
            fontSize: "20.2px",
            color: "#000000",
          }}
        >
          {selected.user.name}
        </span>
      </div>
      <div className="pos" id="_417:256" style={{ top: 240, left: 320 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["u1_1"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, u1_1: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_504:263" style={{ top: 263, left: 515 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["ur1_2"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, ur1_2: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_300:276" style={{ top: 263, left: 130 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["ul1_2"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, ul1_2: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_203:320" style={{ top: 310, left: 80 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["ul2_3"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, ul2_3: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_566:327" style={{ top: 310, left: 566 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["ur2_3"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, ur2_3: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_170:371" style={{ top: 360, left: 55 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["ul3_4"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, ul3_4: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_600:366" style={{ top: 360, left: 600 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["ur3_4"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, ur3_4: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_154:417" style={{ top: 417, left: 30 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["ul4_5"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, ul4_5: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_623:417" style={{ top: 417, left: 623 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["ur4_5"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, ur4_5: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_129:474" style={{ top: 470, left: 15 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["ul5_6"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, ul5_6: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_641:467" style={{ top: 467, left: 641 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["ur5_6"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, ur5_6: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_100:529" style={{ top: 529, left: -8 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["ul6_7"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, ul6_7: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_664:525" style={{ top: 519, left: 659 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["ur6_7"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, ur6_7: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_108:603" style={{ top: 593, left: -20 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["ul7"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, ul7: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_679:591" style={{ top: 591, left: 669 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["ur7"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, ur7: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_80:708" style={{ top: 700, left: -20 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["ll7"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, ll7: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_702:701" style={{ top: 701, left: 670 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["lr7"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, lr7: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_82:776" style={{ top: 776, left: -10 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["ll6_7"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, ll6_7: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_683:774" style={{ top: 774, left: 659 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["lr6_7"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, lr6_7: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_108:832" style={{ top: 832, left: 15 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["ll5_6"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, ll5_6: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_668:839" style={{ top: 829, left: 638 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["lr5_6"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, lr5_6: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_113:883" style={{ top: 883, left: 30 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["ll4_5"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, ll4_5: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_649:883" style={{ top: 883, left: 619 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["lr4_5"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, lr4_5: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_143:935" style={{ top: 929, left: 55 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["ll3_4"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, ll3_4: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_618:936" style={{ top: 936, left: 595 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["lr3_4"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, lr3_4: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_173:990" style={{ top: 980, left: 79 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["ll2_3"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, ll2_3: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_584:989" style={{ top: 989, left: 564 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["lr2_3"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, lr2_3: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_267:1037" style={{ top: 1025, left: 140 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["ll1_2"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, ll1_2: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_501:1026" style={{ top: 1027, left: 501 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["lr1_2"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, lr1_2: e.target.value });
          }}
        />
      </div>
      <div className="pos" id="_393:1048" style={{ top: 1060, left: 320 }}>
        <Input
          placeholder="#"
          width={"2rem"}
          value={props.fields["l1_1"]}
          onChange={(e) => {
            props.setFields({ ...props.fields, l1_1: e.target.value });
          }}
        />
      </div>
    </div>
  );
};

export default TreatmentPlanStripping;
