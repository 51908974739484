import { MessageOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import DetailsBox from "components/Boxes/DetailsBox";
import useCaseTab from "models/useCaseTab";
import React from "react";

const TimeLinePreviewTreatmentPlanPreview = () => {
  const setTabKey = useCaseTab((state) => state.setTabKey);
  return (
    <DetailsBox title="Preview treatment plan" icon={<MessageOutlined />}>
      <div className="p-2 flex items-center justify-center">
        <div className="Box">
          <Result
            title="Treatment plan has submit"
            subTitle="We have submit the treatment plan for the case, please preview it"
            status="success"
            extra={[
              <Button onClick={() => setTabKey("4")} type="link">
                Preview treatment plan
              </Button>,
            ]}
          />
        </div>
      </div>
    </DetailsBox>
  );
};

export function Header() {
  return (
    <div className="treatmentPlanHeading p-2 border-solid border-0 border-b-2 border-b-slate-200 bg-slate-100">
      <div className="flex gap-2 items-center ">
        <div className=" rounded-sm   ">
          <MessageOutlined />
        </div>
        <div className="text-lg font-bold">Treatment Plan Has Submit</div>
      </div>
      <div className="text-medium">
        We submit treatment plan for you please preview its bellow
      </div>
    </div>
  );
}

export default TimeLinePreviewTreatmentPlanPreview;
