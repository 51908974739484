import { DownloadOutlined } from "@ant-design/icons";
import React from "react";
import { ENDPOINT } from "utils";
import { PreviewCard } from "../TreatmentPlanComponents";
import { UrlList } from "components/Uploads/ImagesUpload/ImagesUpload";
import { Image } from "antd";
import { saveAs } from "file-saver";
type Props = {
  file: UrlList;
};

const TreatmentPlanDoc = ({ file }: Props) => {
  return (
    <div className="previewTreatemtnPlan">
      <div className="previewDoc"></div>
      <PreviewCard>
        <div
          className="documentView"
          onClick={() => {
            saveAs(ENDPOINT + file.url, file.url.slice(20, file.url.length));
          }}
        >
          <div className="ImageClipArt flex items-center justify-center">
            <Image
              preview={false}
              src="/attachments/doc.png"
              width={"10rem"}
              height="10rem"
            />
          </div>
          <div className="flex items-center justify-center mt-2 gap-2">
            <DownloadOutlined />
            <div className="text-medium text-xs text-center font-bold">
              Download Document
            </div>
          </div>
        </div>
      </PreviewCard>
    </div>
  );
};

export default TreatmentPlanDoc;
