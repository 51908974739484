import { KeyOutlined } from "@ant-design/icons";
import React from "react";

export function ChangePasswordHeading() {
  return (
    <div className="flex justify-between">
      <div className="headTitle">
        <div className="flex gap-2 items-center">
          <div className="bg-blue-100 px-1 rounded-sm border-blue-200 text-blue-800 border-2 border-solid">
            <KeyOutlined />
          </div>
          <div className="font-bold text-lg">Change password</div>
        </div>
        <div className="font-semibold">
          Manage password, You can change your password from here
        </div>
      </div>
    </div>
  );
}
