import { PlusOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import type { UploadFile, UploadProps } from "antd";
import React, { useState } from "react";
import { ENDPOINT } from "utils";

type FileTypes = "images" | "videos" | "documents" | "files" | "3dFile";

export type UrlList = {
  url: string;
  type: FileTypes;
};

// Components Props
type Props = {
  fileList: UrlList[];
  setFileList: React.Dispatch<React.SetStateAction<UrlList[]>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  type: FileTypes;
};

const ImagesUpload = (props: Props) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]); // files uploaded

  const uploadObject: UploadProps<{ id: string }> = {
    action: ENDPOINT + "file",
    onChange({ file, fileList: newFileList }) {
      // Set Local files list
      setFileList(newFileList);

      // Handle Upload Status
      if (file.status === "error")
        message.error("There are error while uploading");

      if (file.status === "uploading") props.setIsLoading(true);

      if (file.status === "done") {
        props.setIsLoading(false);
        if (!file.response) return;
        props.setFileList([
          ...props.fileList,
          { url: file.response.id, type: props.type },
        ]);
      }
    },
    listType: "picture-card",
    fileList,
    multiple: true,
  };

  return (
    <div className="ImagesUpload mt-1">
      <Upload {...uploadObject}>
        {fileList.length >= 8 ? null : <UploadButton />}
      </Upload>
    </div>
  );
};

const UploadButton = () => {
  return (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
};

export default ImagesUpload;
