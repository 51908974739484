import { Avatar } from "antd";
import React from "react";
import { ENDPOINT } from "./../../../../utils/constants.utils";

type props = {
  comment: IComments;
};

const CommentsPreviewCommentBox: React.FC<props> = (props) => {
  const { comment } = props;
  const { user } = comment;
  if (!user) return null;
  return (
    <div className="CommentsPreviewCommentBox border-solid border-0 border-b-2 border-b-slate-200">
      <div className="bg-slate-50 p-1">
        <div className="flex gap-2 ">
          <Avatar src={ENDPOINT + user.avatar} className="w-12 h-12" />
          <div>
            <div className="text-lg font-semibold">{user.name}</div>
            <div className="text-xs font-semibold">
              {(() => {
                const d = new Date(comment.CreatedAt ?? "");

                return d.toUTCString();
              })()}
            </div>
          </div>
        </div>
        <div className="p-2 mt-2">
          <div className="font-bold">{comment.comment}</div>
        </div>
      </div>
    </div>
  );
};

export default CommentsPreviewCommentBox;
