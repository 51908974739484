import { DownloadOutlined } from "@ant-design/icons";
import { Image, Modal } from "antd";
import { Loader } from "components/Core";
import React from "react";
import { StlViewer } from "react-stl-viewer";
import { ENDPOINT } from "utils";
import { saveAs } from "file-saver";
const StlCard = (props: { url: string; type: string }) => {
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const { width, height } = window.screen;
  return (
    <div>
      <Modal
        open={show}
        width={width / 1.5}
        onCancel={() => setShow(false)}
        title={"Preview STL"}
        okText={"Download"}
        onOk={() => {
          // window.open(ENDPOINT + props.url, "_blank");
          saveAs(ENDPOINT + props.url, props.url.slice(20, props.url.length));
        }}
      >
        <div className="previewStlCard flex justify-center items-center">
          {loading && <Loader />}
          <StlViewer
            url={ENDPOINT + props.url}
            style={{
              width: "100vw",
              height: "60vh",
            }}
            orbitControls
            shadows
            onFinishLoading={() => setLoading(false)}
            modelProps={{}}
          />
        </div>
      </Modal>
      <div className="box border border-blue-200 border-solid bg-blue-50 rounded-sm pt-2 text-md capitalize">
        <div
          className="previewStlViewerImage m-4 mx-8 mb-2 cursor-pointer hover:scale-110 transition-all"
          onClick={() => setShow(true)}
        >
          <Image
            preview={false}
            src="/attachments/stlViewer.png"
            width={100}
            height={100}
          />
          <div className="font-bold text-center">{props.type}</div>
        </div>
        <div
          className="flex text-lg gap-2 items-center justify-center cursor-pointer hover:scale-110 transition-all"
          onClick={() => {
            saveAs(ENDPOINT + props.url, props.url.slice(20, props.url.length));
          }}
        >
          <DownloadOutlined />
          <div className="font-semibold">Download</div>
        </div>
      </div>
    </div>
  );
};

export default StlCard;
