import { Timeline } from "antd";
import React from "react";
import { useUser } from "models";
import { ClockCircleOutlined } from "@ant-design/icons";
import { CasesStatus } from "components";
import useCasePreview from "../../hooks/useCasePreview";
import { NextStatus } from "../../utils/StatusHelper";

export const CaseTimeLine = () => {
  const selected = useCasePreview((s) => s.case);
  const user = useUser((state) => state.user);
  if (!selected || !user) return null;
  const nextStatus = NextStatus(selected.statusID);
  return (
    <Timeline className="font-bold mt-4">
      <Timeline.Item
        label={(() => {
          return new Date(selected.CreatedAt ?? "").toUTCString();
        })()}
      >
        Create case
      </Timeline.Item>
      {selected.status?.map((stats) => {
        if (CasesStatus[stats.statusID + 1]) {
          return (
            <Timeline.Item
              label={(() => new Date(stats.CreatedAt ?? "").toUTCString())()}
            >
              {CasesStatus[stats.statusID + 1][user.type]["status"]}
            </Timeline.Item>
          );
        }
        return null;
      })}
      {nextStatus && (
        <Timeline.Item
          dot={<ClockCircleOutlined className="text-blue-500 bg-blue-50" />}
        >
          {nextStatus[user.type]["waiting"]}
        </Timeline.Item>
      )}
    </Timeline>
  );
};
