import { MessageOutlined } from "@ant-design/icons";
import React from "react";

export function PreviewCard(props: { children: any }) {
  return (
    <div className="w-52 h-52 cursor-pointer flex items-center justify-center bg-slate-100 border-dashed border-slate-200">
      {props.children}
    </div>
  );
}
export function Header() {
  return (
    <div className="treatmentPlanHeading p-2 border-solid border-0 border-b-2 border-b-slate-200 bg-slate-100 ">
      <div className="flex gap-2 items-center ">
        <div className="bg-blue-100 px-1 rounded-sm border-blue-200 text-blue-800 border-2 border-solid">
          <MessageOutlined />
        </div>
        <div className="text-lg font-bold">Treatment Plan</div>
      </div>
      <div className="text-medium">
        We submit treatment plan for you please preview its bellow
      </div>
    </div>
  );
}
export function TreatmentPlanApproveHeader() {
  return (
    <div className="treatmentPlanHeading p-2 border-solid border-0 border-b-2 border-b-slate-200 bg-slate-100 ">
      <div className="flex gap-2 items-center ">
        <div className="bg-blue-100 px-1 rounded-sm border-blue-200 text-blue-800 border-2 border-solid">
          <MessageOutlined />
        </div>
        <div className="text-lg font-bold">Approve Treatment Plan</div>
      </div>
      <div className="text-medium">
        Please approve treatment plan in bellow section, You can add notes,
        files.
      </div>
    </div>
  );
}
export function IconLabel({
  icon,
  title,
  description,
}: {
  icon?: any;
  title: string;
  description?: string;
}) {
  return (
    <div className="IconLabel mt-2">
      <div className="TitleCard flex items-center gap-1 text-lg">
        <div className="TitleCardIcon">{icon}</div>
        <div className="title font-semibold">{title}</div>
      </div>
      {description && <div className="font-medium">{description}</div>}
    </div>
  );
}
