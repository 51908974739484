import React from "react";
import ActionForm from "./ActionForm";
import useCasePreview from "./../hooks/useCasePreview";
import { CasesStatus } from "components";
import { useUser } from "models";
import { ForkOutlined } from "@ant-design/icons";
import ActionsList from "./ActionsList";

type props = {};

const ActionsPreview: React.FC<props> = (props) => {
  const selected = useCasePreview((state) => state.case);
  const user = useUser((state) => state.user);
  if (!selected) return null;
  if (!user) return null;
  if (!CasesStatus[selected.statusID]) return null;
  return (
    <div className="view">
      <div className="ActionsPreview bg-slate-50 border-solid border-slate-100 rounded-sm">
        <Header></Header>
        <div className="p-2">
          {CasesStatus[selected.statusID][user.type]["doAction"] && (
            <ActionForm />
          )}
        </div>
      </div>
      <ActionsList />
    </div>
  );
};

export function Header() {
  return (
    <div className="treatmentPlanHeading p-2 border-solid border-0 border-b-2 border-b-slate-200 bg-slate-100 ">
      <div className="flex gap-2 items-center ">
        <div className="bg-blue-100 px-1 rounded-sm border-blue-200 text-blue-800 border-2 border-solid">
          <ForkOutlined />
        </div>
        <div className="text-lg font-bold">Actions</div>
      </div>
      <div className="text-medium">
        You can change the status for the case from here
      </div>
    </div>
  );
}
export default ActionsPreview;
