import { Button, Checkbox, DatePicker, Form, Input, Radio, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { AvatarUpload } from "components";
import React from "react";
import { FormBuilderInputsProps } from "./types";

const InternalBox = ({
  number,
  getValue,
  setValue,
  rest,
}: {
  number: any;
  getValue: any;
  setValue: any;
  rest: any;
}) => {
  const [hasIncluded, setHasIncluded] = React.useState(
    getValue(rest.name).includes(number)
  );
  if (hasIncluded) {
    return (
      <div
        onClick={() => {
          setValue(
            rest.name,
            getValue(rest.name).filter((trg: any) => trg !== number)
          );
          setHasIncluded(false);
        }}
        className={`cursor-pointer text-xs hover:bg-slate-100 ml-1 h-10 w-10 items-center justify-center flex rounded-sm border-2 bg-blue-400 `}
      >
        {number}
      </div>
    );
  }
  return (
    <div
      onClick={() => {
        setValue(rest.name, [...getValue(rest.name), number]);
        setHasIncluded(true);
      }}
      className={`cursor-pointer text-xs hover:bg-slate-100 ml-1 w-10 h-10 items-center justify-center flex rounded-sm border-2 bg-slate-50`}
    >
      {number}
    </div>
  );
};

type props = {
  form?: FormInstance;
} & FormBuilderInputsProps;

const FormBuilderItems: React.FC<props> = ({ form, ...rest }) => {
  switch (rest.type) {
    case "input":
      return (
        <Form.Item
          name={rest.name}
          label={rest.label}
          rules={rest.rules}
          className={rest.className + " font-semibold"}
          help={rest.help}
        >
          <Input
            placeholder={rest.placeholder}
            type={rest.inputType}
            prefix={rest.prefix}
          />
        </Form.Item>
      );
    case "textarea":
      return (
        <Form.Item
          name={rest.name}
          label={rest.label}
          rules={rest.rules}
          className={rest.className + " font-semibold"}
          help={rest.help}
        >
          <Input.TextArea
            rows={5}
            placeholder={rest.placeholder}
            prefix={rest.prefix}
          />
        </Form.Item>
      );
    case "avatar":
      if (!form) throw new Error("Not having form instance");
      if (!rest.place)
        throw new Error("Please add slot for avatar upload placements");

      return (
        <div className={rest.className}>
          <Form.Item
            name={rest.name}
            rules={rest.rules}
            label={rest.label}
            className="font-semibold"
            help={rest.help}
          >
            <AvatarUpload
              title={rest.title}
              onUpload={(uri) => {
                form.setFieldValue(rest.name, uri);
              }}
              place={rest.place}
            />
          </Form.Item>
        </div>
      );
    case "select":
      if (!form) throw new Error("FORM INSTANCE ERROR: PASS FORM INSTANCE");

      return (
        <Form.Item
          name={rest.name}
          className={rest.className + " font-semibold"}
          label={rest.label}
          rules={rest.rules}
          help={rest.help}
        >
          <Select
            mode={rest.mode}
            placeholder={rest.placeholder}
            defaultValue={rest.defaultValue}
            onChange={(value) => {
              form.setFieldValue(rest.name, value);
            }}
          >
            {rest.items.map((item) => (
              <Select.Option value={item.value} key={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );
    case "datepicker":
      if (!form) throw new Error("FORM INSTANCE ERROR: PASS FORM INSTANCE");
      return (
        <Form.Item
          name={rest.name}
          className={rest.className + " font-semibold"}
          label={rest.label}
          help={rest.help}
        >
          <DatePicker className={"w-full"} placeholder={rest.placeholder} />
        </Form.Item>
      );
    case "checkboxes":
      return (
        <Form.Item
          name={rest.name}
          className={rest.className + " font-semibold"}
          label={rest.label}
          help={rest.help}
        >
          <Checkbox.Group options={rest.items} />
        </Form.Item>
      );
    case "clearing":
      return <div className="w-full"></div>;
    case "radio":
      return (
        <Form.Item
          name={rest.name}
          className={`${rest.className} font-semibold`}
          label={rest.label}
          help={rest.help}
          initialValue={rest.initialValue}
        >
          <Radio.Group>
            {rest.items.map((trg, index) => (
              <Radio value={trg.value} key={index}>
                {trg.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      );
    case "tooth":
      if (!form) throw new Error("Error in form instance");
      const getValue = form.getFieldValue;
      const setValue = form.setFieldValue;
      if (typeof getValue(rest.name) !== "object") {
        setValue(rest.name, []);
      }

      return (
        <Form.Item
          name={rest.name}
          label={rest.label}
          className={`${rest.className} font-semibold`}
          help={rest.help}
        >
          <div className="flex flex-wrap item-center">
            <div className="flex item-center flex-wrap border-solid border-slate-100 border-t-0 border-l-0 p-1">
              {["21", "22", "23", "24", "25", "26", "27", "28"]
                .reverse()
                .map((trg) => (
                  <InternalBox
                    key={trg}
                    number={trg}
                    setValue={setValue}
                    getValue={getValue}
                    rest={rest}
                  />
                ))}
            </div>
            <div className="flex flex-wrap items-center border-solid border-slate-100 border-r-0 border-t-0 p-1">
              {["11", "12", "13", "14", "15", "16", "17", "18"].map((trg) => (
                <InternalBox
                  number={trg}
                  key={trg}
                  setValue={setValue}
                  getValue={getValue}
                  rest={rest}
                />
              ))}
            </div>
          </div>
          <div className="flex flex-wrap item-center ">
            <div className="flex flex-wrap item-center border-solid border-slate-100 border-l-0 border-b-0 p-1">
              {["41", "42", "43", "44", "45", "46", "47", "48"]
                .reverse()
                .map((trg) => (
                  <InternalBox
                    number={trg}
                    key={trg}
                    rest={rest}
                    setValue={setValue}
                    getValue={getValue}
                  />
                ))}
            </div>
            <div className="flex items-center flex-wrap border-solid border-slate-100 border-r-0 border-b-0 p-1">
              {["31", "32", "33", "34", "35", "36", "37", "38"].map((trg) => (
                <InternalBox
                  number={trg}
                  key={trg}
                  rest={rest}
                  setValue={setValue}
                  getValue={getValue}
                />
              ))}
            </div>
          </div>
        </Form.Item>
      );
    case "button":
      return (
        <Button
          className={rest.className}
          htmlType={rest.htmlType}
          onClick={rest.onClick}
          type={rest.buttonType}
        >
          {rest.label}
        </Button>
      );
    default:
      return null;
  }
};

export default FormBuilderItems;
