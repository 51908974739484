import create from "zustand";

type props = {
  activeIndex: number;
  setActiveIndex(index: number): void;
  badges: {
    [key: string]: number;
  };
  setBadges(badges: { [key: string]: number }): void;
  show: boolean;
  setShow: (props: boolean) => void;
};

const useSidePanel = create<props>((set) => ({
  activeIndex: 1,
  setActiveIndex(index) {
    set(() => ({ activeIndex: index, show: false }));
  },
  badges: {},
  setBadges(badges) {
    set(() => ({ badges }));
  },
  show: false,
  setShow(props) {
    set(() => ({
      show: props,
    }));
  },
}));

export default useSidePanel;
