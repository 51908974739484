import { Progress, Space } from "antd";
import React from "react";
import { useCase } from "models";
import useCasePreview from "./../../hooks/useCasePreview";

export const ProgressCasePreview = () => {
  const selected = useCasePreview((state) => state.case);

  if (!selected) return null;
  return (
    <div className="flex justify-center items-center">
      <Space>
        <Progress type="dashboard" percent={selected.statusID * 20} />
      </Space>
    </div>
  );
};
