import { message, Result, Spin } from "antd";
import { CasesStatus, CaseTable } from "components";
import React from "react";
import { http } from "utils";
import { useUser } from "models";
import { useParams } from "react-router-dom";
import { GetWhereStatusIDText } from "./utils/GetWhereStatusIDText";
import Heading from "./components/Heading";

type props = {};

const CaseList: React.FC<props> = (props) => {
  // Types the list -> get it from params, Navigate -> to navigate system

  // is loading to set the loading systems
  const [isLoading, setIsLoading] = React.useState(true);

  // data for the data
  const [data, setData] = React.useState<ICase[]>([]);

  const { type } = useParams<{ type: CasesStatusList }>();
  // user system to get the user
  const user = useUser((state) => state.user);
  if (!user) return null;
  if (!type) return null;
  async function fetchResult() {
    if (!type) return;
    if (!user) return;

    setIsLoading(true);

    try {
      // Get the result
      const result = await http.get<{ data: ICase[] }>("api/cases", {
        params: {
          where: `${GetWhereStatusIDText(type, user.type)} ${
            user.type === "user" ? `AND user_id = ${user.ID}` : ""
          }`,
          preload: "User,Patient",
          order: "ID desc",
        },
      });
      // set the result data
      setData(result.data.data);
      setIsLoading(false);
    } catch (error) {
      message.error("Network error please try again later");
      console.log(error);
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    // fetch result in CHANGE TYPE
    fetchResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  // if is loading return the loading
  if (isLoading) {
    return (
      <div className="flex item-center justify-center w-full h-full bg-white p-2 mx-2 my-2 mb-4 rounded-md">
        <div className="flex flex-1 items-center justify-center flex-col">
          <Spin size="large" />
          <div className="text-lg font-semibold">Loading</div>
        </div>
      </div>
    );
  }

  // if not have any data or data is empty
  if (!data || data.length === 0) {
    return (
      <div className="flex item-center justify-center w-full h-full bg-white p-2 mx-2 my-2 mb-4 rounded-md">
        <div className="flex flex-1 flex-col items-center justify-center">
          <div className="text-lg font-semibold">
            <Result
              status={"500"}
              title="Empty result"
              subTitle="There are no any cases here."
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="caseList">
      <Heading />
      <div className="CaseList bg-white p-2 mx-1 my-1 mb-4 rounded-sm">
        <CaseTable
          data={data.map((trg) => ({
            ID: trg.ID ? trg.ID : 0,
            name: trg.patient.name,
            age: trg.patient.age,
            date: trg.CreatedAt ? trg.CreatedAt : "",
            status: CasesStatus[trg.statusID]
              ? CasesStatus[trg.statusID][user.type]["status"]
              : "",
            avatar: trg.patient.avatar,
            waiting: CasesStatus[trg.statusID]
              ? CasesStatus[trg.statusID][user.type]["waiting"]
              : "",
            userName: trg.user.name,
            case_type: trg.caseType,
          }))}
        />
      </div>
    </div>
  );
};

export default CaseList;
