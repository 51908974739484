import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import React from "react";

function StrippingProtocolPage() {
  const fileUrl = "";
  return (
    <div>
      <DocViewer
        documents={[
          {
            uri:
              "https://www.clearaligner-kh.tk/api/public/files/" +
              fileUrl +
              ".docx",
            fileType:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            fileName: "Stripping Protocol Document",
          },
        ]}
        style={{ height: 1250 }}
        pluginRenderers={DocViewerRenderers}
      />
    </div>
  );
}

export default StrippingProtocolPage;
