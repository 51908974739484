import React from "react";
import { Image } from "antd";
import Collapse from "@kunukn/react-collapse";
import "./TXInstruction.css";
import { DownloadOutlined } from "@ant-design/icons";

const SectionPreviewBase2 = ({
  children,
  title,
  description,
  img
}: {
  children: any;
  title: string;
  description?: string;
  img: string;
}) => {
  const [isOpen, setIsOpen] = React.useState(true);

  return (
    <div className="sectionPreview">
      <div className="py-1 px-2 bg-red-600/[.8] rounded-sm  border-solid border-0 border-b-2 border-b-green-500 flex justify-between items-center">
        <div className="flex gap-4  items-center ">
          <div
            onClick={() => {
              setIsOpen((val) => !val);
            }}
            className="minus h-4 w-4 mr-1 flex items-center cursor-pointer justify-center bg-red-50 font-bold rounded-sm"
          >
            {isOpen ? "-" : "+"}
          </div>
          <Image src={img} width="1.5rem" height="1.5rem" preview={false} />
          <div className="fl">
            <div className="text-2xl text-white ">{title}</div>
            {description && (
              <div className="text-xs text-white">{description}</div>
            )}
          </div>
        </div>
      </div>
      <Collapse isOpen={isOpen} className="collapse-css-transition">
        <div className="py-4 px-4 w-full">{children}</div>
      </Collapse>
    </div>
  );
};

export default SectionPreviewBase2;
