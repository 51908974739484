import { Tag } from "antd";
import React, { Fragment } from "react";
import CardItem from "./CardItem";
import useCasePreview from "./../hooks/useCasePreview";
import {
  PrescriptionArchToTreatValues,
  PrescriptionOverbiteAndPosteriorValues,
  PrescriptionOverjetAndMidlineValues,
  PrescriptionSpacingValues,
  PrescriptionTreatmentTypeValues,
} from "./../../../../components/Cases/Forms/Prescription/Data";
import { BookOutlined } from "@ant-design/icons";

type props = {};

const CasePreviewPrescription: React.FC<props> = (props) => {
  const selected = useCasePreview((state) => state.case);
  if (!selected) return null;
  const { prescription } = selected;
  return (
    <div className="w-full border-2 border-slate-100 bg-slate-50 rounded-sm mt-2 border-solid">
      <div className="flex gap-2 items-center border-0 border-solid pb-2 border-b-2 border-b-slate-200 p-2 bg-slate-100">
        <div className="bg-blue-100 px-1 rounded-sm border-blue-200 text-blue-800 border-2 border-solid">
          <BookOutlined />
        </div>
        <div className="text-lg font-bold">Prescription</div>
      </div>
      <div className="flex flex-wrap items-center gap-8 p-2">
        <CardItem
          title="Arch to treat"
          value={
            PrescriptionArchToTreatValues.find(
              (val) => val.value === prescription.archToTreat
            )?.label
          }
        />
        <CardItem
          title="Treatment Type"
          value={
            PrescriptionTreatmentTypeValues.find(
              (val) => val.value === prescription.treatmentType
            )?.label
          }
        />
        <CardItem
          title="Overjet"
          value={
            PrescriptionOverjetAndMidlineValues.find(
              (val) => val.value === prescription.overjet
            )?.label
          }
        />
        <CardItem
          title="Midline"
          value={
            PrescriptionOverjetAndMidlineValues.find(
              (val) => val.value === prescription.midline
            )?.label
          }
        />
        <CardItem
          title="Overbite"
          value={
            PrescriptionOverbiteAndPosteriorValues.find(
              (val) => val.value === prescription.overbite
            )?.label
          }
        />
        <CardItem
          title="Posterior"
          value={
            PrescriptionOverbiteAndPosteriorValues.find(
              (val) => val.value === prescription.posterior
            )?.label
          }
        />
        <CardItem
          title="Spacing"
          value={
            PrescriptionSpacingValues.find(
              (val) => val.value === prescription.spacing
            )?.label
          }
        />
      </div>

      <div className="flex flex-col mt-6 gap-4">
        {prescription.toothMovement &&
          prescription.toothMovement.length !== 0 && (
            <div className="cardItem">
              <div className="font-semibold">Tooth Movement</div>
              <div className="flex ">
                {prescription.toothMovement && (
                  <Fragment>
                    {prescription.toothMovement.map((trg) => (
                      <Tag key={trg} color="blue" className="text-lg">
                        {trg}
                      </Tag>
                    ))}
                  </Fragment>
                )}
              </div>
            </div>
          )}
        {prescription.attachmentRestrictions &&
          prescription.attachmentRestrictions.length !== 0 && (
            <div className="cardItem">
              <div className="font-semibold">Attachments restrictions</div>
              <div className="flex ">
                {prescription.attachmentRestrictions && (
                  <Fragment>
                    {prescription.attachmentRestrictions.map((trg) => (
                      <Tag key={trg} color="blue" className="text-lg">
                        {trg}
                      </Tag>
                    ))}
                  </Fragment>
                )}
              </div>
            </div>
          )}
        {prescription.extractions && prescription.extractions.length !== 0 && (
          <div className="cardItem">
            <div className="font-semibold">Extractions </div>
            <div className="flex ">
              {prescription.extractions && (
                <Fragment>
                  {prescription.extractions.map((trg) => (
                    <Tag key={trg} color="blue" className="text-lg">
                      {trg}
                    </Tag>
                  ))}
                </Fragment>
              )}
            </div>
          </div>
        )}
        {prescription.missingTeeth &&
          prescription.missingTeeth.length !== 0 && (
            <div className="cardItem">
              <div className="font-semibold">Missing teeth </div>
              <div className="flex ">
                {prescription.missingTeeth && (
                  <Fragment>
                    {prescription.missingTeeth.map((trg) => (
                      <Tag key={trg} className="text-lg" color="blue">
                        {trg}
                      </Tag>
                    ))}
                  </Fragment>
                )}
              </div>
            </div>
          )}
      </div>
      {prescription.instructions !== "" && (
        <CardItem title="Instructions" value={prescription.instructions} />
      )}
    </div>
  );
};

export default CasePreviewPrescription;
