import {
  AlertOutlined,
  BookOutlined,
  CalendarOutlined,
  DownloadOutlined,
  EditFilled,
  FileOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import { CasesStatus } from "components";
import { useUser } from "models";
import React from "react";
import { ENDPOINT } from "utils";
import ActionExtraPreview from "../ActionExtraPreview";
import useCasePreview from "../hooks/useCasePreview";
import ActionEdit from "./ActionEdit";

type props = {};
export type ExtraProps = {
  upper: number;
  lower: number;
  steps: number;
  stepInstructions: { duran: boolean; caPro: boolean }[];
  stepDetails: { soft: boolean; medium: boolean; hard: boolean }[];
  attachmentTemplate: string;
};

const ActionsList: React.FC<props> = (props) => {
  const selected = useCasePreview((state) => state.case);
  const user = useUser((state) => state.user);
  const [isEditMode, setIsMode] = React.useState<number | null>(null);
  const previewExtra = (extra: string | null) => {
    if (extra == null) return null;
    if (extra === "") return null;
    const data: ExtraProps = JSON.parse(extra);
    return data;
  };
  if (!selected) return null;
  if (!user) return null;
  if (selected.status.length === 0) return null;

  return (
    <div className="ActionsList mt-4">
      {selected.status.map((trg, index) => (
        <div
          className=" border-solid border-green-100 mt-2 mb-2 pb-4 rounded-sm"
          key={index}
        >
          <div className="flex justify-between border-solid border-0 border-b-2 border-b-green-200 bg-green-100 py-1">
            <div className="flex gap-2 items-center ">
              <AlertOutlined />
              <div className="text-md font-medium">
                <div className="font-medium">
                  Change status from{" "}
                  <span className="font-bold">
                    {CasesStatus[trg.statusID][user.type]["status"]}
                  </span>{" "}
                  to{" "}
                  <span className="font-bold">
                    {" "}
                    {CasesStatus[trg.statusID][user.type]["waiting"]}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="editButton">
                <Button
                  icon={<EditFilled />}
                  onClick={() => setIsMode(trg.ID ?? null)}
                  type="link"
                >
                  Edit
                </Button>
              </div>
            </div>
          </div>

          {/*  */}
          {isEditMode === trg.ID && <ActionEdit status={trg} />}
          {!isEditMode && (
            <div className="body px-2">
              <div className="flex gap-2 items-center mt-2 text-lg ">
                <BookOutlined />
                <div className="font-semibold">Note</div>
              </div>
              <div className="headingText">{trg.note}</div>
              <div className="CreatedAt">
                <div className="flex gap-2 items-center mt-2 text-lg ">
                  <CalendarOutlined />
                  <div className="font-semibold">Date</div>
                </div>
                <div className="date">
                  {new Date(trg.CreatedAt).toUTCString()}
                </div>
              </div>
              {previewExtra(trg.extra) && (
                <div className="extra">
                  <div className="flex gap-2 items-center mt-2 text-lg ">
                    <BookOutlined />
                    <div className="font-semibold">Instructions</div>
                  </div>
                  <ActionExtraPreview extra={previewExtra(trg.extra)} />
                </div>
              )}
              {trg.files && trg.files.length !== 0 && (
                <div className="files">
                  <div className="flex gap-2 items-center mt-2 text-lg">
                    <FileOutlined />
                    <div className=" font-semibold">Files</div>
                  </div>
                  <div className="files mt-2">
                    {trg.files.map((attach, index) => (
                      <div key={index}>
                        <a
                          href={ENDPOINT + attach}
                          download
                          target={"_blank"}
                          className="border-2 bg-blue-100 rounded-sm px-4 py-1.5 text-md gap-2 capitalize cursor-pointer"
                          rel="noreferrer"
                        >
                          <DownloadOutlined /> File {index + 1}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {/*  */}
        </div>
      ))}
    </div>
  );
};

export default ActionsList;
