import React from "react";
import useUser from "./../../../../models/useUser";
import { useReadQuery } from "./../../../../utils/Api.utils";
import { useUpdateEffect } from "./../../../../hooks/useUpdateEffect";
import { message, Radio, Result } from "antd";

type Props = {
  addressID: number | null;
  setAddressID: React.Dispatch<React.SetStateAction<number | null>>;
};

const TreatmentPlanSelectAddress = ({ addressID, setAddressID }: Props) => {
  const user = useUser((state) => state.user);
  const { isError, data } = useReadQuery<{ data: IAddress[] }>(
    "addresses",
    "",
    {
      params: {
        where: "user_id = " + user?.ID,
      },
    }
  );

  useUpdateEffect(() => {
    if (isError) {
      message.error("Get addresses have error.");
    }
  }, [isError]);
  if (!data) return <></>;
  return (
    <div className="TreatmentPlanSelectAddress">
      {data.data.length === 0 && (
        <div className="addressIsEmpty">
          <Result
            title="Empty address"
            subTitle="You don't have add any address please add a one"
            status={"warning"}
          ></Result>
        </div>
      )}

      <Radio.Group
        value={addressID}
        onChange={(value) => {
          setAddressID(value.target.value);
        }}
      >
        {data.data.map((trg) => (
          <Radio value={trg.ID} key={trg.ID} className="mr-4">
            <div className="address_box">
              <div className="city">{trg.city}</div>
              <div className="address">{trg.address}</div>
              <div className="zip">{trg.zip}</div>
            </div>
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );
};

export default TreatmentPlanSelectAddress;
