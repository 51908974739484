import { EyeOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import React from "react";
import { Link } from "react-router-dom";

const cols: ColumnsType<IUser> = [
  {
    title: "#",
    dataIndex: "ID",
    key: "ID",
    sorter: (a, b) => a.ID - b.ID,
    sortDirections: ["descend"],
  },
  {
    title: "User name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Phone number",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Email address",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "User Type",
    dataIndex: "type",
    key: "type",
    render(value, { type }) {
      return <Tag className="font-semibold capitalize">{type}</Tag>;
    },
  },
  {
    title: "Active status",
    dataIndex: "isActive",
    key: "isActive",
    render(value, { isActive }) {
      if (isActive === 2) return <Tag color="green">Active</Tag>;
      return <Tag color="red">Not active</Tag>;
    },
  },
  {
    title: "Actions",
    dataIndex: "actions",
    render(value, { ID }) {
      return (
        <Link
          to={`/user/${ID}`}
          className="flex gap-2 items-center text-blue-500 font-bold"
        >
          <EyeOutlined />
          <div className="font-bold">Preview</div>
        </Link>
      );
    },
  },
];

export function UserListTable(props: { data: IUser[] }) {
  return (
    <div className="usersTable">
      <Table
        className="font-semibold"
        columns={cols}
        dataSource={props.data}
        size="small"
      />
    </div>
  );
}
