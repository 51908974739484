/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, InputNumber, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { useUpdateEffect } from "hooks";
import React from "react";
import { http, useCreateMutation } from "utils";
import NewItemHeader from "./components/NewItemHeader";
import OptionsSelect from "./components/OptionsSelect";
import ImagesUpload, {
  UrlList,
} from "components/Uploads/ImagesUpload/ImagesUpload";
import { useParams } from "react-router-dom";

const initalState: IItem = {
  title: "",
  description: "",
  fear: "",
  options: "",
  image: "",
};

const NewItem = () => {
  // Form
  const [form] = useForm();
  const [itemOptions, setItemOptions] = React.useState<IItemOptions[]>([]);
  const [fileList, setFileList] = React.useState<UrlList[]>([]);
  const [isLoadingImage, setIsLoadingImage] = React.useState(false);
  // Mutation
  const { mutate, isError, isLoading, isSuccess } = useCreateMutation("items");

  // handle submit
  const handleSubmitItem = (values: any) => {
    mutate({
      ...values,
      options: JSON.stringify(itemOptions),
      image: JSON.stringify(fileList),
    });
  };

  // check error
  useUpdateEffect(() => {
    if (isError) {
      message.error("There are error please try again later.");
    }
    if (isSuccess) {
      message.success("Add item success");
      form.resetFields();
      setItemOptions([]);
      setFileList([]);
    }
  }, [isError, isSuccess]);

  // Edit section
  const params = useParams<{ id: string }>();
  const [isUpdate, setIsUpdate] = React.useState(false);
  const getQuery = async () => {
    if (!params.id) return;
    try {
      const result = await http.get<IItem>("api/items/" + params.id);
      form.setFieldValue("title", result.data.title);
      form.setFieldValue("description", result.data.description);
      form.setFieldValue("fear", result.data.fear);
      setItemOptions(JSON.parse(result.data.options));
      setFileList(JSON.parse(result.data.image));
      setIsUpdate(true);
    } catch (error) {
      console.log(error);
    }
  };

  const update = async (values: any) => {
    try {
      setIsLoadingImage(true);
      await http.put("api/items", {
        ...values,
        options: JSON.stringify(itemOptions),
        image: JSON.stringify(fileList),
      });
      setIsLoadingImage(false);
      message.success("Update success");
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (params.id) {
      getQuery();
    }
  }, []);

  return (
    <div className="newItem bg-white p-1 m-1 rounded-sm h-full">
      <NewItemHeader />
      <Form
        initialValues={initalState}
        form={form}
        name="item"
        layout="vertical"
        onFinish={isUpdate ? update : handleSubmitItem}
      >
        <Form.Item name="title" label="Title" rules={[{ required: true }]}>
          <Input placeholder="Title" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true }]}
        >
          <TextArea placeholder="Description" />
        </Form.Item>
        <Form.Item name="fear" label="Price">
          <InputNumber placeholder="input" />
        </Form.Item>
        <Form.Item name="image" label="Upload Images">
          <ImagesUpload
            isLoading={isLoadingImage}
            setIsLoading={setIsLoadingImage}
            fileList={fileList}
            setFileList={setFileList}
            type="images"
          />
        </Form.Item>
        <OptionsSelect options={itemOptions} setOptions={setItemOptions} />
        <Button
          loading={isLoading}
          disabled={isLoading}
          htmlType="submit"
          className="mt-2"
          block
          type="primary"
        >
          Save Item
        </Button>
      </Form>
    </div>
  );
};

export default NewItem;
