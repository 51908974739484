/* eslint-disable no-useless-escape */
import { Alert, Button, Checkbox, Form, Input, message } from "antd";
import { AvatarUpload } from "components";
import { useUpdateEffect } from "hooks";
import { useUser } from "models";
import React, { useState } from "react";
import { useRegister } from "utils";

import {
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import notification from "utils/Notification";

type props = {};

const Register: React.FC<props> = (props) => {
  const [avatar, setAvatar] = useState("");
  const { isError, data, mutate, isLoading } = useRegister();

  const [isDone, setIsDone] = React.useState(false);

  const onFinish = (values: IURegister) => {
    mutate({ ...values, avatar, type: "user", isActive: false });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const setUser = useUser((state) => state.setUser);
  useUpdateEffect(() => {
    if (data) {
      message.success(
        "Create account success, Waiting for approve account from manager"
      );
      notification.send({
        userID: data.user.ID,
        caseID: 0,
        message: `New User Register In Software with Name ${data.user.name}`,
        alertType: "info",
        notificationType: "default",
        targetType: "manager",
      });
      setIsDone(true);
      return;
      // setUser(data.token, data.user);
    }
  }, [data]);

  return (
    <div className="Register">
      <Form
        name="basic"
        initialValues={{ remember: true }}
        style={{ marginTop: 12 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="uploadField">
          <AvatarUpload
            place="users"
            onUpload={(uri) => {
              setAvatar(uri);
            }}
          />
        </div>
        <Form.Item
          name="Name"
          rules={[{ message: "Please input your full name !", required: true }]}
        >
          <Input placeholder="Full name" prefix={<UserOutlined />} />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
            {
              pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: "Please enter valid address",
            },
          ]}
        >
          <Input
            placeholder="Email"
            prefix={<MailOutlined color="success.600" />}
          />
        </Form.Item>
        <Form.Item
          name="phone"
          rules={[
            {
              message: "Please enter your phone number",
              required: true,
            },
          ]}
        >
          <Input placeholder="Phone" prefix={<PhoneOutlined />} />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password placeholder="Password" prefix={<LockOutlined />} />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>Agree our terms & conditions</Checkbox>
        </Form.Item>
        {isError && (
          <Alert
            style={{ marginBottom: 10 }}
            type="error"
            showIcon
            message="Error"
            description="There are some errors when try register, please try again."
          ></Alert>
        )}
        {isDone && (
          <Alert
            style={{ marginBottom: 10 }}
            type="success"
            showIcon
            message="Create account success"
            description="You have create new account, You can login after manager Active your profile"
          ></Alert>
        )}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={isLoading}
            loading={isLoading}
          >
            Register
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Register;
