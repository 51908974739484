import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce, useUpdateEffect } from "hooks";
import { useShowQuery } from "utils";
import { Loader } from "components";
import { Result, Button } from "antd";
import useCasePreview from "./hooks/useCasePreview";
import CasePreviewTab from "./components/CasePreviewTab";
import CasePreviewHeading from "./components/CasePreviewHeading";
import useCaseTab from "models/useCaseTab";

type props = {};
const config =
  "?preload=Patient,ClinicalCondition,User,Attachment,Prescription,TreatmentPlan,Status,TreatmentPlanApprove,StrippingProtocol";

const CasePreview: React.FC<props> = (props) => {
  const params = useParams<{ id: string }>();
  const setCase = useCasePreview((state) => state.setCase);
  const { isLoading, isError, data } = useShowQuery<ICase>(
    "cases",
    params.id + config
  );

  useUpdateEffect(() => {
    if (data) {
      setCase(data);
    }
  }, [data]);

  const setTabKey = useCaseTab((state) => state.setTabKey);
  useEffectOnce(() => {
    setTabKey("0");
  });

  if (isLoading) {
    return (
      <div className="w-full h-full relative">
        <Loader />
      </div>
    );
  }

  if (isError) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={<Button type="primary">Back Cases</Button>}
      />
    );
  }
  if (data) {
    return (
      <div className="CasePreview bg-white p-2 mx-2 my-2 rounded-md">
        <CasePreviewHeading />
        <CasePreviewTab />
      </div>
    );
  }

  return null;
};

export default CasePreview;
