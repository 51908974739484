import React from "react";
import { Avatar, Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { ENDPOINT } from "utils";
import { EyeFilled } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import assets from "assets";

type props = {
  data: DataType[];
};
interface DataType {
  ID: number;
  avatar: string;
  name: string;
  age: string;
  date: string;
  status: string;
  case_type: string;
  waiting: string;
}

const CaseTable: React.FC<props> = (props) => {
  const navigate = useNavigate();
  const cols: ColumnsType<DataType> = [
    {
      title: "#",
      dataIndex: "ID",
      key: "ID",
      render(_, { ID }) {
        return (
          <div className="font-semibold">{ID.toString().padStart(5, "0")}</div>
        );
      },
      sorter: (a, b) => a.ID - b.ID,
      sortDirections: ["descend"],
      className: "cursor-pointer",
      width: 75,
    },
    {
      title: "Patient",
      dataIndex: "name",
      key: "name",
      render(_, { avatar, name }) {
        return (
          <div className="flex items-center">
            <Avatar
              shape="square"
              src={!avatar ? assets.avatar : ENDPOINT + avatar}
            />
            <div className="font-semibold pl-2">{name}</div>
          </div>
        );
      },
      responsive: ["lg"],
    },
    {
      title: "Created Date",
      dataIndex: "date",
      key: "date",
      render(_, { date }) {
        return (
          <div className="font-semibold">
            {(() => {
              const _date = new Date(date);
              return _date.toUTCString();
            })()}
          </div>
        );
      },
      responsive: ["lg", "md"],
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render(_, { status, ID }) {
        return (
          <Tag
            onClick={() => navigate("/cases/case_preview/" + ID)}
            className="c cursor-pointer"
            color="green"
          >
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Case Type",
      key: "case_type",
      dataIndex: "case_type",
    },
    // {
    //   title: "Waiting",
    //   key: "waiting",
    //   dataIndex: "waiting",
    //   render(_, { ID, waiting }) {
    //     return (
    //       <Tag
    //         onClick={() => navigate("/cases/case_preview/" + ID)}
    //         className="c cursor-pointer"
    //         color="red"
    //       >
    //         {waiting}
    //       </Tag>
    //     );
    //   },
    //   responsive: ["lg"],
    // },
    {
      title: "User",
      key: "userName",
      dataIndex: "userName",
    },
    {
      title: "Action",
      key: "action",
      render: (_, { ID }) => (
        <Space size="middle">
          <Link
            className="flex items-center gap-2"
            to={`/cases/case_preview/${ID}`}
          >
            <EyeFilled />
            <div className="font-semibold">Preview</div>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <Table
      className="font-semibold"
      tableLayout="fixed"
      columns={cols}
      dataSource={props.data}
      size="small"
    />
  );
};

export default CaseTable;
