/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  CalendarOutlined,
  ClockCircleOutlined,
  DownOutlined,
  FileDoneOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Avatar, Dropdown, Menu, message, Modal, Space } from "antd";
import React from "react";
import { ENDPOINT, useDeleteMutation } from "utils";
import useCasePreview from "./../hooks/useCasePreview";
import { useUser } from "models";
import { CasesStatus } from "components";
import assets from "assets";
import { useUpdateMutation } from "utils";

type props = {};

const CasePreviewHeading: React.FC<props> = () => {
  const [isOpenMoveToArchive, setIsOpenMoveToArchive] = React.useState(false);
  const [isOpenRemove, setIsOpenRemove] = React.useState(false);
  const selected = useCasePreview((state) => state.case);

  const moveToArchiveMutation = useUpdateMutation("cases");
  const removeMutation = useDeleteMutation("cases", selected?.ID ?? 0);
  const handleMoveToArchive = async () => {
    // move to archive
    setIsOpenMoveToArchive(false);
    await moveToArchiveMutation.mutateAsync({
      ID: selected?.ID,
      StatusID: 100,
    });
    message.success("Move to archive success");
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const handleRemove = async () => {
    // move to archive
    setIsOpenRemove(false);
    await removeMutation.mutateAsync();
    message.success("Remove case success");
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };
  const user = useUser((state) => state.user);
  if (!user) return null;
  if (!selected) return null;
  return (
    <div className="CasePreviewHeading">
      <Modal
        okText="Yes sure"
        onOk={() => {
          handleMoveToArchive();
        }}
        title="Move case to archive"
        open={isOpenMoveToArchive}
        onCancel={() => setIsOpenMoveToArchive(false)}
      >
        <div className="text-lg">Do you want move this case to archive ?</div>
      </Modal>
      <Modal
        okText="Yes sure"
        onOk={() => {
          handleRemove();
        }}
        title="Remove Case"
        open={isOpenRemove}
        onCancel={() => setIsOpenRemove(false)}
      >
        <div className="text-lg">
          You well lose the data for ever are you sure ?
        </div>
      </Modal>
      <div className="w-full p-2 border-b-2 relative">
        <div className="flex flex-wrap gap-6 justify-between">
          <div id="leftSection" className="flex gap-6">
            <Avatar
              src={
                selected.patient.avatar === "" || !selected.patient.avatar
                  ? assets.avatar
                  : ENDPOINT + selected.patient.avatar
              }
              className="w-24 h-24"
              shape="square"
            />
            <div>
              <div className="text-4xl font-bold">{selected.patient.name}</div>
              <div className="mt-1 font-bold">
                Case #{selected.ID?.toString().padStart(5, "0")}
                {" / "}
                {`User: #${selected.user.name} `}
              </div>
              <div className="flex flex-wrap items-center mt-2 gap-4 font-semibold">
                <div className="box">
                  <div className="flex items-center gap-2">
                    <div className="bg-blue-200 px-1 rounded-sm border-blue-300 text-blue-800 border-2 border-solid">
                      <CalendarOutlined />
                    </div>
                    <div>
                      <div className="label font-thin text-sm">Patient age</div>
                      <div className="value">{selected.patient.age}</div>
                    </div>
                  </div>
                </div>
                <div>/</div>
                <div className="flex items-center gap-2">
                  <div className="bg-blue-200 px-1 rounded-sm border-blue-300 text-blue-800 border-2 border-solid">
                    <PhoneOutlined />
                  </div>
                  <div>
                    <div className="label font-thin text-sm">Patient phone</div>
                    <div className="value">{selected.patient.phone}</div>
                  </div>
                </div>
                <div>/</div>
                <div className="flex items-center gap-2">
                  <div className="bg-blue-200 px-1 rounded-sm border-blue-300 text-blue-800 border-2 border-solid">
                    <MailOutlined />
                  </div>
                  <div>
                    <div className="label font-thin text-sm">Patient email</div>
                    <div className="value">{selected.patient.email}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {CasesStatus[selected.statusID] && (
            <div className="rightSection flex flex-wrap flex-col justify-center items-end">
              <div className="text-2xl font-bold flex items-center gap-2">
                <FileDoneOutlined className="text-green-500" />
                {CasesStatus[selected.statusID][user.type]["status"]}
              </div>
              {selected.statusID !== 5 && (
                <div className="text-red-500 items-center font-bold flex gap-2">
                  <ClockCircleOutlined />
                  {CasesStatus[selected.statusID][user.type]["waiting"]}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="a absolute right-0 top-0">
          <div className="viewMore">
            <Dropdown
              overlay={
                <Menu
                  items={[
                    {
                      key: "1",
                      label: "Move To Archive",
                      onClick: () => {
                        setIsOpenMoveToArchive(true);
                      },
                    },
                    {
                      key: "2",
                      danger: true,
                      label: "Remove Case",
                      onClick: () => {
                        setIsOpenRemove(true);
                      },
                    },
                  ]}
                />
              }
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  Settings
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasePreviewHeading;
