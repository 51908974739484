import create from "zustand";
import { IUseCase } from "./types";
import { fetchNextPage, fetchPreviousPage } from "./utils";

// useCase store
export default create<IUseCase>((set) => ({
  page: "Patient",
  nextPage: () => set((state) => ({ page: fetchNextPage(state.page) })),
  previousPage: () => set((state) => ({ page: fetchPreviousPage(state.page) })),
  setPage(page) {
    set(() => ({
      page,
    }));
  },
}));
