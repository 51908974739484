import React from "react";

const TreatmentPlanCost = ({ cost }: { cost: string }) => {
  return (
    <div className="ViewBox bg-green-50/25 shadow-lg w-96 py-8 flex items-center justify-center border-b-4 border-b-green-400 border-solid border-0">
      <div className="Price">
        <span className="font-bold text-6xl text-red-500">{cost}</span>{" "}
        <span className="font-bold text-md">SAR</span>
      </div>
    </div>
  );
};

export default TreatmentPlanCost;
