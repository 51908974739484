import { PlusOutlined, ScanOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

type props = {};

const Heading: React.FC<props> = (props) => {
  const navigate = useNavigate();
  return (
    <div className="Heading">
      <div className="bg-white p-2 shadow-sm py-4">
        <div className="heading flex justify-between">
          <div className="leftSection">
            <div className="flex gap-2 items-center font-bold text-lg">
              <div className="bg-green-500 text-green-900 px-1.5 text-sm py-0.5 rounded-sm">
                <ScanOutlined />
              </div>
              <div className="text-2xl">Cases List</div>
            </div>
            <div className="flex">
              <div className="bg-yellow-50 p-0.5 px-2 rounded-sm mt-0.5 font-semibold">
                You can preview, manage, check cases from list bellow
              </div>
            </div>
          </div>
          <div className="rightSection">
            <Button
              type="link"
              onClick={() => navigate("/cases/create_case")}
              icon={<PlusOutlined />}
            >
              New Case
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Heading;
