import {
  Alert,
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  message,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { CasesStatus, MultiFilesUpload } from "components";
import React from "react";
import { useCreateMutation, useUpdateMutation } from "utils";
import { useUpdateEffect } from "hooks";
import { useUser } from "models";
import useCasePreview from "./../hooks/useCasePreview";
import { GetStatus } from "./../utils/StatusHelper";
import notification from "utils/Notification";
import { ExtraProps } from "./ActionsList";

type Props = {
  status: IStatus;
};

const ActionEdit = (props: Props) => {
  const [form] = Form.useForm();

  // use Update Mutation
  const sending = useUpdateMutation("status");

  // Check the extra
  const previewExtra = (extra: string | null) => {
    if (extra == null) return null;
    if (extra === "") return null;
    const data: ExtraProps = JSON.parse(extra);
    return data;
  };

  // Install Extra From the other props
  const [extra, setExtra] = React.useState<ExtraProps>(
    previewExtra(props.status.extra) ?? {
      upper: 0,
      lower: 0,
      steps: 4,
      stepInstructions: [
        { duran: false, caPro: false },
        { duran: false, caPro: false },
        { duran: false, caPro: false },
        { duran: false, caPro: false },
        { duran: false, caPro: false },
        { duran: false, caPro: false },
      ],
      stepDetails: [
        { soft: false, medium: false, hard: false },
        { soft: false, medium: false, hard: false },
        { soft: false, medium: false, hard: false },
        { soft: false, medium: false, hard: false },
        { soft: false, medium: false, hard: false },
        { soft: false, medium: false, hard: false },
      ],
      attachmentTemplate: "",
    }
  );

  // User
  const user = useUser((state) => state.user);
  // Case preview
  const casePreview = useCasePreview((state) => state.case);
  if (!user || !casePreview) return null;

  const handleSubmitForm = (values: any) => {
    sending.mutate({
      ...values,
      ID: props.status.ID,
      extra: JSON.stringify(extra),
    });

    notification.send({
      message: `Case #${casePreview.ID}: Has Update the status action`,
      userID: user.ID,
      caseID: casePreview.ID,
      alertType: "success",
      notificationType: "default",
      targetType: "user",
    });
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useUpdateEffect(() => {
    if (sending.isError) {
      message.error("There are error while submit status.");
    }
    if (sending.isSuccess) {
      message.success("Submit status success");
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  }, [sending.isSuccess, sending.isError]);

  const status = GetStatus(casePreview.statusID);
  // const nextStatus = NextStatus(casePreview.statusID);
  const nextStatus = () => {
    if (CasesStatus[casePreview.statusID + 1])
      return CasesStatus[casePreview.statusID + 1];
    return undefined;
  };

  if (!status) return null;
  if (casePreview.statusID === 5) return null;

  return (
    <div>
      <Form
        name="status"
        onFinish={handleSubmitForm}
        initialValues={{ note: props.status.note, files: [] }}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        {/* Extra instructions sets */}
        {props.status.statusID === 3 && (
          <div className="ExtraInstructionsPreview py-0">
            <Form.Item label="Number of Steps">
              <div className="flex items-center gap-1">
                <div className="font-bold text-md">Send</div>
                <InputNumber
                  placeholder="Number"
                  value={extra.steps}
                  onChange={(e) => setExtra({ ...extra, steps: e || 1 })}
                />
                <div className="font-bold">Steps</div>
              </div>
            </Form.Item>

            <Form.Item label="Steps Details">
              {extra.steps > 0 &&
                Array.from(Array(extra.steps).keys()).map((trg) => (
                  <div className="div flex items-center gap-4 mb-2">
                    <div className="font-bold">Step {trg + 1}</div>
                    <Checkbox
                      checked={extra.stepDetails[trg].soft}
                      onChange={(e) => {
                        setExtra({
                          ...extra,
                          stepDetails: extra.stepDetails.map((i, inx) => {
                            if (inx === trg) {
                              return {
                                ...i,
                                soft: e.target.checked,
                              };
                            }
                            return i;
                          }),
                        });
                      }}
                    >
                      Soft
                    </Checkbox>
                    <Checkbox
                      checked={extra.stepDetails[trg].medium}
                      onChange={(e) => {
                        setExtra({
                          ...extra,
                          stepDetails: extra.stepDetails.map((i, inx) => {
                            if (inx === trg) {
                              return {
                                ...i,
                                medium: e.target.checked,
                              };
                            }
                            return i;
                          }),
                        });
                      }}
                    >
                      Medium
                    </Checkbox>
                    <Checkbox
                      checked={extra.stepDetails[trg].hard}
                      onChange={(e) => {
                        setExtra({
                          ...extra,
                          stepDetails: extra.stepDetails.map((i, inx) => {
                            if (inx === trg) {
                              return {
                                ...i,
                                hard: e.target.checked,
                              };
                            }
                            return i;
                          }),
                        });
                      }}
                    >
                      Hard
                    </Checkbox>
                  </div>
                ))}
            </Form.Item>
            <Form.Item label="Number of Aligner" className="mt-2">
              <div className="flex gap-2 items-center">
                <div className="labelView font-medium">Upper</div>
                <InputNumber
                  placeholder="upper"
                  value={extra.upper}
                  onChange={(e) => setExtra({ ...extra, upper: e || 0 })}
                />
                <div className="labelView font-medium">Lower</div>
                <InputNumber
                  placeholder="lower"
                  value={extra.lower}
                  onChange={(e) => setExtra({ ...extra, lower: e || 0 })}
                />
              </div>
            </Form.Item>
            {extra.steps > 0 &&
              Array.from(Array(extra.steps).keys()).map((trg) => (
                <div className="div flex items-center gap-4">
                  <div className="font-medium">Step {trg + 1}</div>
                  <Checkbox
                    checked={extra.stepInstructions[trg].duran}
                    onChange={(e) => {
                      setExtra({
                        ...extra,
                        stepInstructions: extra.stepInstructions.map(
                          (i, inx) => {
                            if (inx === trg) {
                              return {
                                ...i,
                                duran: e.target.checked,
                              };
                            }
                            return i;
                          }
                        ),
                      });
                    }}
                  >
                    Duran
                  </Checkbox>
                  <Checkbox
                    checked={extra.stepInstructions[trg].caPro}
                    onChange={(e) => {
                      setExtra({
                        ...extra,
                        stepInstructions: extra.stepInstructions.map(
                          (i, inx) => {
                            if (inx === trg) {
                              return {
                                ...i,
                                caPro: e.target.checked,
                              };
                            }
                            return i;
                          }
                        ),
                      });
                    }}
                  >
                    Ca Pro
                  </Checkbox>
                </div>
              ))}
            <Form.Item label={"Attachment Template"} className="mt-4">
              <div className="flex gap-2 font-medium items-center">
                <div className="font-medium">Copyplast At Step Number</div>
                <Input
                  width={"2rem"}
                  value={extra.attachmentTemplate}
                  onChange={(e) =>
                    setExtra({ ...extra, attachmentTemplate: e.target.value })
                  }
                  className="w-52"
                />
              </div>
            </Form.Item>
          </div>
        )}
        {/* End extra instructions sets */}
        <Form.Item label="Note" className="font-bold" name="note">
          <TextArea rows={4} placeholder="Add note to the submit" />
        </Form.Item>
        <Alert
          message={`You well update the information`}
          type="info"
          showIcon
        />
        <div className=" mt-4">
          <Button
            htmlType="submit"
            block
            loading={sending.isLoading}
            disabled={sending.isLoading}
            type="primary"
          >
            Save
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ActionEdit;
