// read steps active status
import type { IPages } from "../hooks/types";

export function ReadStepsActiveStatus(page: IPages) {
  switch (page) {
    case "Patient":
      return 0;
    case "ClinicalCondition":
      return 1;
    case "Attachments":
      return 2;
    case "Prescription":
      return 3;
    case "Success":
      return 4;
    default:
      return 0;
  }
}

export function SetStepsActiveStatus(pageNumber: number): IPages {
  switch (pageNumber) {
    case 0:
      return "Patient";
    case 1:
      return "ClinicalCondition";
    case 2:
      return "Attachments";
    case 3:
      return "Prescription";
    case 4:
      return "Success";
    default:
      return "Patient";
  }
}
