//// Cases status

interface CasesStatusInterface {
  statusID: number;
  duration: number;
  manager: { status: string; waiting: string; doAction?: boolean };
  assistant: { status: string; waiting: string; doAction?: boolean };
  user: { status: string; waiting: string; doAction?: boolean };
  factory: { status: string; waiting: string; doAction?: boolean };
  confirmButton: string;
}

export const CasesStatus: CasesStatusInterface[] = [
  {
    statusID: 0,
    duration: 0,
    manager: {
      status: "New Case",
      waiting: "Submit Case Received",
      doAction: true,
    },
    assistant: {
      status: "New Case",
      waiting: "Submit Case Received",
      doAction: true,
    },
    user: {
      status: "New case",
      waiting: "Submit Case Received",
    },
    factory: {
      status: "New case",
      waiting: "Submit Case Received",
    },
    confirmButton: "Set Case Received",
  },
  {
    statusID: 1,
    duration: 0,
    manager: {
      status: "Waiting Treatment Plan",
      waiting: "Submit Treatment plan",
      doAction: true,
    },
    assistant: {
      status: "Waiting Treatment Plan",
      waiting: "Submit Treatment plan",
      doAction: true,
    },
    user: {
      status: "Waiting Treatment Plan",
      waiting: "Submit Treatment plan",
    },
    factory: {
      status: "Waiting Treatment Plan",
      waiting: "Submit Treatment plan",
    },
    confirmButton: "Submit Treatment plan",
  },
  {
    statusID: 2,
    duration: 24,
    manager: {
      status: "Waiting Approve TX",
      waiting: "Waiting Approve Treatment plan",
    },
    assistant: {
      status: "Waiting Approve TX",
      waiting: "Approve Treatment plan",
    },
    user: {
      status: "Waiting Approve TX",
      waiting: "Waiting Approve Treatment plan",
      doAction: true,
    },
    factory: {
      status: "Waiting Approve TX",
      waiting: "Waiting Approve Treatment plan",
    },
    confirmButton: "Approve Treatment plan",
  },
  {
    statusID: 3,
    duration: 24,
    manager: {
      status: "Submitted Case",
      waiting: "Set instruction",
      doAction: true,
    },
    assistant: {
      status: "Submitted Case",
      waiting: "Set instruction",
      doAction: true,
    },
    user: {
      status: "Submitted Case",
      waiting: "Set instructions",
    },
    factory: {
      status: "Submitted Case",
      waiting: "Set instructions",
    },
    confirmButton: "Set instructions",
  },
  {
    statusID: 4,
    duration: 24,
    manager: {
      status: "Sending to lab",
      waiting: "In process",
      doAction: true,
    },
    assistant: {
      status: "Sending to lab",
      waiting: "In process",
      doAction: true,
    },
    user: {
      status: "In Process",
      waiting: "In Process",
    },
    factory: {
      status: "In Process",
      waiting: "In Process",
    },
    confirmButton: "Set to in process",
  },
  {
    statusID: 5,
    duration: 24,
    manager: {
      status: "In process",
      waiting: "Complete",
      doAction: true,
    },
    assistant: {
      status: "In process",
      waiting: "Complete",
      doAction: true,
    },
    user: {
      status: "In Process",
      waiting: "Complete",
    },
    factory: {
      status: "In Process",
      waiting: "Complete",
    },
    confirmButton: "Complete",
  },
  {
    statusID: 6,
    duration: 24,
    manager: {
      status: "Complete",
      waiting: "",
      doAction: true,
    },
    assistant: {
      status: "Complete",
      waiting: "",
      doAction: true,
    },
    user: {
      status: "Complete",
      waiting: "",
    },
    factory: {
      status: "Complete",
      waiting: "",
    },
    confirmButton: "Complete",
  },
];
