/* eslint-disable react-hooks/rules-of-hooks */
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Divider, Result } from "antd";
import React from "react";
import { AddressHeading } from "./AddressHeading";
import { useReadQuery } from "utils";
import { useUser } from "models";
import { useNavigate } from "react-router-dom";
import { Loader } from "components";
import AddressesTable from "./AddressesTable";

type props = {};

const Address: React.FC<props> = (props) => {
  const user = useUser((s) => s.user);

  const navigate = useNavigate();

  if (!user) return null;
  const find = {
    userID: user.ID,
  };
  const { isLoading, data, refetch } = useReadQuery<{
    data: IAddress[];
  }>(`addresses?find=${find}`);
  if (isLoading)
    return (
      <div className="h-full">
        <div className="bg-white p-1 rounded-sm m-1 h-full flex items-center justify-center">
          <div className="relative">
            <Loader />
          </div>
        </div>
      </div>
    );

  if (!data)
    return (
      <div className="h-full">
        <div className="bg-white p-1 rounded-sm m-1 h-full flex items-center justify-center">
          <Result
            status={"500"}
            title="Network error"
            subTitle="Unexpected network error please try again later"
            extra={[
              <Button
                type="link"
                icon={<ReloadOutlined />}
                onClick={() => {
                  refetch();
                }}
              >
                Try again
              </Button>,
            ]}
          />
        </div>
      </div>
    );

  if (data.data.length === 0)
    return (
      <div className="h-full">
        <div className="bg-white p-1 rounded-sm m-1 h-full flex items-center justify-center">
          <Result
            status={"404"}
            title="Empty addresses"
            subTitle="Your addresses is empty please add new address"
            extra={[
              <Button
                type="link"
                icon={<PlusOutlined />}
                onClick={() => {
                  navigate("/new_address");
                }}
              >
                New address
              </Button>,
            ]}
          />
        </div>
      </div>
    );
  return (
    <div className="Address h-full">
      <div className="bg-white p-1 rounded-sm m-1 h-full">
        <AddressHeading />
        <Divider />
        <AddressesTable data={data.data} />
      </div>
    </div>
  );
};

export default Address;
