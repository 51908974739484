/* eslint-disable no-restricted-globals */
import { CloseOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/lib/table";
import React from "react";
import { http } from "utils";

type props = {
  data: IAddress[];
};

const cols: ColumnsType<IAddress> = [
  {
    title: "#",
    key: "ID",
    dataIndex: "ID",
  },
  {
    title: "Address",
    key: "address",
    dataIndex: "address",
  },
  {
    title: "City",
    key: "city",
    dataIndex: "city",
  },
  {
    title: "Postal code",
    key: "zip",
    dataIndex: "zip",
  },
  {
    title: "Remove",
    key: "remove",
    dataIndex: "ID",
    render(_, { ID }) {
      const remove = async () => {
        const conf = confirm("Do you really want delete address ?");
        if (conf) {
          try {
            const result = await http.delete("api/addresses/" + ID);
            if (result) {
              location.reload();
            }
          } catch (error) {
            console.log(error);
          }
        }
      };
      return (
        <div className=" flex">
          <div
            className="bg-red-100 px-1 rounded-sm border-red-200 text-red-700 border-2 border-solid cursor-pointer hover:bg-red-200 hover:text-red-900"
            onClick={remove}
          >
            <CloseOutlined />
          </div>
        </div>
      );
    },
  },
];

const AddressesTable: React.FC<props> = (props) => {
  return (
    <div className="AddressesTable">
      <Table
        className="font-semibold"
        tableLayout="auto"
        columns={cols}
        size="small"
        dataSource={props.data}
      />
    </div>
  );
};

export default AddressesTable;
