import create from "zustand";

interface IUseTab {
  tabKey: string;
  setTabKey: (key: string) => void;
}

const useCaseTab = create<IUseTab>((set) => ({
  tabKey: "0",
  setTabKey(key) {
    set({ tabKey: key });
  },
}));

export default useCaseTab;
