import { Typography } from "antd";
import React from "react";

type props = {};

const CenteredFooter: React.FC<props> = (props) => {
  return (
    <div
      className="CenteredFooter"
      style={{
        position: "fixed",
        bottom: 2,
        left: 0,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography.Text
        type="secondary"
        strong
        style={{ textAlign: "center", width: "100%" }}
      >
        Copyright 2022@ Clear aligner
      </Typography.Text>
    </div>
  );
};

export default CenteredFooter;
