import { FormBuilderInputsProps } from "../../../FormBuilder/types";
import {
  CalendarOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";

export const patientFormInputs: FormBuilderInputsProps[] = [
  {
    name:"caseType",
    label:"Case Type",
    type:"radio",
    items:[
      {label:"New Case",value: "NewCase"},
      {label:"Extra Aligner",value: "ExtraAligner"},
      {label:"Mid Course Adjustment",value: "MidCourse"},
      {label:"Refinement",value: "Refinement"},
    ],
    span:24
  },
  {
    name: "first_name",
    label: "Patient First name",
    type: "input",
    placeholder: "first name",
    span: 8,
    rules: [{ required: true, message: "Please enter the patient first name" }],
    prefix: <UserOutlined />,
  },
  {
    name: "middle_name",
    label: "Patient Middle Name",
    type: "input",
    placeholder: "middle name",
    span: 8,
    rules: [{ required: true, message: "Please enter the middle patient name" }],
  },
  {
    name: "last_name",
    label: "Patient Last Name",
    type: "input",
    placeholder: "Last name",
    span: 8,
    rules: [{ required: true, message: "Please enter the patient last name" }],
  },
  {
    name: "phone",
    type: "input",
    span: 12,
    placeholder: "phone number",
    label: "Patient Phone number",
    prefix: <PhoneOutlined />,
  },
  {
    name: "email",
    type: "input",
    span: 12,
    placeholder: "Add email address",
    label: "Patient Email",
    prefix: <MailOutlined />,
  },
  {
    name: "gender",
    type: "select",
    label: "Patient Gender",
    rules: [{ required: true, message: "Please select gender" }],
    span: 12,
    placeholder: "Select patient gender",
    items: [
      { label: "Male", value: "male" },
      { label: "Female", value: "female" },
    ],
  },
  {
    type: "input",
    span: 12,
    name: "age",
    label: "Patient Age",
    placeholder: "please add patient age",
    rules: [{ required: true, message: "Please add age number" }],
    prefix: <CalendarOutlined />,
  },
  {
    name: "avatar",
    span: 24,
    type: "avatar",
    label: "Patient Image",
    place: "patients",
  },
  {
    name: "submit",
    span: 20,
    type: "button",
    label: "Next",
    htmlType: "submit",
    className: "w-full mt-2",
    buttonType: "primary",
  },
];
