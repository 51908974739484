import React from "react";
import TreatmentPlanEmpty from "./TreatmentPlanEmpty";
import TreatmentPlanPreview from "./TreatmentPlanPreview";
import TreatmentPlanSubmit from "./TreatmentPlanSubmit";
import { useUser } from "models";
import useCasePreview from "./../hooks/useCasePreview";

type props = {};

const TreatmentPlan: React.FC<props> = (props) => {
  const selected = useCasePreview((state) => state.case);
  const user = useUser((state) => state.user);
  if (!user) return null;
  if (!selected) return null;
  switch (user.type) {
    case "user":
      if (selected.treatmentPlan.ID !== 0) {
        return <TreatmentPlanPreview />;
      }
      return <TreatmentPlanEmpty />;
    case "manager":
      if (selected.treatmentPlan.ID !== 0) {
        return <TreatmentPlanPreview />;
      }
      return <TreatmentPlanSubmit />;
    case "assistant":
      if (selected.treatmentPlan.ID !== 0) {
        return <TreatmentPlanPreview />;
      }
      return <TreatmentPlanSubmit />;
    case "factory":
      return null;
    default:
      return <TreatmentPlanEmpty />;
  }
};

export default TreatmentPlan;
