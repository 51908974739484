import React from "react";
import { Tabs } from "antd";
import UserDetails from "../pages/UserDetails";
import CaseDetails from "../pages/CaseDetails";
import AddressDetails from "../pages/AddressDetails";

const UserTabs = ({ user }: { user?: IUser }) => {
  if (!user) return null;
  return (
    <div className="userPreviewTap mt-2">
      <Tabs defaultActiveKey="0" type="card">
        <Tabs.TabPane tab="Details" key="0">
          <UserDetails user={user} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Cases" key="1">
          <CaseDetails userID={user.ID} userType={user.type} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Addresses" key="2">
          <AddressDetails userID={user.ID} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default UserTabs;
