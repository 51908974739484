import React from "react";
import { Result } from "antd";
type props = {};

const TreatmentPlanEmpty: React.FC<props> = (props) => {
  return (
    <div className="TreatmentPlan">
      <div className="text-lg font-semibold">Treatment plan</div>
      <Result
        status="404"
        title="Empty"
        subTitle="Not received treatment plan yet."
      />
    </div>
  );
};

export default TreatmentPlanEmpty;
