/* eslint-disable no-restricted-globals */
import {
  BookOutlined,
  DownloadOutlined,
  EditFilled,
  FileOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import React, { useRef } from "react";
import useCasePreview from "./../hooks/useCasePreview";
import { ENDPOINT } from "./../../../../utils/constants.utils";
import ImagesUpload, {
  UrlList,
} from "components/Uploads/ImagesUpload/ImagesUpload";
import { Alert, Button, Image, message, Result } from "antd";
import VideoPlayer from "components/Uploads/VideoPlayer/VideoPlayer";
import TextArea from "antd/lib/input/TextArea";
import { useUser } from "models";
import { http, useCreateMutation } from "utils";
import { useUpdateEffect } from "./../../../../hooks/useUpdateEffect";
import { Loader } from "components";
import {
  Header,
  IconLabel,
  PreviewCard,
  TreatmentPlanApproveHeader,
} from "./TreatmentPlanComponents";
import axios from "axios";
import TreatmentPlanSelectAddress from "./TreatmentPlanSelectAddress";
import notification from "utils/Notification";
import TreatmentPlanImages from "./TreatmentPlanPreviewComponents/TreatmentPlanImages";
import TreatmentPlanDoc from "./TreatmentPlanPreviewComponents/TreatmentPlanDoc";
import TreatmentPlanEdit from "./TreatmentPlanEdit";
import TreatmentPlanInstructions from "./TreatmentPlanPreviewComponents/TreatmentPlanInstructions";
import TreatmentPlanPreviewSP from "./TreatmentPlanEditComponent/TreatmentPlanPreviewSP";
import SectionPreviewBase from "./TreatmentPlanPreviewComponents/SectionPreviewBase";
import SectionPreviewBase2 from "./TreatmentPlanPreviewComponents/SectionPreviewBase2";
import { saveAs } from "file-saver";
import TreatmentPlanCost from "./TreatmentPlanPreviewComponents/TreatmentPlanCost";
import email, { MANAGER_EMAIL } from "utils/Email.utils";
type props = {};

const TreatmentPlanPreview: React.FC<props> = (props) => {
  const selected = useCasePreview((state) => state.case);
  const divRef = useRef<any>();

  const user = useUser((user) => user.user);

  const [editingMode, setEditingMode] = React.useState(false);

  const [fileList, setFileList] = React.useState<UrlList[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [note, setNote] = React.useState("");
  const [addressID, setAddressID] = React.useState<number | null>(null);

  const mutation = useCreateMutation("treatment_plan_approve");
  const statusSending = useCreateMutation("status");

  const sendApprove = async () => {
    if (!user) return;
    if (!selected) return;
    const data: ITreatmentPlanApprove = {
      caseID: selected.ID,
      userID: user.ID,
      note,
      files: JSON.stringify(fileList),
      addressID: addressID || 0,
    };
    setIsLoading(true);
    await mutation.mutateAsync(data);
    await axios.put(ENDPOINT + "api/cases", {
      ID: selected.ID,
      statusID: 3,
    });
    await statusSending.mutateAsync({
      userID: user.ID,
      caseID: selected.ID,
      statusID: 3,
      files: [],
    });
    notification.send({
      userID: user.ID,
      message: `${user?.name} Has approved treatment plan for Case #0000${selected.ID}`,
      alertType: "success",
      notificationType: "needAttention",
      targetType: "manager",
      caseID: selected.ID,
    });
    email.send({
      to: MANAGER_EMAIL,
      subject: `${user?.name} Approve treatment plan`,
      data: {
        title: `${user?.name} Approved treatment plan`,
        body: `Case ${selected.ID} approved from doctor ${user?.name} you can check it`,
        url: `${selected.ID}`,
        user: `Manager`,
      },
    });
    await message.success("You have success approve the treatment plan");
    location.reload();
  };

  useUpdateEffect(() => {
    if (mutation.isError) {
      message.error("There are error while updating please try again");
    }
  }, [mutation.isError]);

  const files = React.useMemo<UrlList[]>(() => {
    if (selected) return JSON.parse(selected.treatmentPlan.files);
    return [];
  }, [selected]);

  if (!selected) return null;
  if (editingMode)
    return (
      <TreatmentPlanEdit
        onFinish={() => {
          setEditingMode(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }}
      />
    );
  return (
    <div className="treatment h-full" ref={divRef}>
      {mutation.isLoading && <Loader />}
      <div className="TreatmentPlanPreview mb-2 bg-slate-50 rounded-sm my-2 border-solid border-slate-100">
        <Header />
        <div className=" absolute right-0 top-0">
          <div className="flex justify-end p-4">
            {user?.type === "manager" && (
              <Button
                icon={<EditFilled />}
                type="primary"
                onClick={() => {
                  setEditingMode(true);
                }}
              >
                Edit Treatment Plan
              </Button>
            )}
            {user?.type === "user" && (
              <Alert
                banner
                message="Scroll Down to approve the treatment plan"
              ></Alert>
            )}
          </div>
        </div>

        <div className="p-2">
          {/* Images List */}
          {files.filter((trg) => trg.type === "images").length !== 0 && (
            <TreatmentPlanImages
              files={files.filter((trg) => trg.type === "images")}
            />
          )}
          {selected.treatmentPlan.strippingProtocolFile !== null &&
            selected.treatmentPlan.strippingProtocolFile !== "" && (
              <TreatmentPlanPreviewSP
                fileUrl={selected.treatmentPlan.strippingProtocolFile}
              />
            )}
          {/* Videos */}
          {files.filter((trg) => trg.type === "videos").length !== 0 && (
            <div className="videosList">
              {/* <IconLabel
                title="Videos"
                icon={<VideoCameraOutlined />}
                description={"Preview videos in section bellow"}
              /> */}
              <SectionPreviewBase
                title="Models"
                description="Preview Models Videos"
                img="/attachments/model.png"
                handleDownload={() => {
                  http
                    .post("api/zip:Generate", {
                      files: files
                        .filter((trg) => trg.type === "videos")
                        .map((trg) => trg.url),
                    })
                    .then((res) => {
                      message.success("Files Ready to download");
                      saveAs(
                        ENDPOINT + "public/tmp/" + res.data.url,
                        res.data.url
                      );
                    });
                }}
              >
                <div className="flex gap-2 mt-2">
                  {files
                    .filter((trg) => trg.type === "videos")
                    .map((trg) => (
                      <PreviewCard>
                        <VideoPlayer uri={ENDPOINT + trg.url} />
                      </PreviewCard>
                    ))}
                </div>
              </SectionPreviewBase>
            </div>
          )}

          {files.filter((trg) => trg.type === "documents").length !== 0 && (
            <div className="documentsList mt-4 mb-4">
              {/* <IconLabel
                title="Documents"
                icon={<PaperClipOutlined />}
                description="Download documents from bellow"
              /> */}
              <SectionPreviewBase
                title="Documents"
                description="CSV Document"
                img="/attachments/doc.png"
                handleDownload={() => {
                  http
                    .post("api/zip:Generate", {
                      files: files
                        .filter((trg) => trg.type === "documents")
                        .map((trg) => trg.url),
                    })
                    .then((res) => {
                      message.success("Files Ready to download");
                      saveAs(
                        ENDPOINT + "public/tmp/" + res.data.url,
                        res.data.url
                      );
                    });
                }}
              >
                <div className="flex gap-2 mt-2">
                  {files
                    .filter((trg) => trg.type === "documents")
                    .map((trg) => (
                      <TreatmentPlanDoc file={trg} />
                    ))}
                </div>
              </SectionPreviewBase>
            </div>
          )}

          {files.filter((trg) => trg.type === "3dFile").length !== 0 && (
            <div className="3dFilesPreview mt-4 mb-4">
              {/* <IconLabel
                title="3D File"
                icon={<ForkOutlined />}
                description="Download 3D file"
              /> */}
              <SectionPreviewBase
                title="3D File"
                description="Download 3D File"
                img="/attachments/3d.png"
                handleDownload={() => {
                  http
                    .post("api/zip:Generate", {
                      files: files
                        .filter((trg) => trg.type === "3dFile")
                        .map((trg) => trg.url),
                    })
                    .then((res) => {
                      message.success("Files Ready to download");
                      saveAs(
                        ENDPOINT + "public/tmp/" + res.data.url,
                        res.data.url
                      );
                    });
                }}
              >
                <div className="flex gap-2 mt-2">
                  {files
                    .filter((trg) => trg.type === "3dFile")
                    .map((trg) => (
                      <PreviewCard>
                        <div
                          className="documentView"
                          onClick={() => {
                            window.open(ENDPOINT + trg.url, "_blank");
                          }}
                        >
                          <div className="previewFileIcon flex items-center justify-center">
                            <Image
                              src="/attachments/3d.png"
                              width="10rem"
                              height="10rem"
                              preview={false}
                            />
                          </div>
                          <div className="flex items-center justify-center gap-2 mt-2">
                            <DownloadOutlined />
                            <div className="text-medium text-xs text-center font-bold">
                              Download 3D file
                            </div>
                          </div>
                        </div>
                      </PreviewCard>
                    ))}
                </div>
              </SectionPreviewBase>
            </div>
          )}
          {files.filter((trg) => trg.type === "files").length !== 0 && (
            <div className="documentsList mt-2">
              <IconLabel
                title="Files"
                icon={<FileOutlined />}
                description="Download files from bellow"
              />
              <SectionPreviewBase
                title="Files"
                description="Download files from bellow"
                img="/attachments/files.png"
              >
                <div className="flex gap-2 mt-2">
                  {files
                    .filter((trg) => trg.type === "files")
                    .map((trg) => (
                      <PreviewCard>
                        <div
                          className="documentView"
                          onClick={() => {
                            window.open(ENDPOINT + trg.url, "_blank");
                          }}
                        >
                          <div className="previewFileIcon flex items-center justify-center">
                            <Image
                              src="/attachments/files.png"
                              width="10rem"
                              height="10rem"
                              preview={false}
                            />
                          </div>
                          <div className="flex items-center justify-center gap-2 mt-2">
                            <DownloadOutlined />
                            <div className="text-medium text-xs text-center font-bold">
                              Download file
                            </div>
                          </div>
                        </div>
                      </PreviewCard>
                    ))}
                </div>
              </SectionPreviewBase>
            </div>
          )}

          <div className="documentsList">
            <TreatmentPlanInstructions />
          </div>
          {selected.treatmentPlan.link && selected.treatmentPlan.link !== "" && (
            <SectionPreviewBase2
              title="3D Video Link"
              description=""
              img={"/attachments/link.png"} >
              <div className="Preview flex items-left justify-left"> 
                <a href={selected.treatmentPlan.link} target="_blank">{selected.treatmentPlan.link}</a>
              </div>
            </SectionPreviewBase2>
          )}
          {/* Cost Preview */}
          {selected.treatmentPlan.cost &&
            selected.treatmentPlan.cost !== "" && (
              <SectionPreviewBase
                title="Cost"
                img={"/attachments/money.png"}
                description=""
              >
                <div className="Preview flex items-center justify-center py-8">
                  <TreatmentPlanCost cost={selected.treatmentPlan.cost} />
                </div>
              </SectionPreviewBase>
            )}
        </div>
      </div>
      {/* Treatment plan approve */}
      {selected.treatmentPlanApprove.ID === 0 &&
        user?.ID === selected.userID && (
          <div className="TreatmentPlanApprove mb-4 bg-slate-50 rounded-sm my-2 border-solid border-slate-100">
            <TreatmentPlanApproveHeader />
            <div className="p-2">
              <div className="note">
                <IconLabel
                  title="Treatment plan suggestions"
                  icon={<BookOutlined />}
                  description="Please fill a suggestions for the treatment plan if you have"
                />
                <div className="textForm">
                  <TextArea
                    rows={4}
                    className="mt-1"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </div>
              </div>
              <div className="address">
                <IconLabel
                  title="Address"
                  icon={<HomeOutlined />}
                  description={"Please select address to shipping"}
                />
                <TreatmentPlanSelectAddress
                  addressID={addressID}
                  setAddressID={setAddressID}
                />
              </div>
              <div className="files">
                <IconLabel
                  title="Files"
                  icon={<FileOutlined />}
                  description="Please upload files if want to submit in approve"
                />
                <ImagesUpload
                  fileList={fileList}
                  setFileList={setFileList}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  type="files"
                />
              </div>

              <div className="approveButton mt-2">
                <Button block type="primary" onClick={sendApprove}>
                  Approve Treatment Plan
                </Button>
              </div>
            </div>
          </div>
        )}
      {selected.treatmentPlanApprove.ID !== 0 && (
        <div className="treatmentPlanApprove mb-4 bg-slate-50 rounded-sm my-2 border-solid border-slate-100">
          <TreatmentPlanApproveHeader />
          <div className="p-2 flex justify-center">
            <Result
              title="Treatment Plan Approved"
              subTitle="You have approve treatment plan "
              status={"success"}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TreatmentPlanPreview;
