import { BoxPlotOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const ListItemHeader = () => {
  const navigate = useNavigate();

  return (
    <div className="newItemHeader">
      <div className="flex justify-between">
        <div className="headTitle">
          <div className="flex gap-2 items-center">
            <div className="bg-blue-100 px-1 rounded-sm border-blue-200 text-blue-800 border-2 border-solid">
              <BoxPlotOutlined />
            </div>
            <div className="font-bold text-lg">List Item</div>
          </div>
          <div className="font-semibold">
            Check your item list here and you can edit or remove it
          </div>
        </div>
        <Button
          type="link"
          icon={<PlusOutlined />}
          onClick={() => {
            navigate("/item/new");
          }}
        >
          New item
        </Button>
      </div>
      <Divider />
    </div>
  );
};

export default ListItemHeader;
