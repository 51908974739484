import { IPages } from "./types";

// Pages Handlers
export function fetchNextPage(currentPage: IPages): IPages {
  switch (currentPage) {
    case "Patient":
      return "ClinicalCondition";
    case "ClinicalCondition":
      return "Attachments";
    case "Attachments":
      return "Prescription";
    case "Prescription":
      return "Success";
    default:
      return "Patient";
  }
}
export function fetchPreviousPage(currentPage: IPages): IPages {
  switch (currentPage) {
    case "Patient":
      return "Patient";
    case "ClinicalCondition":
      return "Patient";
    case "Attachments":
      return "ClinicalCondition";
    case "Prescription":
      return "Attachments";
    default:
      return "Patient";
  }
}
