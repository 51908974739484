// Notifications sending

import { http } from "./Api.utils";

const notification = {
  async send(data: INotification) {
    try {
      const result = await http.post("api/notification", data);
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  },
  async fetch(params: string, options?: any) {
    try {
      const result = await http.get<{ data: INotification[] }>(
        "api/notification" + params,
        { params: options }
      );
      return result.data.data;
    } catch (error) {
      return undefined;
    }
  },
};

export default notification;
