/* eslint-disable no-useless-escape */
import { Alert, Button, Checkbox, Form, Input, message } from "antd";
import React from "react";

import { LockOutlined, MailOutlined } from "@ant-design/icons";

import { useUpdateEffect } from "../../hooks/useUpdateEffect";
import useUser from "../../models/useUser";
import { useLogin } from "../../utils/Api.utils";

type props = {};

const Login: React.FC<props> = (props) => {
  const { data, isError, isLoading, mutate } = useLogin();
  const onFinish = (values: IULogin) => {
    mutate(values);
  };

  const onFinishFailed = () => {
    message.error("Please complete all require fields");
  };

  const setUser = useUser((state) => state.setUser);
  useUpdateEffect(() => {
    if (!data) return;
    if (data.user.isActive === 1) {
      message.error(
        "Account not active yet, Please wait until the manager active your account"
      );
      return;
    }

    message.success("Login success, You can access our services");
    setUser(data.token, data.user);
  }, [data]);
  return (
    <div className="Login ">
      <Form
        name="basic"
        initialValues={{ remember: true }}
        style={{ marginTop: 12 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
            {
              pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: "Please enter valid address",
            },
          ]}
        >
          <Input placeholder="Email" prefix={<MailOutlined />} />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password placeholder="Password" prefix={<LockOutlined />} />
        </Form.Item>
        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item>
        {isError && (
          <Alert
            style={{ marginBottom: 10 }}
            type="error"
            showIcon
            message="There are error in Email/Password"
          ></Alert>
        )}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={isLoading}
            loading={isLoading}
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
