import "./layout.css";

import { Col, Layout, Row } from "antd";
import React from "react";

import Logo from "../Core/Logo/Logo";
import AccountPreview from "./AccountPreview";
import SidePanel from "./SidePanel";
import { MenuOutlined } from "@ant-design/icons";
import useSidePanel from "./useSidepanel";

const { Header, Content, Sider } = Layout;

const DashboardLayout: React.FC<{
  children?: JSX.Element;
}> = ({ children }) => {
  const show = useSidePanel((state) => state.show);
  const setShow = useSidePanel((state) => state.setShow);

  return (
    <Layout className="fullPage">
      <Header className="layoutHeader">
        <Row gutter={12}>
          <Col className="sm:block md:hidden lg:hidden">
            <div
              className="IconMenu pt-2 "
              onClick={() => {
                setShow(!show);
              }}
            >
              <MenuOutlined className=" text-2xl text-white" />
            </div>
          </Col>
          <Col>
            <a href="/">
              <Logo size="xs" />
            </a>
          </Col>
        </Row>
        <div className="div">
          <AccountPreview />
        </div>
      </Header>
      <Layout>
        <Sider
          width={220}
          className={`site-layout-background ${show ? "" : "hideSidePanel"}`}
        >
          <SidePanel />
        </Sider>
        <Layout>
          <Content
            className="overflow-y-scroll hideScrollBar"
            onClick={() => {
              setShow(false);
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default React.memo(DashboardLayout);
