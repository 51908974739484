/* eslint-disable react-hooks/rules-of-hooks */
import { Alert, Col, Divider, Empty, Row } from "antd";
import React from "react";
import { NextStatus } from "../utils/StatusHelper";
import useCasePreview from "./../hooks/useCasePreview";
import { useUser } from "models";
import {
  AlertOutlined,
  BellFilled,
  BellOutlined,
  ClockCircleFilled,
  EditOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import { useReadQuery } from "./../../../../utils/Api.utils";
import TimeLinePreviewTreatmentPlanPreview from "./TimeLinePreviewTreatmentPlanPreview";
import TimeLinePreviewTreatmentPlanApprove from "./TimeLinePreviewTreatmentPlanApprove";
import { useUpdateEffect } from "hooks";
import DetailsBox from "components/Boxes/DetailsBox";
import { ProgressCasePreview } from "./SummaryComponents/ProgressCasePreview";
import { CaseTimeLine } from "./SummaryComponents/CaseTimeLine";
import WaitingAction from "./SummaryComponents/WaitingAction";
import { useParams } from "react-router-dom";

type props = {};

const TimeLinePreview: React.FC<props> = (props) => {
  const selected = useCasePreview((s) => s.case);
  const user = useUser((state) => state.user);
  const params = useParams();
  const notifications = useReadQuery<{ data: INotification[] }>(
    "notification",
    "",
    {
      params: {
        where: "case_id = " + params.id,
      },
    }
  );
  useUpdateEffect(() => {
    notifications.refetch();
  }, [selected]);

  if (!user) return null;
  if (!selected) return null;
  const nextStatus = NextStatus(selected.statusID);

  return (
    <div className="TimeLinePreview mt-2">
      <div className="mt-1">
        {selected.treatmentPlan.ID !== 0 &&
          selected.treatmentPlanApprove.ID === 0 && (
            <TimeLinePreviewTreatmentPlanPreview />
          )}
        {selected.treatmentPlanApprove.ID !== 0 && (
          <TimeLinePreviewTreatmentPlanApprove />
        )}
        <div className="flex flex-row gap-4 flex-wrap items-stretch mt-2">
          <div className="basis-1/4">
            <DetailsBox title="Progress" icon={<FieldTimeOutlined />}>
              <ProgressCasePreview />
            </DetailsBox>
          </div>
          <div className="grow self-stretch h-auto">
            <DetailsBox title="Case Timeline" icon={<FieldTimeOutlined />}>
              <CaseTimeLine />
            </DetailsBox>
          </div>
        </div>
        <div className="mt-2 mb-4">
          <WaitingAction />
        </div>
        <DetailsBox title="Notifications" icon={<BellOutlined />}>
          <>
            {notifications.data?.data.length === 0 && (
              <div className="h-64 items-center justify-center flex">
                <Empty description="Empty notification" />
              </div>
            )}
            {notifications.data?.data.length !== 0 && (
              <div className="list">
                {notifications.data?.data.map((trg, index) => (
                  <Alert
                    key={trg.ID}
                    className="m-2 font-semibold"
                    message={trg.message}
                    type={trg.alertType}
                    showIcon
                  />
                ))}
              </div>
            )}
          </>
        </DetailsBox>
      </div>
    </div>
  );
};

export default TimeLinePreview;
