import {
  AlertOutlined,
  BookOutlined,
  FileDoneOutlined,
  FormOutlined,
  KeyOutlined,
  Loading3QuartersOutlined,
  PicLeftOutlined,
  PieChartOutlined,
  PlusCircleOutlined,
  UserAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { Badge } from "antd";
import { useEffectOnce } from "hooks";
import { useUser } from "models";
import React from "react";
import { useNavigate } from "react-router-dom";
import { http } from "utils";
import useSidePanel from "./useSidepanel";

type props = {
  title: string;
  icon?: any;
  onClick?: any;
  index: number;
  key: string;
  badgeKey?: string;
  navigate?: string;
};

function SidePanelGroupItem(props: props) {
  const badges = useSidePanel((state) => state.badges);
  const activeIndex = useSidePanel((state) => state.activeIndex);
  const setActiveIndex = useSidePanel((state) => state.setActiveIndex);
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        setActiveIndex(props.index);
        navigate(props.navigate ?? "");
      }}
      className={`sidePanelGroupItem cursor-pointer hover:bg-slate-50 ${
        activeIndex === props.index ? "bg-slate-100" : ""
      } rounded-sm ${
        activeIndex === props.index ? "border-r-2 border-r-slate-300" : ""
      }`}
    >
      <div className="flex px-2 py-1 my-1 gap-2 items-center">
        {props.icon}
        <Badge
          count={props.badgeKey ? badges[props.badgeKey] : 0}
          offset={[20, 10]}
        >
          <div className="text-base">{props.title}</div>
        </Badge>
      </div>
    </div>
  );
}

function SidePanelGroup(props: { title: string; items: props[] }) {
  return (
    <div className="sidePanelGroup mt-3">
      <div className="text-lg font-semibold">{props.title}</div>
      <div className="mt-0">
        {props.items.map((trg) => (
          <SidePanelGroupItem {...trg} />
        ))}
      </div>
    </div>
  );
}

const SidePanel: React.FC = () => {
  const data: { title: string; items: props[] }[] = [
    {
      title: "Dashboard",
      items: [
        {
          title: "Analysis",
          icon: <PieChartOutlined />,
          index: 1,
          key: "/",
          navigate: "/",
        },
        {
          title: "Messages",
          icon: <AlertOutlined />,
          index: 2,
          key: "/dashboard/messages",
          navigate: "/dashboard/messages",
        },
      ],
    },
    {
      title: "Cases",
      items: [
        {
          title: "Create case",
          icon: <PlusCircleOutlined />,
          index: 21,
          key: "/cases/create_case",
          navigate: "/cases/create_case",
        },
        {
          title: "Case List",
          icon: <BookOutlined />,
          index: 22,
          key: "/cases/case_list/new_cases",
          navigate: "/cases/case_list/new_cases",
          badgeKey: "newCases",
        },
        {
          title: "Waiting TX Plan",
          icon: <FormOutlined />,
          index: 23,
          key: "/cases/case_list/waiting_tx_plan",
          navigate: "/cases/case_list/waiting_tx_plan",
          badgeKey: "waitingTreatmentPlan",
        },
        {
          title: "Need Approve",
          icon: <FormOutlined />,
          index: 24,
          key: "/cases/case_list/need_approve",
          navigate: "/cases/case_list/need_approve",
          badgeKey: "needApproved",
        },
        {
          title: "In process",
          icon: <Loading3QuartersOutlined />,
          index: 25,
          key: "/cases/case_list/in_process",
          navigate: "/cases/case_list/in_process",
          badgeKey: "inProcessCases",
        },
        {
          title: "Shipped",
          icon: <FileDoneOutlined />,
          index: 26,
          key: "/cases/case_list/completed",
          navigate: "/cases/case_list/completed",
          badgeKey: "completedCases",
        },
      ],
    },
    {
      title: "Account Settings",
      items: [
        {
          title: "New Address",
          icon: <PlusCircleOutlined />,
          index: 33,
          key: "/new_address",
          navigate: "/new_address",
        },
        {
          title: "Addresses",
          icon: <PicLeftOutlined />,
          index: 31,
          key: "/address",
          navigate: "/address",
        },
        {
          title: "Account settings",
          icon: <UserOutlined />,
          index: 32,
          key: "/account_settings",
          navigate: "/account_settings",
        },
        {
          title: "Change password",
          icon: <KeyOutlined />,
          index: 34,
          key: "/change_password",
          navigate: "/change_password",
        },
      ],
    },
  ];

  const dataManager: { title: string; items: props[] }[] = [
    {
      title: "Dashboard",
      items: [
        {
          title: "Analysis",
          icon: <PieChartOutlined />,
          index: 1,
          key: "/",
          navigate: "/",
        },
        {
          title: "Messages",
          icon: <AlertOutlined />,
          index: 2,
          key: "/dashboard/messages",
          navigate: "/dashboard/messages",
        },
      ],
    },
    {
      title: "Cases",
      items: [
        {
          title: "Create case",
          icon: <PlusCircleOutlined />,
          index: 21,
          key: "/cases/create_case",
          navigate: "/cases/create_case",
        },
        {
          title: "Case List",
          icon: <BookOutlined />,
          index: 22,
          key: "/cases/case_list/new_cases",
          navigate: "/cases/case_list/new_cases",
          badgeKey: "newCases",
        },
        {
          title: "Waiting TX Plan",
          icon: <FormOutlined />,
          index: 23,
          key: "/cases/case_list/waiting_tx_plan",
          navigate: "/cases/case_list/waiting_tx_plan",
          badgeKey: "waitingTreatmentPlan",
        },
        {
          title: "Waiting Approve",
          icon: <FormOutlined />,
          index: 26,
          key: "/cases/case_list/need_approve",
          navigate: "/cases/case_list/need_approve",
          badgeKey: "needApproved",
        },
        {
          title: "Submitted cases",
          icon: <Loading3QuartersOutlined />,
          index: 27,
          key: "/cases/case_list/submitted_cases",
          navigate: "/cases/case_list/submitted_cases",
          badgeKey: "submittedCases",
        },
        {
          title: "Sending to lab",
          icon: <Loading3QuartersOutlined />,
          index: 29,
          key: "/cases/case_list/sending_to_lab",
          navigate: "/cases/case_list/sending_to_lab",
          badgeKey: "sendingToLab",
        },
        {
          title: "In Process Case",
          icon: <Loading3QuartersOutlined />,
          index: 28,
          key: "/cases/case_list/in_process",
          navigate: "/cases/case_list/in_process",
          badgeKey: "inProcessCases",
        },
        {
          title: "Shipped",
          icon: <FileDoneOutlined />,
          index: 25,
          key: "/cases/case_list/completed",
          navigate: "/cases/case_list/completed",
          badgeKey: "completedCases",
        },
      ],
    },
    {
      title: "Account Settings",
      items: [
        {
          title: "New Address",
          icon: <PlusCircleOutlined />,
          index: 33,
          key: "/new_address",
          navigate: "/new_address",
        },
        {
          title: "Addresses",
          icon: <PicLeftOutlined />,
          index: 31,
          key: "/address",
          navigate: "/address",
        },
        {
          title: "Account settings",
          icon: <UserOutlined />,
          index: 32,
          key: "/account_settings",
          navigate: "/account_settings",
        },
        {
          title: "Change password",
          icon: <KeyOutlined />,
          index: 34,
          key: "/change_password",
          navigate: "/change_password",
        },
      ],
    },
    {
      title: "Users",
      items: [
        {
          title: "Create User",
          icon: <UserAddOutlined />,
          index: 41,
          key: "/new_user",
          navigate: "/new_user",
        },
        {
          title: "User list",
          icon: <UserSwitchOutlined />,
          index: 42,
          key: "/user_list",
          navigate: "/users_list",
        },
        {
          title: "Need active users",
          icon: <UserSwitchOutlined />,
          index: 43,
          key: "/need_active_users_list",
          navigate: "/need_active_users_list",
          badgeKey: "needApprovedAccount",
        },
        {
          title: "Search User",
          icon: <KeyOutlined />,
          index: 44,
          key: "/user_search",
          navigate: "/user_search"
        }
      ],
    },
    // {
    //   title: "Items",
    //   items: [
    //     {
    //       title: "New Item",
    //       icon: <KeyOutlined />,
    //       index: 51,
    //       key: "/item/new",
    //       navigate: "/item/new",
    //     },
    //     {
    //       title: "Item List",
    //       icon: <KeyOutlined />,
    //       index: 52,
    //       key: "/item/list",
    //       navigate: "/item/list",
    //     },
    //     {
    //       title: "Buy Item",
    //       icon: <GiftOutlined />,
    //       key: "/item/buy",
    //       navigate: "/item/buy",
    //       index: 53,
    //     },
    //     {
    //       title: "My Orders",
    //       icon: <CiOutlined />,
    //       key: "/item/orders",
    //       navigate: "/item/orders",
    //       index: 54,
    //     },
    //     {
    //       title: "Orders",
    //       icon: <AlertOutlined />,
    //       key: "/item/all",
    //       navigate: "/item/all",
    //       index: 55,
    //     },
    //   ],
    // },
  ];
  const user = useUser((state) => state.user);
  const setBadges = useSidePanel((state) => state.setBadges);
  async function fetchCounts() {
    if (!user) return null;
    try {
      const url = user.type === "manager" ? "all" : user.ID.toString();
      const result = await http.get<{ [key: string]: number }>(
        "api/count/cases/" + url
      );
      setBadges(result.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffectOnce(() => {
    fetchCounts();
  });
  if (!user) return null;
  return (
    <div className="SidePanel px-2 mt-2">
      {user.type === "manager" && (
        <>
          {dataManager.map((d, index) => (
            <SidePanelGroup key={index} {...d} />
          ))}
        </>
      )}
      {user.type !== "manager" && (
        <>
          {data.map((d, index) => (
            <SidePanelGroup key={index} {...d} />
          ))}
        </>
      )}
    </div>
  );
};

export default SidePanel;
