import { Image, Modal } from "antd";
import assets from "assets";
import React from "react";

type Props = {
  uri: string;
};

const VideoPlayer = ({ uri }: Props) => {
  const [isModelOpen, setIsModelOpen] = React.useState(false);

  return (
    <>
      <div className="relativex">
        <div className="previewImage relative">
          <video
            width="100%"
            height="100%"
            onClick={() => setIsModelOpen(true)}
            autoPlay={false}
            controls={false}
          >
            <source src={uri} type="video/mp4" />
          </video>
        </div>
      </div>
      <Modal
        footer={null}
        open={isModelOpen}
        title="Preview Video"
        onCancel={() => setIsModelOpen(false)}
      >
        <div className="w-full flex items-center justify-center">
          <video width={400} height={400} controls autoPlay>
            <source src={uri} type="video/mp4" />
          </video>
        </div>
      </Modal>
    </>
  );
};

export default VideoPlayer;
