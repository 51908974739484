// Notifications sending

import { http } from "./Api.utils";

export const MANAGER_EMAIL = "clearaligner3d@gmail.com";

const email = {
  async send(data: IEmail) {
    try {
      const result = await http.post("api/email:Send", data);
      //   console.log(result);
    } catch (error) {
      console.log(error);
    }
  },
};

export default email;
