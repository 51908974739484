/**
 * UserDetails Component
 *
 */

// Libraries
import {
  EyeOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, message } from "antd";
import assets from "assets";
import { Loader } from "components";
import React from "react";
import { ENDPOINT, useUpdateMutation } from "utils";
import { useUpdateEffect } from "./../../../hooks/useUpdateEffect";

// View
const UserDetails = ({ user }: { user: IUser }) => {
  const { mutate, isError, isLoading, isSuccess } = useUpdateMutation<
    unknown,
    IUser
  >("users");

  useUpdateEffect(() => {
    if (isError) message.error("There are error please try again");
    // eslint-disable-next-line no-restricted-globals
    if (isSuccess) location.reload();
  }, [isError, isSuccess]);
  return (
    <div className="userDetails">
      {/* Heading */}
      <div className="Heading flex items-center justify-between">
        <div className="flex items-center mb-2">
          <Avatar
            src={
              user.avatar === ""
                ? assets.avatar
                : { uri: `${ENDPOINT}${user.avatar}` }
            }
            shape="circle"
            size={64}
          />
          <div className="UserNameDetails">
            <div className="name text-lg font-bold">{user.name}</div>
            <div className="details font-semibold">#{user.ID}</div>
          </div>
        </div>
        <div className="actionsButtons">
          {user.isActive === 2 && (
            <Button
              danger
              type="primary"
              onClick={() => {
                mutate({ ...user, isActive: 1 });
              }}
            >
              Deactivate
            </Button>
          )}
          {user.isActive === 1 && (
            <Button
              onClick={() => {
                mutate({ ...user, isActive: 2 });
              }}
              type="primary"
              className="bg-green-400 border-green-300 hover:bg-green-500 active:bg-green-500 focus:bg-green-500"
            >
              Activate
            </Button>
          )}
        </div>
      </div>
      {/* End Heading */}

      {/* List details */}
      <div className="listDetails px-2 mt-2">
        <UserDetailsIBox
          icon={<MailOutlined />}
          label={user.email}
          title="Email"
        />
        <UserDetailsIBox
          icon={<PhoneOutlined />}
          label={user.phone}
          title="Phone"
        />
        <UserDetailsIBox
          icon={<UserOutlined />}
          label={user.type}
          title="User Type"
        />
        <UserDetailsIBox
          icon={<EyeOutlined />}
          title="Activation Status"
          label={user.isActive === 2 ? "Active" : "Not active"}
          color={user.isActive === 2 ? "text-green-600" : "text-red-600"}
        />
      </div>
      {/* End list details */}
      {isLoading && <Loader />}
    </div>
  );
};

export const UserDetailsIBox = (props: {
  icon: any;
  label: string;
  title?: string;
  color?: string;
}) => {
  return (
    <div className="detailsBox pb-2 pt-2 border-solid border-0 border-b-2 border-b-slate-100">
      <div className="flex items-center">
        <div className="title">{props.title}</div>
      </div>
      <div className="flex items-center gap-2 text-lg">
        <div className="icon">{props.icon}</div>
        <div className={`label ${props.color}`}>{props.label}</div>
      </div>
    </div>
  );
};

export default UserDetails;
