import React from "react";
import { Button, Image } from "antd";
import "./TXInstruction.css";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { ENDPOINT } from "utils";
import { DownloadOutlined, NodeCollapseOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";
import Collapse from "@kunukn/react-collapse";

type Props = {
  fileUrl: string;
};

const TreatmentPlanPreviewSP = ({ fileUrl }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div className="treatmentPlanSP">
      <div className="py-1 px-2 bg-red-600/[.8] rounded-sm  border-solid border-0 border-b-2 border-b-green-500 ">
        <div className="flex justify-between items-center">
          <div className="flex gap-2 items-center ">
            <div
              onClick={() => {
                setIsOpen((val) => !val);
              }}
              className="minus h-4 w-4 flex items-center cursor-pointer justify-center bg-red-50 font-bold rounded-sm"
            >
              {isOpen ? "-" : "+"}
            </div>
            <Image
              src="/attachments/sp.png"
              width="1.5rem"
              height="1.5rem"
              preview={false}
            />
            <div className="text-2xl text-white">Stripping Protocol</div>
          </div>
          <div
            className="download flex items-center text-white text-lg font-semibold gap-2 cursor-pointer hover:scale-105 transition-all"
            onClick={() => {
              saveAs(
                ENDPOINT + "public/files/" + fileUrl + ".docx",
                fileUrl + ".docx"
              );
            }}
          >
            <div className="iconDownload">
              <DownloadOutlined />
            </div>
            <div className="downloadTitle">Download</div>
          </div>
        </div>
      </div>
      <Collapse isOpen={isOpen} className="collapse-css-transition">
        <div className="py-4 px-4 w-full bg_image ">
          <DocViewer
            documents={[
              {
                uri:
                  "https://www.ca-clearart.com/api/public/files/" +
                  fileUrl +
                  ".docx",
                fileType:
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                fileName: "Stripping Protocol Document",
              },
            ]}
            style={{ height: 1250 }}
            pluginRenderers={DocViewerRenderers}
          />
        </div>
      </Collapse>
    </div>
  );
};

export default TreatmentPlanPreviewSP;
