/* -------------------------------------------------------------------------- */
/*                                    DetailsBox                                    */
/* -------------------------------------------------------------------------- */

/* ---------------------------- Component Global ---------------------------- */
interface IProps {
  title: string;
  description?: string;
  icon?: any;
  children: React.ReactElement;
}

/* ---------------------------- Default function ---------------------------- */
const DetailsBox = (props: IProps) => {
  /* ----------------------------- Function return ---------------------------- */
  return (
    <div className="bg-blue-50/25 h-full px-1 rounded-sm border-blue-100/50 border-solid border-2">
      <div className="flex items-center pt-0.5 text-black font-semibold gap-1 text-lg ">
        <div className="icon pt-0.5">{props.icon}</div>
        <div className="font-semibold text">{props.title}</div>
      </div>
      <div className="body p-2">{props.children}</div>
    </div>
  );
};

/* ----------------------------- Default export ----------------------------- */
export default DetailsBox;
