import { Form } from "antd";
import { FormBuilder } from "components";
import Heading from "components/Cases/components/Heading";
import { useCase } from "models";
import { useCreateCase } from "pages/cases/create_case/hooks";
import React from "react";

import { patientFormInputs } from "./Data";

type props = {};

const Patient: React.FC<props> = () => {
  const [form] = Form.useForm();
  const nextPage = useCreateCase((state) => state.nextPage);
  const setPatient = useCase((state) => state.setPatient);
  const patient = useCase((state) => state.patient);

  const setCaseType = useCase(state => state.setCaseType)
  const handleSubmitForm = (values: any) => {
      const name = `${values.first_name} ${values.middle_name} ${values.last_name}`
      setCaseType(values.caseType)
    setPatient({...values,name});
    nextPage();
  };

  return (
    <div className="Patient ">
      <Heading
        title="Patient details"
        description="Please fill the patient details, to easy manage your patient cases"
      />
      <Form
        name="patient"
        layout="vertical"
        onFinish={handleSubmitForm}
        initialValues={{...patient,caseType:"NewCase"}}
        autoComplete={"off"}
        form={form}
      >
        <FormBuilder
          form={form}
          inputs={[
            ...patientFormInputs,
            {
              type: "button",
              name: "Cancel",
              label: "Cancel",
              span: 4,
              className: "w-full mt-2",
              buttonType: "default",
              onClick: () => {
                window.location.reload();
              },
            },
          ]}
        />
      </Form>
    </div>
  );
};

export default Patient;
