import { Descriptions } from "antd";
import React from "react";
import { ExtraProps } from "./components/ActionsList";
import { useUpdateEffect } from "./../../../hooks/useUpdateEffect";
import { useEffectOnce } from "hooks";

type Props = {
  extra: ExtraProps | null;
};

const ActionExtraPreview = ({ extra }: Props) => {
  useEffectOnce(() => {
    console.log(extra);
  });
  if (extra == null) return null;
  return (
    <div className="ActionExtraPreview">
      <Descriptions title="Instructions Details" bordered layout="vertical">
        <Descriptions.Item label="Steps">{extra.steps}</Descriptions.Item>
        <Descriptions.Item label="Step Details">
          {extra.stepDetails.slice(0, extra.steps).map((trg, index) => (
            <div>
              <span className="font-bold">Step {index + 1}:</span>{" "}
              {trg.soft && "Soft"} {trg.medium && "Medium"} {trg.hard && "Hard"}{" "}
            </div>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="Steps Instructions">
          {extra.stepInstructions.slice(0, extra.steps).map((trg, index) => (
            <div>
              Step {index + 1}: {trg.caPro && "CA Pro"} {trg.duran && "Duran"}{" "}
            </div>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="Upper">{extra.upper}</Descriptions.Item>
        <Descriptions.Item label="Lower">{extra.lower}</Descriptions.Item>
        <Descriptions.Item label="Attachment Template">
          Copyplast At Step number{extra.attachmentTemplate}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default ActionExtraPreview;
