/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Avatar,
  Badge,
  Divider,
  Dropdown,
  Empty,
  Menu,
  Typography,
} from "antd";
import { useUser } from "models";
import React from "react";
import { Link } from "react-router-dom";

import {
  BellFilled,
  KeyOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";

const onLogOutClick = () => {
  window.localStorage.removeItem("userToken");
  window.location.reload();
};

const UserMenu = (
  <Menu>
    <Menu.Item key="0" icon={<UserOutlined />}>
       <a>My settings</a>
    </Menu.Item>
    <Menu.Item key="1" icon={<KeyOutlined />}>
       <Link to="/change_password">Change password</Link>
    </Menu.Item>
    <Menu.Item key="2" icon={<LogoutOutlined />} onClick={onLogOutClick}>
       <a>Log out</a>
    </Menu.Item>
  </Menu>
);


const NotificationMenuItem = (props: {
  avatar: any;
  title: string;
  subtitle: string;
}) => (
  <div className=" w-80">
    <div className="flex items-center gap-2 px-2 pb-0 mb-0">
      <Avatar className="bg-red-400" size={32}>
        {props.avatar}
      </Avatar>
      <Typography.Text className="text-lg mb-0 pb-0 font-bold">
        {props.title}
      </Typography.Text>
    </div>
    <Divider className="mt-2 mb-2" />
    <div className="px-2">
      <Typography.Text>{props.subtitle}</Typography.Text>
    </div>
  </div>
);

const notificationsData: { avatar: any; title: string; subtitle: string }[] =
  [];

const NotificationMenu = (
  <Menu>
    <Menu.Item className="p-0 py-2">
      {notificationsData.map((trg, index) => (
        <NotificationMenuItem {...trg} key={index} />
      ))}
      {notificationsData.length === 0 && <Empty />}
    </Menu.Item>
  </Menu>
);

type props = {};
const AccountPreview: React.FC<props> = (props) => {
  const user = useUser((state) => state.user);
  if (!user) return null;
  return (
    <div className="flex items-center gap-5">
      <Dropdown
        overlay={NotificationMenu}
        trigger={["click"]}
        placement="bottom"
      >
        <a onClick={(e) => e.preventDefault()}>
          <div className="AccountPreview flex items-center gap-1 cursor-pointer mt-2">
            <Badge count={notificationsData.length}>
              <BellFilled className="text-white text-2xl" />
            </Badge>
          </div>
        </a>
      </Dropdown>
      <Dropdown overlay={UserMenu} trigger={["click"]}>
        <a onClick={(e) => e.preventDefault()}>
          <div className="AccountPreview flex items-center gap-1 cursor-pointer">
            <Avatar
              size={32}
              className="bg-black flex items-center justify-center"
              icon={
                <UserOutlined
                  width={"32px"}
                  size={32}
                  className="self-center"
                  height={"32px"}
                />
              }
            />
            <Typography.Text className="font-normal text-white">
              {user.name}
            </Typography.Text>
          </div>
        </a>
      </Dropdown>
    </div>
  );
};

export default AccountPreview;
