import create from "zustand";

interface IUseCart {
  cartItems: ICartItems[];
  setCartItems: (cartItems: ICartItems[]) => void;
}

const useCart = create<IUseCart>((set) => ({
  cartItems: [],
  setCartItems(cartItems) {
    set(() => ({
      cartItems,
    }));
  },
}));

export default useCart;
