import "./authentication.css";

import { Layout, Tabs, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import { CenteredFooter, Logo } from "components";
import React from "react";

import Login from "./Login";
import Register from "./Register";
import ForgotPassword from './ForgotPassword';


const { TabPane } = Tabs;
const Authentication = () => {
  return (
    <Layout className="fullPage">
      <Content className="contentCenter">
        <Logo size="sm" />
        <Typography.Paragraph style={{ fontWeight: "bold" }}>
          Clear aligner
        </Typography.Paragraph>
        <div className="flexibleWidth" style={{ marginTop: "20px" }}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Login" key="1">
              <Login />
            </TabPane>
            <TabPane tab="Register" key="2">
              <Register />
            </TabPane>
            {/* <TabPane tab="Forgot Password?" key="3">
              <ForgotPassword />
            </TabPane> */}
          </Tabs>
        </div>
        <CenteredFooter />
      </Content>
    </Layout>
  );
};

export default Authentication;
