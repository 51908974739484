/**
 *
 * Assets Pages
 */

const assets = {
  logo: {
    placeholder: require("./logo/placeholder.png"),
    logo: require("./logo/logo.PNG"),
  },
  attachments: [
    require("./attachments/a01.svg"),
    require("./attachments/a02.png"),
    require("./attachments/a03.png"),
    require("./attachments/a04.png"),
    require("./attachments/a05.png"),
    require("./attachments/a06.png"),
    require("./attachments/a07.png"),
    require("./attachments/a08.png"),
  ],
  arch: [
    require("./arch/booth.png"),
    require("./arch/upper.png"),
    require("./arch/lower.png"),
  ],
  avatar: require("./avatar.jpg"),
};
export default assets;
