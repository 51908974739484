import { Image, message, Upload } from "antd";
import React from "react";
import { ENDPOINT } from "./../../../utils/constants.utils";
import type { UploadProps } from "antd";
import { StlViewer } from "react-stl-viewer";
import {
  DeleteOutlined,
  FileDoneOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

type props = {
  initialImage?: any;
  label?: string;
  isImage?: boolean;
  onChangeImage?: (uri: string) => void;
  onRemoveImage?: (uri: string) => void;
  showDelete?: boolean;
};

const StlUpload: React.FC<props> = (props) => {
  const [stlFile, setStlFile] = React.useState<string | undefined>(undefined);
  const [uploaded, setUploaded] = React.useState(
    props.showDelete ? true : false
  );
  const [uploading, setUploading] = React.useState(false);

  const data: UploadProps<{ id: string }> = {
    action: ENDPOINT + "file",
    onChange({ file }) {
      if (file.status !== "uploading") {
        setUploading(false);
        if (file.response) {
          setStlFile(file.response.id);
          setUploaded(true);
          if (props.onChangeImage) {
            props.onChangeImage(file.response.id);
          }
        }
      }

      if (file.status === "uploading") {
        setUploading(true);
      }

      if (file.status === "error") {
        message.error(
          "There are error while uploading the file please try again later"
        );
      }
    },
    showUploadList: false,
    beforeUpload(file) {
      const acceptedFormats = ['stl'];
      if(acceptedFormats.includes(file.name.split('.')[1])) {
        return true;
      }

      return false;
    },
  };

  return (
    <div>
      <div className=" bg-white py-2 px-3 cursor-pointer m-2 mt-4 rounded-lg">
        <div className={`p-4 border-2 border-dotted rounded-sm flex items-center justify-center ${
                uploaded ? "border-green-200" : "" }`} >
          {!stlFile && <Upload {...data}>
                         <Image 
                          src={props.initialImage}
                          preview={false}
                          className="w-28 h-24 object-fit" /> 
                       </Upload>
           }
           {stlFile && (
             <div className="w-28 text-green-300 text-2xl h-24 flex items-center justify-center">
               <div className="font text-base">
                <StlViewer
                  url={ENDPOINT + stlFile}
                  orbitControls
                  shadows
                  modelProps={{}}
                />
               </div>
             </div>
          )}
        </div>
        <div className="mt-1">
          <h3 className="text text-xs uppercase font-bold">
             {props.label}
          </h3>
        </div>
        <div
          className={`text-center flex items-center gap-1 mt-1 justify-center ${
            uploaded ? "text-red-300" : "text-slate-400"
          }`}
          onClick={() => {
            if (uploaded && stlFile) {
              setStlFile(undefined);
              setUploaded(false);
              if (props.onRemoveImage) {
                props.onRemoveImage(stlFile);
              }
            }
          }}
        >
          <div className={`text-xs`}>
            {uploaded && "Delete file"}
            {!uploaded && !uploading && "Upload image"}
            {!uploaded && uploading && "Uploading"}
          </div>
          {uploaded && <DeleteOutlined className="text-xs" />}
          {uploading && <LoadingOutlined className="text-xs" />}
        </div>
      </div>
    </div>
  );
};

export default StlUpload;
