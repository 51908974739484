import { Divider, message, Result } from "antd";
import { useUpdateEffect } from "hooks";
import React from "react";
import { useReadQuery } from "utils";
import { UserListHeading } from "./UserListHeading";
import { UserListTable } from "./UserListTable";

type props = {};

const UsersList: React.FC<props> = (props) => {
  const { data, isError } = useReadQuery<{ data: IUser[] }>("users", "", {
    params: {
      order: "ID desc",
    },
  });

  useUpdateEffect(() => {
    if (isError) {
      message.error(
        "There are error while fetching data please try again later."
      );
    }
  }, [isError]);

  if (!data) {
    return (
      <div className="bg-white p-1 m-1 rounded-sm h-full">
        <div className="flex flex-1 justify-center items-center">
          <Result
            status={"500"}
            title="No data"
            subTitle="Not expected Error while fetching data !"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="UsersList h-full">
      <div className="bg-white p-1 h-full mx-1 my-2 rounded-sm">
        <UserListHeading />
        <Divider />
        <div className="context">
          <UserListTable data={data.data} />
        </div>
      </div>
    </div>
  );
};

export default UsersList;
