import {
  Active,
  Attachments,
  ClinicalConditions,
  Patient,
  SuccessPage,
} from "components";
import Prescription from "components/Cases/Forms/Prescription/Prescription";
import React from "react";

import { useCreateCase } from "./hooks";
import { useEffectOnce } from "hooks";
import useSidePanel from "components/Layouts/useSidepanel";
import { Steps } from "antd";
import {
  ReadStepsActiveStatus,
  SetStepsActiveStatus,
} from "./utils/ReadStepsActiveStatus";

// Page props
type props = {};

// Create Case function
const CreateCase: React.FC<props> = (props) => {
  const page = useCreateCase((state) => state.page);
  const setPage = useCreateCase((state) => state.setPage);
  const setActiveIndex = useSidePanel((state) => state.setActiveIndex);
  useEffectOnce(() => {
    setActiveIndex(21);
    if (page === "Success") {
      setPage("Patient");
    }
  });

  return (
    <div>
      <div className="bg-white px-2 py-6">
        <Steps
          current={ReadStepsActiveStatus(page)}
          onChange={(key) => {
            if (key === 4) return;
            if (key > ReadStepsActiveStatus(page)) return;
            setPage(SetStepsActiveStatus(key));
          }}
        >
          <Steps.Step title="Patient details" />
          <Steps.Step title="Clinical conditions" />
          <Steps.Step title="Attachments" />
          <Steps.Step title="Prescription" />
          <Steps.Step title="Submit" />
        </Steps>
      </div>
      <div className="CreateCase bg-white p-2 mx-2 my-2 pb-4 rounded-md">
        <Active active={page === "Patient"}>
          <Patient />
        </Active>
        <Active active={page === "ClinicalCondition"}>
          <ClinicalConditions />
        </Active>
        <Active active={page === "Attachments"}>
          <Attachments />
        </Active>
        <Active active={page === "Prescription"}>
          <Prescription />
        </Active>
        <Active active={page === "Success"}>
          <SuccessPage />
        </Active>
      </div>
    </div>
  );
};

export default CreateCase;
