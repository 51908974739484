import { MessageOutlined } from "@ant-design/icons";
import React from "react";

const Header = () => {
  return (
    <div className="treatmentPlanHeading p-2 border-solid border-0 border-b-2 border-b-slate-200 bg-slate-100 ">
      <div className="flex gap-2 items-center ">
        <div className="bg-blue-100 px-1 rounded-sm border-blue-200 text-blue-800 border-2 border-solid">
          <MessageOutlined />
        </div>
        <div className="text-lg font-bold">Edit Treatment Plan</div>
      </div>
      <div className="text-medium">
        Edit treatment plan, remove, edit, files and instructions
      </div>
    </div>
  );
};

export default Header;
