// use case preview

import create from "zustand";
import { IUseCasePreview } from "./types";

const useCasePreview = create<IUseCasePreview>((set) => ({
  setCase(data) {
    set(() => ({
      case: data,
    }));
  },
}));

export default useCasePreview;
