import React, { Fragment } from "react";

type props = {
  children?: JSX.Element;
  active?: boolean;
};

const Active: React.FC<props> = ({ children, active }) => {
  if (!active) return null;
  return <Fragment>{children}</Fragment>;
};

export default Active;
