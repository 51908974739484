/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React from "react";
import ListItemHeader from "./components/ListItemHeader";
import type { ColumnsType } from "antd/es/table";
import { Button, message, Modal, Table } from "antd";
import { http, useReadQuery } from "utils";
import { Loader } from "components";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

// Interface For Items
interface IItemTable extends IItem {
  actions?: string;
}

const columns: ColumnsType<IItemTable> = [
  {
    title: "ID",
    dataIndex: "ID",
    key: "ID",
  },
  {
    title: "title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "fear",
    dataIndex: "fear",
    key: "fear",
  },
];

const ListItem = () => {
  const { data, isLoading, refetch } = useReadQuery<{ data: IItemTable[] }>(
    "items"
  );

  const navigate = useNavigate();

  const removeItem = async (ID: number = 0) => {
    Modal.confirm({
      title: "Remove item",
      content: "Do you want remove item ?",
      onOk: async () => {
        try {
          const result = await http.delete("api/items/" + ID);
          if (result) {
            message.success("Item has remove");
            refetch();
          }
        } catch (error) {
          alert("There are error");
          message.error("There are error please try again");
        }
      },
    });
  };
  const previewItem = (ID: number = 0) => {
    navigate("/item/" + ID);
  };

  const memoCols = React.useMemo<ColumnsType<IItemTable>>(() => {
    return [
      ...columns,
      {
        title: "actions",
        dataIndex: "ID",
        key: "actions",
        render(_, { ID }, index) {
          return (
            <div className="flex items-center gap-2" key={index}>
              <Button icon type="text" onClick={() => removeItem(ID)}>
                <DeleteFilled className="text-red-500" />
              </Button>
              <Button icon type="text" onClick={() => previewItem(ID)}>
                <EditFilled className="text-blue-500" />
              </Button>
            </div>
          );
        },
      },
    ];
  }, []);

  return (
    <div className="listItem bg-white p-1 m-1 rounded-sm h-full">
      <ListItemHeader />
      {isLoading && <Loader />}

      {data && (
        <div className="body p-2">
          <Table columns={memoCols} dataSource={data.data} />
        </div>
      )}
    </div>
  );
};

export default ListItem;
