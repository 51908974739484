import ImagesUpload, {
  UrlList,
} from "components/Uploads/ImagesUpload/ImagesUpload";
import React from "react";
import { DeleteFilled, EditOutlined, PhoneOutlined } from "@ant-design/icons";
import { IconLabel, PreviewCard } from "../TreatmentPlanComponents";
import ImagesPreview from "./ImagesPreview";
import { Image } from "antd";
import { ENDPOINT } from "utils";

type Props = {
  files: UrlList[];
  setFiles: React.Dispatch<React.SetStateAction<UrlList[]>>;
  type: "images" | "videos" | "documents" | "files" | "3dFile";
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const TreatmentPlanFilesEditor = (props: Props) => {
  // Files Props Files
  return (
    <div className="TreatmentPlanImageEditing">
      <div className="PreviewFiles">
        <IconLabel icon={<EditOutlined />} title={props.type} />
        <div className="HeaderViewer mt-2 flex gap-2 items-center">
          {props.files.map((trg, index) => (
            <PreviewCard>
              <div className="relative">
                {props.type === "images" && (
                  <Image
                    preview={false}
                    src={ENDPOINT + trg.url}
                    width="12rem"
                    height="12rem"
                  />
                )}
                {props.type === "videos" && (
                  <video
                    width="192px"
                    height={"192px"}
                    autoPlay={false}
                    controls={false}
                  >
                    <source src={ENDPOINT + trg.url} type="video/mp4" />
                  </video>
                )}
                {props.type === "documents" && (
                  <div className="flex items-center justify-center">
                    <Image
                      preview={false}
                      src="/attachments/doc.png"
                      width={"12rem"}
                      height="12rem"
                    />
                  </div>
                )}
                {props.type === "3dFile" && (
                  <div className="flex">
                    <Image
                      preview={false}
                      src="/attachments/3d.png"
                      width="12rem"
                      height="12rem"
                    />
                  </div>
                )}
                {props.type === "files" && (
                  <div className="flex">
                    <Image
                      preview={false}
                      src="/attachments/files.png"
                      width="12rem"
                      height="12rem"
                    />
                  </div>
                )}
                <div
                  className="DeleteIcon absolute text-2xl text-red-600 rounded-full bg-white h-8 w-8 flex items-center justify-center -left-1 -top-1"
                  onClick={() => {
                    props.setFiles((oldValues) => {
                      return oldValues.filter(
                        (_trg, x) => _trg.url !== trg.url
                      );
                    });
                  }}
                >
                  <DeleteFilled className=" hover:scale-110 hover:transition-all" />
                </div>
              </div>
            </PreviewCard>
          ))}
          <ImagesUpload
            fileList={props.files}
            setFileList={props.setFiles}
            isLoading={props.loading}
            setIsLoading={props.setLoading}
            type={props.type}
          />
        </div>
      </div>
    </div>
  );
};

export default TreatmentPlanFilesEditor;
